import { NCInputText } from '@daupler/nexus-components';
import { IncidentEmailProcessorProps } from './resource-form-types';
import { IncidentEmailProcessorOhio811NewtinOptions } from '../../types/EntityConfig';

export function IncidentEmailProcessorOhio811Newtin({
  onChange,
  value,
}: IncidentEmailProcessorProps) {
  const {
    emergency_etc_hours: emergencyEtcHours,
    category_name_template: categoryNameTemplate,
  } = (value as IncidentEmailProcessorOhio811NewtinOptions) ?? {};
  return (
    <>
      <NCInputText
        label="Emergency ETC Hours"
        name="ohio_811_newtin-emergency_etc_hours"
        type="number"
        step={1}
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorOhio811NewtinOptions),
            emergency_etc_hours: parseInt(event.target.value, 10),
          });
        }}
        value={emergencyEtcHours}
      />
      <NCInputText
        label="Category Name Template"
        name="ohio_811_newtin-category_name"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorOhio811NewtinOptions),
            category_name_template: event.target.value,
          });
        }}
        value={categoryNameTemplate}
      />
    </>
  );
}

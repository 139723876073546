import {
  FormField,
  NCAlert,
  NCButton,
  useForm,
} from '@daupler/nexus-components';
import clsx from 'clsx';
import { useState } from 'react';
import { EntityConfigResource, EntityConfigResourceType } from '../types/EntityConfig';
import { logger } from '../utils/logger';
import './ConfigVisualEditorMacroModal.css';
import { EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorUnsavedWarning } from './ConfigVisualEditorUnsavedWarning';
import { ConfigVisualEditorObjectPicker } from './ConfigVisualEditorObjectPicker';
import { EntityConfigMacroType } from '../types/EntityConfigUIExtensions';
import { getDescriptionForMacroType, getIconForMacroType } from '../utils/macro-tools';

enum View {
  CHOOSE_MACRO = 'CHOOSE_MACRO',
  CONFIGURE_MACRO = 'CONFIGURE_MACRO',
}

type ConfigVisualEditorMacroModalProps = {
  copyResource?: () => void;
  onAbandonChanges?: () => void;
  onBack: () => void;
  onClose: () => void;
  onSubmit: (resource: unknown) => Promise<void>;
  resource?: EditorEntityResource;
  resourceType?: EntityConfigResourceType;
  wipResource: {
    resourceData: Partial<EntityConfigResource> | null,
    type: EntityConfigResourceType;
  } | null;
};

export function ConfigVisualEditorMacroModal({
  copyResource,
  onAbandonChanges,
  onBack,
  onClose,
  onSubmit,
  resource,
  resourceType: incomingType,
  wipResource,
}: ConfigVisualEditorMacroModalProps) {
  const hasDefaultResource = incomingType || resource?.state.type;
  const [view, setView] = useState(hasDefaultResource
    ? View.CONFIGURE_MACRO
    : View.CHOOSE_MACRO);

  const [
    subFormValidationCallback,
  ] = useState<({ validate: () => boolean })>({ validate: () => false });

  const type: FormField<EntityConfigMacroType | ''> = {
    invalidMessage: '',
    name: 'type',
    validate: (value) => !!value,
    validMessage: '',
    value: '',
    initialValue: '',
    isValid: !!resource?.state.type,
  };

  const {
    formState,
    isFormValid,
    onChange,
    validateField,
    validateForm,
  } = useForm({ type });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    validateField(formState.type.name);
    if (view === View.CHOOSE_MACRO && formState.type.isValid) {
      setView(View.CONFIGURE_MACRO);
      return;
    }

    validateForm();
    subFormValidationCallback.validate();
    if (!isFormValid()) { return; }

    try {
      await onSubmit({
        type: formState.type.value,
      });
      onClose();
    } catch (err) {
      logger.error((err as Error).message, err);
    }
  };

  const handleCancel = () => {
    if (hasDefaultResource || view === View.CHOOSE_MACRO) {
      onClose();
      onAbandonChanges?.();
    } else {
      setView(View.CHOOSE_MACRO);
    }
  };

  const [shouldWarnUnsaved, setShouldWarnUnsaved] = useState(false);

  return (
    <>
      <form onSubmit={handleSubmit} className="config_visual_editor_macro_modal">
        <div className="config_visual_editor_macro_modal__header">
          <NCButton
            appearance={NCButton.appearances.SOLID}
            color={NCButton.colors.LIGHT}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onBack}
          >
            <i className="fa-light fa-arrow-left" />
          </NCButton>
          <h2 className="nc-t-h5_medium_mobile">
            Macro Editor
          </h2>
        </div>

        <div className="config_visual_editor_macro_modal__body">
          {view === View.CHOOSE_MACRO ? (
            <ConfigVisualEditorObjectPicker
              items={Object.values(EntityConfigMacroType).map((macroType) => ({
                id: macroType,
                name: macroType,
                description: getDescriptionForMacroType(
                  macroType as unknown as EntityConfigMacroType,
                ),
                icon: (<i className={`fa-solid ${getIconForMacroType(macroType as unknown as EntityConfigMacroType)}`} />),
              }))}
              title="Choose Macro"
              onChange={(id) => onChange(
                formState.type.name,
                id as unknown as EntityConfigMacroType,
              )}
              value={formState.type.value as string}
            />
          ) : null}
          {view === View.CONFIGURE_MACRO ? (
            <div>
              {wipResource ? (
                <NCAlert
                  className="nc-l-mb_200_mobile"
                  action={(
                    <NCButton
                      appearance={NCButton.appearances.LINK}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      onClick={onAbandonChanges}
                    >
                      <i className="fa-solid fa-times fa-fw config_visual_editor_macro_modal__unsaved_dismiss" />
                    </NCButton>
                  )}
                  message="You have unsaved changes since your last edit."
                  type={NCAlert.types.WARNING}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <div
          className={clsx(
            'config_visual_editor_macro_modal__footer',
            'nc-flex',
            'nc-flex--align_center',
            'nc-flex--gap_1',
            'nc-l-pa_utilities_225_mobile',
            'nc-flex--justify_between',
          )}
        >
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={handleCancel}
          >
            {view === View.CHOOSE_MACRO || hasDefaultResource ? 'Cancel' : 'Back'}
          </NCButton>
          <div className="nc-flex nc-flex--gap_1 nc-flex--align_center">
            {view === View.CHOOSE_MACRO ? null : (
              <NCButton
                appearance={NCButton.appearances.OUTLINE}
                color={NCButton.colors.GREY}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                onClick={copyResource}
              >
                <i className="fa-solid fa-fw fa-copy" />
              </NCButton>
            )}
            <NCButton
              appearance={NCButton.appearances.SOLID}
              color={NCButton.colors.PRIMARY}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              type="submit"
            >
              {view === View.CHOOSE_MACRO ? 'Next' : 'Save'}
            </NCButton>
          </div>
        </div>
      </form>

      {shouldWarnUnsaved ? (
        <ConfigVisualEditorUnsavedWarning
          onCancel={() => { setShouldWarnUnsaved(false); }}
          onConfirm={() => {
            setShouldWarnUnsaved(false);
          }}
        />
      ) : null}
    </>
  );
}

import { useState } from 'react';
import {
  FormField,
  NCInputCheckbox,
  useForm,
} from '@daupler/nexus-components';
import { Tooltip } from '@mui/material';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import { EntityConfigResourceIncidentEmailRouter, EntityConfigResourceIncidentEmailRouterRoute, EntityConfigResourceType } from '../../types/EntityConfig';
import { InputList } from '../InputList';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function IncidentEmailRouterForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      routes: routesValue,
    },
  } = resourceData as EntityConfigResourceIncidentEmailRouter ?? { data: {} };
  const routes: FormField<EntityConfigResourceIncidentEmailRouterRoute[]> = {
    invalidMessage: '',
    name: 'routes',
    validate: () => true,
    validMessage: '',
    value: routesValue ?? [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    routes,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        routes: formState.routes.value,
      },
    },
  });

  const getEmptyRoute = (): EntityConfigResourceIncidentEmailRouterRoute => ({
    _dispatch_policy_ref: undefined,
    _processor_ref: undefined,
    stop_cascade: false,
  });
  const [
    newRoute,
    setNewRoute,
  ] = useState<EntityConfigResourceIncidentEmailRouterRoute>(getEmptyRoute());
  const clearRoute = () => {
    setNewRoute(getEmptyRoute());
  };
  const {
    _dispatch_policy_ref: dispatchPolicyRef,
    _processor_ref: processorRef,
  } = newRoute;

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <InputList<EntityConfigResourceIncidentEmailRouterRoute>
        className="nc-l-mt_200_mobile"
        data={formState.routes.value}
        label="Routes"
        onAdd={() => {
          onFormChange(
            formState.routes.name,
            [
              ...formState.routes.value,
              newRoute,
            ],
          );
          setNewRoute(getEmptyRoute());
        }}
        onClear={clearRoute}
        onRemove={(data) => {
          onFormChange(
            formState.routes.name,
            data,
          );
        }}
        renderItem={(item) => (
          <Tooltip
            title={(
              <ul>
                <li>
                  Dispatch Policy:
                  {': '}
                  {resources.find(({ state: resource }) => {
                    const { _dispatch_policy_ref: ref } = item;
                    return ref?.key === resource.key
                      && resource.type === EntityConfigResourceType.DISPATCH_POLICIES;
                  })?.state.display_name}
                </li>
                <li>
                  Email Processor:
                  {': '}
                  {resources.find(({ state: resource }) => {
                    const { _processor_ref: ref } = item;
                    return ref?.key === resource.key
                      && resource.type === EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS;
                  })?.state.display_name}
                </li>
                <li>
                  Stop Cascade:
                  {`: ${item.stop_cascade}`}
                </li>
              </ul>
            )}
          >
            <span>
              {resources.find(({ state: resource }) => {
                const { _processor_ref: ref } = item;
                return ref?.key === resource.key
                  && resource.type === EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS;
              })?.state.display_name}
              {' '}
              {resources.find(({ state: resource }) => {
                const { _dispatch_policy_ref: ref } = item;
                return ref?.key === resource.key
                  && resource.type === EntityConfigResourceType.DISPATCH_POLICIES;
              })?.state.display_name}
            </span>
          </Tooltip>
        )}
      >
        <ConfigVisualEditorResourceRefControl
          label="Dispatch Policy"
          className="nc-l-mt_200_mobile"
          name="route-dispatch_policy"
          resources={resources}
          resourceType={EntityConfigResourceType.DISPATCH_POLICIES}
          entityId={entityId}
          onChange={(event) => setNewRoute({
            ...newRoute,
            _dispatch_policy_ref: {
              key: event.target.value,
              type: EntityConfigResourceType.DISPATCH_POLICIES,
            },
          })}
          value={dispatchPolicyRef?.key ?? ''}
        />
        <ConfigVisualEditorResourceRefControl
          label="Email Processor"
          className="nc-l-mt_200_mobile"
          name="route-email_processor"
          resources={resources}
          resourceType={EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS}
          entityId={entityId}
          onChange={(event) => setNewRoute({
            ...newRoute,
            _processor_ref: {
              key: event.target.value,
              type: EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS,
            },
          })}
          value={processorRef?.key ?? ''}
        />
        <NCInputCheckbox
          label="Stop Cascade"
          className="nc-l-mt_200_mobile"
          name="route-stop_cascade"
          onChange={(event) => setNewRoute({
            ...newRoute,
            stop_cascade: event.target.checked,
          })}
          checked={newRoute.stop_cascade}
        />
        <p className="nc-t-sub_text_light_mobile">This probably needs some additional explanation here.</p>
      </InputList>
    </>
  );
}

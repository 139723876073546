import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  NCButton,
  NCContextMenu,
  NCLabel,
} from '@daupler/nexus-components';
import { EntityDetail } from '../types/Entity';
import './ConfigVisualEditorHeader.css';
import { NavToolPicker } from './NavToolPicker';
import { EditorMode } from '../types/ConfigEditor';

type ConfigVisualEditorHeaderProps = {
  abandonChanges: () => void;
  documentId?: string;
  entity: EntityDetail | null;
  editorMode: EditorMode;
  hasUnsavedChanges?: boolean;
  isDrawerOpen: boolean;
  isObjectsMenuOpen: boolean;
  isSaving?: boolean;
  onApply: () => void;
  onDrawerClick: () => void;
  onEditorModeChange: (editorMode: EditorMode) => void;
  onObjectsMenuClick: () => void;
  onPlan: () => void;
  onSave: () => void;
  onSaveAs: () => void;
  planErrors?: number;
  viewPlan: () => void;
};

export function ConfigVisualEditorHeader({
  abandonChanges,
  documentId,
  editorMode,
  entity,
  hasUnsavedChanges,
  isDrawerOpen,
  isObjectsMenuOpen,
  isSaving,
  onApply,
  onDrawerClick,
  onEditorModeChange,
  onObjectsMenuClick,
  onPlan,
  onSave,
  onSaveAs,
  planErrors,
  viewPlan,
}: ConfigVisualEditorHeaderProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isContextOpen, setIsContextOpen] = useState(false);
  const [isPlanMenuOpen, setIsPlanMenuOpen] = useState(false);

  return (
    <div className="config_visual_editor_header">
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-flex--gap_1 config_visual_editor__title_row">
        <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
          <NavToolPicker />
          <div className={clsx('config_visual_editor_header__title')}>
            <span className="nc-t-sub_text_light_mobile nc-t-h3_light_tablet">{entity?.name}</span>
            {' '}
            <span className="nc-t-info_text_medium_mobile nc-t-h5_medium_tablet">{`(${entity?.shortName})`}</span>
          </div>
          {hasUnsavedChanges ? <NCLabel label="Unsaved Changes" color={NCLabel.colors.WARNING} /> : null}
          {editorMode !== EditorMode.UNINITIALIZED ? (
            <div
              className={clsx(
                'config_visual_editor_header__plan',
                {
                  'config_visual_editor_header__plan--error': (planErrors ?? 0) > 0,
                  'config_visual_editor_header__plan--success': planErrors === 0,
                },
              )}
            >
              <button type="button" onClick={viewPlan} className="config_visual_editor_header__plan__action">
                {planErrors !== undefined && planErrors > 0 ? (
                  <>
                    <i className="fa-light fa-warning" />
                    {' '}
                    {`${planErrors} Errors`}
                  </>
                ) : null}
                {planErrors !== undefined && planErrors === 0 ? (
                  <>
                    Ready to apply!
                  </>
                ) : null}
                {planErrors === undefined ? (
                  <>
                    No plan available
                  </>
                ) : null}
              </button>
              <NCContextMenu
                id="plan-menu"
                isOpen={isPlanMenuOpen}
                options={[[
                  {
                    id: 'plan-menu-1',
                    label: 'Plan',
                    labelText: 'Plan',
                    onClick: onPlan,
                    type: NCContextMenu.itemTypes.BUTTON,
                  },
                  {
                    id: 'plan-menu-2',
                    label: 'Apply',
                    labelText: 'Apply',
                    onClick: onApply,
                    type: NCContextMenu.itemTypes.BUTTON,
                  },
                ]]}
                setIsOpen={setIsPlanMenuOpen}
                className="config_visual_editor_header__plan__menu"
              >
                <div className="config_visual_editor_header__plan__menu__text">
                  <i className="fa-light fa-chevron-down" />
                  <span className="nc-a11y_hidden">Plan</span>
                </div>
              </NCContextMenu>
            </div>
          ) : null}
        </div>

        <div
          className={clsx('nc-flex nc-flex--align_end nc-flex--gap_1')}
        >
          {isSaving ? (
            <i className="fa-solid fa-spinner nc-anim-rotate" />
          ) : null}

          <NCButton
            appearance={editorMode === EditorMode.UNINITIALIZED
              ? NCButton.appearances.INVERSE
              : NCButton.appearances.SOLID}
            color={editorMode === EditorMode.UNINITIALIZED
              ? NCButton.colors.DARK
              : NCButton.colors.GREY}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => {
              onEditorModeChange(editorMode === EditorMode.VISUAL
                ? EditorMode.YAML : EditorMode.VISUAL);
              navigate(`/config/entities/${entity?.id ?? ''}/documents/${documentId ?? 'new'}?${searchParams.toString()}`);
            }}
            disabled={editorMode === EditorMode.UNINITIALIZED}
          >
            {editorMode === EditorMode.VISUAL ? (<i className="fa-solid fa-fw fa-code" />) : (<i className="fa-solid fa-fw fa-window" />)}
          </NCButton>

          <NCContextMenu
            id="header_context"
            isOpen={isContextOpen}
            flyoverClassName="config_visual_editor_header__context_menu"
            options={[[
              {
                id: '1',
                label: (
                  <>
                    <i className="fa-solid fa-folder" />
                    {' '}
                    Open
                  </>
                ),
                labelText: '',
                onClick: () => {
                  navigate(`load-data?${searchParams.toString()}`);
                },
                type: NCContextMenu.itemTypes.BUTTON,
              },
              {
                id: '2',
                label: (
                  <>
                    <i className="fa-solid fa-save" />
                    {' '}
                    Save
                  </>
                ),
                labelText: '',
                onClick: onSave,
                type: NCContextMenu.itemTypes.BUTTON,
                disabled: !documentId,
              },
              {
                id: '3',
                label: (
                  <>
                    <i className="fa-solid fa-save" />
                    {' '}
                    Save As
                  </>
                ),
                labelText: '',
                onClick: onSaveAs,
                type: NCContextMenu.itemTypes.BUTTON,
                disabled: editorMode === EditorMode.UNINITIALIZED,
              },
              {
                id: '4',
                label: (
                  <>
                    <i className="fa-solid fa-trash" />
                    {' '}
                    Abandon Changes
                  </>
                ),
                labelText: '',
                onClick: abandonChanges,
                type: NCContextMenu.itemTypes.BUTTON,
                disabled: !hasUnsavedChanges,
              },
            ]]}
            setIsOpen={setIsContextOpen}
          >
            <NCButton
              appearance={NCButton.appearances.SOLID}
              color={NCButton.colors.GREY}
              size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            >
              Menu
            </NCButton>
          </NCContextMenu>
        </div>
      </div>

      <div className="nc-flex nc-flex--align_end nc-flex--justify_between config_visual_editor__tool_row">
        <div className="nc-flex nc-flex--align_end nc-flex--gap_1">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            color={NCButton.colors.DARK}
            onClick={onDrawerClick}
            active={isDrawerOpen}
            disabled={editorMode === EditorMode.UNINITIALIZED}
          >
            Objects
            {' '}
            {searchParams.get('type-filter')?.length ? <i className="fa-light fa-filter" /> : null}
          </NCButton>
        </div>

        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          color={NCButton.colors.DARK}
          onClick={onObjectsMenuClick}
          active={isObjectsMenuOpen}
          disabled={editorMode === EditorMode.UNINITIALIZED}
        >
          Params
        </NCButton>
      </div>
    </div>
  );
}

import { UnauthorizedError } from '@daupler/nexus-components';
import { CreateEntityPayload, Entity, EntityDetail } from '../types/Entity';
import {
  EntityConfigApplicationResponse,
  EntityConfigApplicationsResponse,
  EntityConfigApplyResponse,
  EntityConfigDocumentResponse,
  EntityConfigDocumentsResponse,
  EntityConfigPlanResponse,
  EntityConfigUtilityCategoriesResponse,
  PatchEntityConfigDocumentPayload,
  PostEntityConfigDocumentPayload,
} from '../types/EntityConfigApi';

type DauplerApiOptions = {
  baseUrl: string;
  fetch: typeof window.fetch;
};

export class DauplerApi {
  baseUrl: string;

  fetch: typeof window.fetch;

  constructor({
    baseUrl,
    fetch,
  }: DauplerApiOptions) {
    this.baseUrl = baseUrl;
    this.fetch = fetch;
  }

  static stripEntityIdPrefix(entityId: string): string {
    return entityId.split('-').slice(1).join('-');
  }

  async login(payload: { username: string; password: string }): Promise<string> {
    const response = await this.fetch(
      `${this.baseUrl}/api/app/login/`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.access;
  }

  async getTimezones(authToken: string): Promise<string[]> {
    const response = await this.fetch(
      `${this.baseUrl}/api/flux/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          variables: null,
          query: `
            query {
              tzNames
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.data.tzNames;
  }

  async getEntities(authToken: string): Promise<Entity[]> {
    const response = await this.fetch(
      `${this.baseUrl}/api/flux/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          variables: null,
          query: `
            query {
              viewableEntities {
                id
                name
                metadata {
                  type
                  purpose
                  configurationMethod
                }
              }
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.data.viewableEntities;
  }

  async getEntity(authToken: string, id: string): Promise<EntityDetail> {
    const response = await this.fetch(
      `${this.baseUrl}/api/flux/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          variables: null,
          query: `
            query {
              entity(id: "${id}") {
                id
                name
                shortName
                timezone
                parentEntities {
                  id
                  name
                }
              }
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    return body.data.entity;
  }

  async createEntity(
    authToken: string,
    payload: CreateEntityPayload,
  ): Promise<Entity> {
    const response = await this.fetch(
      `${this.baseUrl}/api/admin_app/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            mutation($input: CreateEntityInput!) {
              createEntity(data: $input) {
                __typename
                ... on EntityCreated {
                  newEntity {
                    id
                    name
                    metadata {
                      type
                      purpose
                      configurationMethod
                      rootEntityId
                    }
                  }
                }
                ... on SimpleError {
                  message
                }
              }
            }
          `,
          variables: { input: payload },
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    if (body.data.createEntity.__typename === 'EntityCreated') {
      return body.data.createEntity.newEntity;
    }
    if (body.data.createEntity.__typename === 'SimpleError') {
      throw new Error(`Error creating entity: ${body.data.createEntity.message}`);
    }
    throw new Error(`Unhandled response: ${JSON.stringify(body)}`);
  }

  async deleteEntity(
    authToken: string,
    entityId: string,
  ): Promise<null> {
    const response = await this.fetch(
      `${this.baseUrl}/api/admin_app/graphql/`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            mutation B {
              deleteEntity(data: {
                entityId: "${entityId}"
              }) {
                __typename
                ... on SimpleError {
                  message
                }
              }
            }
          `,
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    const body = await response.json();
    if (body.data.deleteEntity.__typename === 'EntityDeleted') {
      return null;
    }
    if (body.data.deleteEntity.__typename === 'SimpleError') {
      throw new Error(`Error deleting entity: ${body.data.deleteEntity.message}`);
    }
    throw new Error(`Unhandled response: ${JSON.stringify(body)}`);
  }

  async postEntityConfigDocument(
    authToken: string,
    entityId: string,
    configDocument: Partial<PostEntityConfigDocumentPayload>,
  ): Promise<EntityConfigDocumentResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/entities/${entityId}/configuration-documents/`,
      {
        method: 'POST',
        body: JSON.stringify(configDocument),
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async patchEntityConfigDocument(
    authToken: string,
    entityId: string,
    documentId: string,
    configDocument: Partial<PatchEntityConfigDocumentPayload>,
  ): Promise<EntityConfigDocumentResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/entities/${entityId}/configuration-documents/${documentId}/`,
      {
        method: 'PATCH',
        body: JSON.stringify(configDocument),
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async getEntityConfigDocuments(
    authToken: string,
    entityId: string,
  ): Promise<EntityConfigDocumentsResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/entities/${entityId}/configuration-documents`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async getEntityConfigDocument(
    authToken: string,
    entityId: string,
    configId: string,
  ): Promise<EntityConfigDocumentResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/entities/${entityId}/configuration-documents/${configId}/`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async getEntityConfigApplications(
    authToken: string,
    entityId: string,
  ): Promise<EntityConfigApplicationsResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/entities/${entityId}/configuration-applications/`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async getEntityConfigApplication(
    authToken: string,
    entityId: string,
    applicationId: string,
  ): Promise<EntityConfigApplicationResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/entities/${entityId}/configuration-applications/${applicationId}/`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async getUtilityCategories(
    authToken: string,
  ): Promise<EntityConfigUtilityCategoriesResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/configurations/utility_categories`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async planEntityConfig(
    authToken: string,
    entityId: string,
    configurationYaml: string,
    options?: {
      includeAiValidation?: boolean,
    },
  ): Promise<EntityConfigPlanResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/configurations:plan`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            attributes: {
              configuration_yaml: configurationYaml,
              options: {
                include_ai_validation: options?.includeAiValidation ?? false,
              },
            },
            relationships: {
              root_entity: {
                data: {
                  type: 'entities',
                  id: entityId,
                },
              },
            },
          },
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }

  async applyEntityConfig(
    authToken: string,
    entityId: string,
    configurationYaml: string,
    planHash: string,
    { dryRun }: { dryRun?: boolean } = {},
  ): Promise<EntityConfigApplyResponse> {
    const response = await this.fetch(
      `${this.baseUrl}/api/configurations:apply`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${authToken}`,
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            attributes: {
              configuration_yaml: configurationYaml,
              plan_hash: planHash,
              options: {
                dry_run: dryRun ?? false,
              },
            },
            relationships: {
              root_entity: {
                data: {
                  type: 'entities',
                  id: entityId,
                },
              },
            },
          },
        }),
      },
    );
    if (response.status === 403) { throw new UnauthorizedError(); }
    if (!response.ok) { throw new Error(await response.text()); }
    return response.json();
  }
}

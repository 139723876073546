import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { parse } from 'yaml';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorLoadDataModal } from '../components/ConfigVisualEditorLoadDataModal';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { useAuthentication } from '../hooks/useAuthentication';
import {
  EntityConfigDocumentResource,
} from '../types/EntityConfigApi';
import { useLoadConfigurationDocuments } from '../hooks/useLoadConfigurationDocuments';
import { deleteLocalConfigVersion, getStorageKeyForEntity, loadLocalDataForEntity } from '../utils/config-tools';

export function EntitiesLoadDataRoute() {
  const { id: entityId } = useParams();

  const {
    data,
    isLoading,
    onDownload,
  } = useLoadConfigurationDocuments({
    entityId: entityId ?? '',
    dauplerApi: useRef(new DauplerApi({
      baseUrl: config.dauplerApiBaseUrl,
      fetch: fetch.bind(window),
    })).current,
    authToken: useAuthentication().authToken ?? '',
  });

  const {
    setEntityConfig,
    entity,
  } = useEntityConfigEditor();

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleLoad = (configDocument: EntityConfigDocumentResource) => {
    if (!entity) { return; }
    setEntityConfig(parse(configDocument.attributes.yaml));
    setIsModalOpen(false);
    navigate(`/config/entities/${entity.id}/documents/${configDocument.id}`);
  };

  if (!entityId) { return null; }

  return (
    <ConfigVisualEditorLoadDataModal
      isLoading={isLoading}
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onCloseFinished={() => navigate(-1)}
      onDeleteLocal={(localConfig) => deleteLocalConfigVersion(
        getStorageKeyForEntity(entityId),
        localConfig,
      )}
      onDownload={onDownload}
      data={data}
      localData={loadLocalDataForEntity(getStorageKeyForEntity(entityId))}
      onLoad={handleLoad}
    />
  );
}

import {
  FormField,
  NCInputText,
  NCSelect,
} from '@daupler/nexus-components';
import clsx from 'clsx';
import './EntityFormFields.css';

type EntityFormFieldsFormState = {
  name: FormField<string>;
  shortName: FormField<string>;
  timezone: FormField<string>;
};
type EntityFormFieldsProps = {
  condensed?: boolean;
  disabled?: boolean;
  formState: EntityFormFieldsFormState;
  onChange: <K extends keyof EntityFormFieldsFormState>(
    fieldName: K | string,
    value: EntityFormFieldsFormState[K]['value'],
  ) => void;
  timezones: string[] | null;
};

export function EntityFormFields({
  condensed,
  disabled,
  formState,
  onChange,
  timezones,
}: Readonly<EntityFormFieldsProps>) {
  return (
    <>
      <div className={clsx({ 'entity_form_fields__row--condensed': condensed })}>
        <NCInputText
          className={clsx('nc-l-mt_200_mobile', {
            'entity_form_fields__row_item--2': condensed,
          })}
          label="Name"
          isValid={disabled ? undefined : formState.name.isValid}
          hint={formState.name.message}
          name={formState.name.name}
          onChange={(event) => onChange(formState.name.name, event.target.value)}
          value={formState.name.value}
          required
          disabled={disabled}
        />
        <NCInputText
          className={clsx('nc-l-mt_200_mobile', {
            'entity_form_fields__row_item--1': condensed,
          })}
          label="Short Name"
          isValid={disabled ? undefined : formState.shortName.isValid}
          hint={formState.shortName.message}
          name={formState.shortName.name}
          onChange={(event) => onChange(formState.shortName.name, event.target.value)}
          value={formState.shortName.value}
          required
          disabled={disabled}
        />
      </div>
      <div className={clsx({ 'entity_form_fields__row--condensed': condensed })}>
        <NCSelect
          className={clsx('nc-l-mt_200_mobile', {
            'entity_form_fields__row_item--2': condensed,
          })}
          label="Timezone"
          isValid={disabled ? undefined : formState.timezone.isValid}
          hint={formState.timezone.message}
          name={formState.timezone.name}
          onChange={(event) => onChange(formState.timezone.name, event.target.value)}
          options={[
            { value: '', label: 'Choose one...' },
            ...(timezones ?? []).map((tz) => ({
              label: tz,
              value: tz,
            })),
          ]}
          value={formState.timezone.value}
          required
          disabled={disabled}
        />
      </div>
    </>
  );
}

import clsx from 'clsx';
import { NCButton, NCLabel, NCLinkButton } from '@daupler/nexus-components';
import { ConfigVisualEditorField } from './ConfigVisualEditorField';
import './ConfigVisualEditorModule.css';

type ConfigVisualEditorModuleProps = {
  hasUnsavedChanges?: boolean;
  moduleType: string;
  data: Record<string, unknown>;
  editLink: string;
  icon: string;
  onRemove: () => void;
};

export function ConfigVisualEditorModule({
  hasUnsavedChanges,
  moduleType,
  data,
  editLink,
  icon,
  onRemove,
}: ConfigVisualEditorModuleProps) {
  return (
    <div className="config_visual_editor_module">
      <div className="nc-flex nc-flex--align_center">
        <NCLabel label="module" size={NCLabel.sizes.SM} color={NCLabel.colors.SUCCESS} />
        {' '}
        <span className="nc-t-sub_text_light_mobile nc-l-ml_100_mobile">
          {moduleType}
          {' '}
          <i
            className={clsx(
              'fa-solid',
              'fa-fw',
              icon,
              {
                'icon--unsaved': hasUnsavedChanges,
              },
            )}
          />
        </span>
      </div>
      <div className="nc-l-mt_100_mobile">
        <p className="nc-t-sub_text_medium_mobile">Data</p>
        {Object.entries(data).map(([key, value]) => (
          <ConfigVisualEditorField
            key={`${moduleType}-${key}`}
            fieldName={key}
            fieldValue={value}
            id={moduleType}
            preview
          />
        ))}
      </div>
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mt_200_mobile">
        <NCLinkButton
          width={[[NCLinkButton.breakpoints.MOBILE, NCLinkButton.widths.HUG]]}
          color={NCLinkButton.colors.PRIMARY}
          appearance={NCLinkButton.appearances.INVERSE}
          size={[[NCLinkButton.breakpoints.MOBILE, NCLinkButton.sizes.XS]]}
          to={editLink}
          state={{ wasAppLink: true }}
        >
          <i className="fa-light fa-pencil" />
          {' '}
          Edit
        </NCLinkButton>

        <NCButton
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          color={NCButton.colors.ERROR}
          appearance={NCButton.appearances.OUTLINE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
          onClick={onRemove}
        >
          <i className="fa-light fa-trash" />
          {' '}
          Remove
        </NCButton>
      </div>
    </div>
  );
}

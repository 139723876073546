import { CciConditionTimeInPartitionString, EntityConfigParamType } from '../../types/EntityConfig';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';
import { CciConditionOpProps } from './resource-form-types';

export function CciConditionTimeInPartitionStringForm({
  data,
  onChange,
  params,
}: CciConditionOpProps) {
  const timeInPartitionStringData = data as CciConditionTimeInPartitionString;
  const {
    _partition_string_ref: partitionStringRefValue,
    _tzname_ref: tzNameRefValue,
  } = timeInPartitionStringData ?? {};

  return (
    <>
      <ConfigVisualEditorParamControl
        label="Partition String"
        name="partition_string"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => {
          onChange({
            ...timeInPartitionStringData ?? {},
            _partition_string_ref: {
              key: event.target.value,
              type: EntityConfigParamType.STRING,
            },
          });
        }}
        value={partitionStringRefValue?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        className="nc-l-mt_200_mobile"
        label="Timezone Name"
        name="timezone_name"
        params={params}
        paramType={EntityConfigParamType.STRING}
        onChange={(event) => {
          onChange({
            ...timeInPartitionStringData ?? {},
            _tzname_ref: {
              key: event.target.value,
              type: EntityConfigParamType.STRING,
            },
          });
        }}
        value={tzNameRefValue?.key ?? ''}
      />
    </>
  );
}

import {
  NCButton,
  NCLoadingIndicator,
  NCTable,
  NCTableBody,
  NCTableHeader,
  NCTableHeaderCol,
  NCTableRow,
  NCTableRowCol,
  timestampShortDisplayString,
} from '@daupler/nexus-components';
import { useState } from 'react';
import { EntityConfigDocumentResource } from '../types/EntityConfigApi';
import { SegmentedControl } from './SegmentedControl';
import './ConfigVisualEditorLoadDataView.css';

enum View {
  REMOTE = 'REMOTE',
  LOCAL = 'LOCAL',
}

export type EnhancedConfigDocumentResource = (EntityConfigDocumentResource
& { meta: { applied: string; latest: boolean } });

type ConfigVisualEditorLoadDataViewProps = {
  data?: EnhancedConfigDocumentResource[];
  isLoading?: boolean;
  localData?: EnhancedConfigDocumentResource[];
  onDeleteLocal: (config: EntityConfigDocumentResource) => void;
  onDownload: (config: EntityConfigDocumentResource) => void;
  onLoad: (config: EntityConfigDocumentResource) => void;
};

export function ConfigVisualEditorLoadDataView({
  data,
  isLoading,
  localData,
  onDeleteLocal,
  onDownload,
  onLoad,
}: ConfigVisualEditorLoadDataViewProps) {
  const [view, setView] = useState(View.REMOTE);

  const dataToDisplay = view === View.REMOTE ? data : localData;

  return (
    <>
      <SegmentedControl
        onChange={(value) => setView(value as View)}
        segments={[
          { label: 'Remote', value: View.REMOTE },
          { label: 'Local', value: View.LOCAL },
        ]}
        value={view}
        className="config_visual_editor_load_data_view__segmented nc-l-mb_200_mobile"
      />
      {isLoading ? (
        <div className="nc-flex nc-flex--align_center nc-flex--justify_center nc-l-pa_200_mobile">
          <NCLoadingIndicator label="Loading configuration documents" />
        </div>
      ) : null}
      {dataToDisplay?.length ? (
        <NCTable>
          <NCTableHeader>
            <NCTableHeaderCol className="nc-t-left">Saved</NCTableHeaderCol>
            <NCTableHeaderCol>Applied</NCTableHeaderCol>
            <NCTableHeaderCol>Latest</NCTableHeaderCol>
            <NCTableHeaderCol>{' '}</NCTableHeaderCol>
            <NCTableHeaderCol>{' '}</NCTableHeaderCol>
            {view === View.LOCAL ? <NCTableHeaderCol>{' '}</NCTableHeaderCol> : null}
          </NCTableHeader>
          <NCTableBody>
            {dataToDisplay.map((configDocument) => (
              <NCTableRow key={configDocument.id}>
                <NCTableRowCol>{timestampShortDisplayString('en-US', configDocument.attributes.timestamps.updated)}</NCTableRowCol>
                <NCTableRowCol>
                  {configDocument.meta.applied ? timestampShortDisplayString('en-US', configDocument.meta.applied) : ''}
                </NCTableRowCol>
                <NCTableRowCol className="nc-t-center">
                  {configDocument.meta.latest ? <i className="fa-light fa-check" /> : null}
                </NCTableRowCol>
                <NCTableRowCol>
                  <NCButton
                    appearance={NCButton.appearances.SOLID}
                    color={NCButton.colors.GREY}
                    size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
                    width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                    onClick={() => onLoad(configDocument)}
                  >
                    Load
                  </NCButton>
                </NCTableRowCol>
                <NCTableRowCol>
                  <NCButton
                    appearance={NCButton.appearances.SOLID}
                    color={NCButton.colors.GREY}
                    size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
                    width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                    onClick={() => onDownload(configDocument)}
                  >
                    <i className="fa-solid fa-download" />
                  </NCButton>
                </NCTableRowCol>
                {view === View.LOCAL ? (
                  <NCTableRowCol>
                    <NCButton
                      appearance={NCButton.appearances.SOLID}
                      color={NCButton.colors.GREY}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.DEFAULT]]}
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      onClick={() => onDeleteLocal(configDocument)}
                    >
                      <i className="fa-solid fa-trash" />
                    </NCButton>
                  </NCTableRowCol>
                ) : null}
              </NCTableRow>
            ))}
          </NCTableBody>
        </NCTable>
      ) : (
        <p className="nc-t-grey_700 nc-t-center nc-t-body_light nc-l-pa_200_mobile">
          No configurations saved for this Entity!
        </p>
      )}
    </>
  );
}

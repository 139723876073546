import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResourceType } from '../types/EntityConfig';
import { ConfigVisualEditorMacroModal } from '../components/ConfigVisualEditorMacroModal';

export function EntitiesIdAddMacroRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    entity,
  } = useEntityConfigEditor();

  const handleSubmit = async () => {
  };

  const handleExit = () => {
    if (!entity) { return; }
    searchParams.delete('resource-type');
    if (location.state?.isRefLink || location.state?.wasAppLink) {
      navigate(-1);
    } else {
      navigate(`/config/entities/${entity.id}?${searchParams.toString()}`);
    }
  };

  const typeToAdd = searchParams.get('resource-type');

  if (!entity) { return null; }

  return (
    <ConfigVisualEditorMacroModal
      onBack={handleExit}
      resourceType={typeToAdd as EntityConfigResourceType}
      onClose={handleExit}
      onSubmit={handleSubmit}
      resource={location.state?.resourceData}
      wipResource={null}
    />
  );
}

import {
  NCButton,
  NCInputText,
} from '@daupler/nexus-components';
import { useState } from 'react';
import { EntityConfigResourceType } from '../types/EntityConfig';
import './ConfigVisualEditorObjectPicker.css';

type ConfigVisualEditorObjectPickerProps = {
  items: {
    name: string;
    id: string;
    description?: string;
    icon?: React.JSX.Element;
  }[];
  onChange: (id: string) => void;
  title: string;
  value: string;
};

export function ConfigVisualEditorObjectPicker({
  items,
  onChange,
  title,
  value,
}: ConfigVisualEditorObjectPickerProps) {
  const [searchFilter, setSearchFilter] = useState('');

  return (
    <>
      <div className="nc-flex nc-flex--align_end nc-flex--justify_between nc-l-mt_100_mobile">
        <p className="nc-t-h5_medium_mobile">{title}</p>
        <NCInputText
          label="Search"
          labelHidden
          value={searchFilter}
          onChange={(event) => { setSearchFilter(event.target.value); }}
          name="resource-search"
          tag={(<i className="fa-solid fa-search fa-fw" />)}
        />
      </div>
      <div className="nc-l-mt_200_mobile config_visual_editor_object_picker__grid">
        {items
          .filter(({ name }) => name.toLowerCase().includes(searchFilter))
          .length === 0 ? (
            <p className="nc-t-sub_text_light nc-t-grey_700">No items matching filter.</p>
          ) : null}
        {items
          .filter(({ name }) => name.toLowerCase().includes(searchFilter))
          .map((item) => (
            <div key={item.id} className="config_visual_editor_object_picker__item">
              <p className="nc-t-body_medium_mobile">
                {item.icon}
                {' '}
                {item.name}
              </p>
              {item.description ? (
                <p className="nc-t-sub_text_regular_mobile">
                  {item.description}
                </p>
              ) : null}
              <NCButton
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                appearance={NCButton.appearances.OUTLINE}
                color={NCButton.colors.PRIMARY}
                active={value === item.id}
                className="nc-l-mt_100_mobile"
                disabled={!!(value && value !== item.id)}
                onClick={() => {
                  if (value === item.id) {
                    onChange('' as EntityConfigResourceType);
                  } else {
                    onChange(item.id);
                  }
                }}
              >
                {value === item.id
                  ? 'Unselect'
                  : 'Select'}
              </NCButton>
            </div>
          ))}
      </div>
    </>
  );
}

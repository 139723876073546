import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import { CciConditionData, CciConditionOperation, EntityConfigResourceCciCondition } from '../../types/EntityConfig';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { CciConditionOp } from './CciConditionOp';

export function CciConditionForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      op: opValue,
    },
  } = (resourceData as EntityConfigResourceCciCondition) ?? { data: {} };
  const dataValue = (resourceData?.data as CciConditionData) ?? {};
  const op: FormField<CciConditionOperation | ''> = {
    name: 'op',
    value: opValue ?? '',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const data: FormField<CciConditionData | null> = {
    name: 'data',
    value: dataValue ?? null,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };

  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    data,
    op,
  });
  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        ...formState.data.value,
        op: formState.op.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />

      <NCSelect
        label="Op"
        name="op"
        className="nc-l-mt_200_mobile"
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(CciConditionOperation)
            .map((value) => ({ label: value, value })),
        ]}
        onChange={(event) => {
          onFormChange(
            formState.op.name,
            event.target.value,
          );
        }}
        value={formState.op.value}
      />

      {formState.op.value ? (
        <div className="nc-l-mt_200_mobile">
          <CciConditionOp
            op={formState.op.value}
            cciConditionData={formState.data.value}
            entityId={entityId}
            onChange={(updatedData) => onFormChange(formState.data.name, updatedData)}
            params={params}
            resources={resources}
          />
        </div>
      ) : null}
    </>
  );
}

import {
  FormField,
  NCInputCheckbox,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import {
  getBaseResourceFormFields,
  getResourceRefFromId,
  utilityScenarioCategoryListToNodeList,
} from '../../utils/resource-tools';
import {
  AiTag,
  EntityConfigRef,
  EntityConfigResourceServiceCategory,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';
import { ChecklistControl } from '../ChecklistControl';

export function ServiceCategoryForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  utilityCategories,
  workgroups,
}: ResourceFormProps) {
  const {
    data: dataValue,
  } = resourceData as EntityConfigResourceServiceCategory ?? { data: {} };
  const {
    _cci_dispatch_rule_ref: cciDispatchRuleRefValue,
    _dispatch_policy_ref: dispatchPolicyRefVaule,
    _incident_version_ref: incidentVersionRefValue,
    _service_area_ref: serviceAreaRefValue,
    _service_lists_ref_list: serviceListRefsValue,
    scenarios: scenariosValue,
    is_policy_fallback_category: isPolicyFallbackCategoryValue,
  } = dataValue ?? {};
  const dispatchPolicyRef: FormField<EntityConfigRef | undefined> = {
    name: 'dispatchPolicyRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: dispatchPolicyRefVaule,
  };
  const incidentVersionRef: FormField<EntityConfigRef | undefined> = {
    name: 'incidentVersionRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: incidentVersionRefValue,
  };
  const serviceAreaRef: FormField<EntityConfigRef | undefined> = {
    name: 'serviceAreaRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: serviceAreaRefValue,
  };
  const serviceListRefs: FormField<EntityConfigRef[]> = {
    name: 'serviceListRefs',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: serviceListRefsValue ?? [],
  };
  const cciDispatchRuleRef: FormField<EntityConfigRef | undefined> = {
    name: 'cciDispatchRuleRef',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: cciDispatchRuleRefValue,
  };
  const isPolicyFallbackCategory: FormField<boolean> = {
    name: 'isPolicyFallbackCategory',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: isPolicyFallbackCategoryValue ?? false,
  };
  const scenarios: FormField<{ v2: [string, string] }[] | null> = {
    name: 'scenarios',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: (scenariosValue ?? []).map(({ v2 }) => ({
      v2: [
        v2[0] === null ? 'null' : v2[0],
        v2[1] === null ? 'null' : v2[1],
      ],
    })),
  };
  const simpleAiTags: FormField<AiTag[]> = {
    name: 'simpleAiTags',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    incidentVersionRef,
    dispatchPolicyRef,
    serviceAreaRef,
    serviceListRefs,
    cciDispatchRuleRef,
    isPolicyFallbackCategory,
    scenarios,
    simpleAiTags,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _dispatch_policy_ref: formState.dispatchPolicyRef.value,
        _incident_version_ref: formState.incidentVersionRef.value,
        _service_area_ref: formState.serviceAreaRef.value,
        _service_lists_ref_list: formState.serviceListRefs.value,
        _cci_dispatch_rule_ref: formState.cciDispatchRuleRef.value,
        is_policy_fallback_category: formState.isPolicyFallbackCategory.value,
        scenarios: (formState.scenarios.value ?? [])
          .map(({ v2 }) => ({
            v2: [
              v2[0] === 'null' ? null : v2[0],
              v2[1] === 'null' ? null : v2[1],
            ],
          })),
        simple_ai_tags: formState.simpleAiTags.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <ConfigVisualEditorResourceRefControl
        label="Dispatch Policy"
        className="nc-l-mt_200_mobile"
        name={formState.dispatchPolicyRef.name}
        resources={resources}
        resourceType={EntityConfigResourceType.DISPATCH_POLICIES}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.dispatchPolicyRef.name,
          getResourceRefFromId(
            event.target.value,
            EntityConfigResourceType.DISPATCH_POLICIES,
            resources,
          ),
        )}
        value={formState.dispatchPolicyRef.value?.key ?? ''}
      />
      <ConfigVisualEditorResourceRefControl
        label="Incident Version"
        className="nc-l-mt_200_mobile"
        name={formState.incidentVersionRef.name}
        resources={resources}
        resourceType={EntityConfigResourceType.INCIDENT_VERSIONS}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.incidentVersionRef.name,
          getResourceRefFromId(
            event.target.value,
            EntityConfigResourceType.INCIDENT_VERSIONS,
            resources,
          ),
        )}
        value={formState.incidentVersionRef.value?.key ?? ''}
      />
      <ConfigVisualEditorResourceRefControl
        label="Service Area"
        className="nc-l-mt_200_mobile"
        name={formState.serviceAreaRef.name}
        resourceType={EntityConfigResourceType.SERVICE_AREAS}
        resources={resources}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.serviceAreaRef.name,
          getResourceRefFromId(
            event.target.value,
            EntityConfigResourceType.SERVICE_AREAS,
            resources,
          ),
        )}
        value={formState.serviceAreaRef.value?.key ?? ''}
      />
      <ConfigVisualEditorResourceRefControl
        label="Service Lists"
        className="nc-l-mt_200_mobile"
        name={formState.serviceListRefs.name}
        multiple
        resourceType={EntityConfigResourceType.SERVICE_LISTS}
        resources={resources}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.serviceListRefs.name,
          Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => ({
              key: option.value,
              type: EntityConfigResourceType.SERVICE_LISTS,
            })),
        )}
        value={formState.serviceListRefs.value.map(({ key }) => key)}
      />
      <ConfigVisualEditorResourceRefControl
        entityId={entityId}
        label="CCI Dispatch Rule"
        className="nc-l-mt_200_mobile"
        name={formState.cciDispatchRuleRef.name}
        resources={resources}
        resourceType={EntityConfigResourceType.CCI_DISPATCH_RULES}
        onChange={(event) => onFormChange(
          formState.cciDispatchRuleRef.name,
          getResourceRefFromId(
            event.target.value,
            EntityConfigResourceType.CCI_DISPATCH_RULES,
            resources,
          ),
        )}
        value={formState.cciDispatchRuleRef.value?.key ?? ''}
      />
      <NCInputCheckbox
        className="nc-l-mt_200_mobile"
        label="Is Policy Fallback Category?"
        name={formState.isPolicyFallbackCategory.name}
        checked={formState.isPolicyFallbackCategory.value}
        onChange={() => onFormChange(
          formState.isPolicyFallbackCategory.name,
          !formState.isPolicyFallbackCategory.value,
        )}
      />

      <ChecklistControl
        name={formState.scenarios.name}
        label="Scenarios"
        className="nc-l-mt_200_mobile"
        onChange={(value) => {
          onFormChange(
            formState.scenarios.name,
            value.map((labelPair) => ({ v2: labelPair.split('|') as [string, string] })),
          );
        }}
        value={formState.scenarios.value?.map(({ v2 }) => v2.join('|')) ?? []}
        tree={utilityScenarioCategoryListToNodeList(utilityCategories)}
      />

      <NCSelect
        label="Simple AI Tags"
        className="nc-l-mt_200_mobile"
        name={formState.simpleAiTags.name}
        multiple
        options={Object.values(AiTag).map((aiTag) => ({
          label: aiTag,
          value: aiTag,
        }))}
        onChange={(event) => onFormChange(
          formState.simpleAiTags.name,
          Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => option.value) as AiTag[],
        )}
        value={formState.simpleAiTags.value ?? ''}
      />
    </>
  );
}

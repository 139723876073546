import { EntityConfigMacroType } from '../types/EntityConfigUIExtensions';

export function getIconForMacroType(type: EntityConfigMacroType) {
  switch (type) {
    default:
      return 'fa-square';
  }
}

export function getDescriptionForMacroType(type: EntityConfigMacroType) {
  switch (type) {
    default:
      return '';
  }
}

import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigModule } from '../types/EntityConfig';
import { ConfigVisualEditorModuleModal } from '../components/ConfigVisualEditorModuleModal';
import { useAuthentication } from '../hooks/useAuthentication';

export function EntitiesEditModuleRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    params,
    resources,
    modules,
    entity,
    wipData,
  } = useEntityConfigEditor();

  const { moduleId } = useParams();
  const [,id] = moduleId?.split('|') ?? [];

  const { authToken } = useAuthentication();
  const { documentId } = useParams();
  const handleSubmit = async (module: EntityConfigModule) => {
    if (!authToken || !documentId) { return; }
    if (!moduleId) { return; }
    modules.update(moduleId, module);
  };

  const handleExit = () => {
    if (!entity) { return; }
    if (location.state?.isRefLink || location.state?.wasAppLink) {
      navigate(-1);
    } else {
      navigate(`/config/entities/${entity.id}?${searchParams.toString()}`);
    }
  };

  const module = modules.data?.find(({ id: key }) => key === id);

  const handleProgressSave = (values: unknown) => {
    if (!module) { return; }
    wipData.save(module.id, values);
  };

  if (!entity) { return null; }
  return (
    <ConfigVisualEditorModuleModal
      entityId={entity?.id ?? ''}
      onSaveProgress={handleProgressSave}
      onBack={handleExit}
      onClose={handleExit}
      module={module}
      onSubmit={handleSubmit}
      params={params.data ?? []}
      resources={resources.data ?? []}
      wipData={wipData.get()?.[module?.id ?? ''] as EntityConfigModule}
    />
  );
}

import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { parse } from 'yaml';
import './EntitiesIdRoute.css';
import { ConfigVisualEditorLoadDataView } from '../components/ConfigVisualEditorLoadDataView';
import { useLoadConfigurationDocuments } from '../hooks/useLoadConfigurationDocuments';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { useAuthentication } from '../hooks/useAuthentication';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigDocumentResource } from '../types/EntityConfigApi';
import { EditorMode } from '../types/ConfigEditor';
import { deleteLocalConfigVersion, getStorageKeyForEntity, loadLocalDataForEntity } from '../utils/config-tools';

export function EntitiesIdRoute() {
  const { id: entityId, documentId } = useParams();

  const {
    data,
    isLoading,
    onDownload,
  } = useLoadConfigurationDocuments({
    entityId: entityId ?? '',
    dauplerApi: useRef(new DauplerApi({
      baseUrl: config.dauplerApiBaseUrl,
      fetch: fetch.bind(window),
    })).current,
    authToken: useAuthentication().authToken ?? '',
  });

  const navigate = useNavigate();
  const { setEntityConfig, editor } = useEntityConfigEditor();
  useEffect(() => {
    if (editor.mode === EditorMode.UNINITIALIZED) { return; }
    if (documentId) { return; }
    editor.setMode(EditorMode.UNINITIALIZED);
  }, [documentId, editor]);

  const handleLoad = (configDocument: EntityConfigDocumentResource) => {
    if (!entityId) { return; }
    editor.setMode(EditorMode.VISUAL);
    setEntityConfig(parse(configDocument.attributes.yaml));
    navigate(`/config/entities/${entityId}/documents/${configDocument.id}`);
  };

  const localData = loadLocalDataForEntity(getStorageKeyForEntity(entityId ?? ''));

  useEffect(() => {
    if (!data) { return; }
    if (data?.length || localData.length) { return; }
    editor.setMode(EditorMode.VISUAL);
    navigate(`/config/entities/${entityId}/documents/new`, { replace: true });
  }, [data, editor, entityId, localData.length, navigate]);

  return (
    <div className="nc-l-pa_300_mobile">
      <h1 className="nc-t-h1_medium_mobile">Prepare Workspace</h1>
      <p className="nc-t-body_light_mobile">Create a new configuration or load from existing</p>
      <div className="nc-l-mt_300_mobile entities_id_route__action_container">
        <ConfigVisualEditorLoadDataView
          onDeleteLocal={(localConfig) => deleteLocalConfigVersion(
            getStorageKeyForEntity(entityId ?? ''),
            localConfig,
          )}
          onDownload={onDownload}
          onLoad={handleLoad}
          data={data}
          localData={localData}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

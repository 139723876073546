import { useState } from 'react';
import {
  FormField,
  NCInputCheckbox,
  NCSelect,
  useForm,
} from '@daupler/nexus-components';
import { Tooltip } from '@mui/material';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import {
  EntityConfigResourceType,
  EntityConfigResourceWorkgroupRole,
  EntityConfigWorkgroupPermissionAction,
  WorkgroupRolePermission,
} from '../../types/EntityConfig';
import { InputList } from '../InputList';

export function WorkgroupRoleForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      can_use_password: canUsePasswordValue,
      permissions: permissionsValue,
    },
  } = (resourceData as EntityConfigResourceWorkgroupRole) ?? { data: {} };
  const getEmptyWorkgroupPermission = () => ({
    _workgroup_ref: undefined,
    action: '' as EntityConfigWorkgroupPermissionAction,
  });
  const [
    newWorkgroupPermission,
    setNewWorkgroupPermission,
  ] = useState<WorkgroupRolePermission>(getEmptyWorkgroupPermission());

  const canUsePassword: FormField<boolean> = {
    name: 'canUsePassword',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: canUsePasswordValue ?? false,
  };
  const permissions: FormField<WorkgroupRolePermission[]> = {
    name: 'permissions',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: permissionsValue ?? [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      resourceData,
      resources,
      resourceType,
      isResourceReferenced,
    }),
    canUsePassword,
    permissions,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        can_use_password: formState.canUsePassword.value,
        permissions: formState.permissions.value.map((permission) => {
          const { _workgroup_ref: ref } = permission;
          return {
            _workgroup_ref: ref,
            action: permission.action,
          };
        }),
      },
    },
  });

  const addPermission = (permissionOverride?: WorkgroupRolePermission[]) => {
    const { _workgroup_ref: ref } = newWorkgroupPermission;
    onFormChange(
      formState.permissions.name,
      [
        ...formState.permissions.value,
        ...permissionOverride || [
          {
            action: newWorkgroupPermission.action,
            _workgroup_ref: ref,
          },
        ],
      ],
    );
    setNewWorkgroupPermission(getEmptyWorkgroupPermission());
  };
  const clearPermission = () => {
    setNewWorkgroupPermission(getEmptyWorkgroupPermission());
  };
  const removePermission = (data: WorkgroupRolePermission[]) => {
    onFormChange(
      formState.permissions.name,
      data,
    );
  };

  const {
    _workgroup_ref: newWorkgroupRefValue,
  } = newWorkgroupPermission;
  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <NCInputCheckbox
        label="Can use password?"
        className="nc-l-mt_200_mobile"
        name={formState.canUsePassword.name}
        checked={formState.canUsePassword.value}
        onChange={(event) => onFormChange(
          formState.canUsePassword.name,
          event.target.checked,
        )}
      />

      <InputList<WorkgroupRolePermission>
        data={formState.permissions.value}
        label="Permissions"
        onAdd={addPermission}
        onClear={clearPermission}
        onRemove={removePermission}
        secondaryActions={[
          {
            label: 'Manager',
            onClick: () => addPermission([
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.ENTITIES_MANAGE,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.ENTITIES_VIEW,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_ADD,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_CALL,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_CHANGE,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_DELETE,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_MANAGE,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_VIEW,
              },
            ]),
          },
          {
            label: 'Crew',
            onClick: () => addPermission([
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.ENTITIES_VIEW,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_ADD,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_CALL,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_CHANGE,
              },
              {
                _workgroup_ref: formState.workgroupRef.value,
                action: EntityConfigWorkgroupPermissionAction.INCIDENTS_VIEW,
              },
            ]),
          },
        ]}
        renderItem={(permission) => (
          <Tooltip
            title={(
              <ul>
                <li>
                  Workgroup:
                  {' '}
                  {workgroups.find(({ id }) => {
                    const { _workgroup_ref: ref } = permission;
                    return ref?.key === id;
                  })?.state.display_name}
                </li>
                <li>
                  Action:
                  {' '}
                  {permission.action}
                </li>
              </ul>
            )}
          >
            <span>
              {workgroups.find(({ id }) => {
                const { _workgroup_ref: ref } = permission;
                return ref?.key === id;
              })?.state.display_name}
              {` (${permission.action})`}
            </span>
          </Tooltip>
        )}
        placeholder="No Permissions here yet!"
      >
        <NCSelect
          label="Workgroup"
          name="action-workgroup"
          onChange={(event) => setNewWorkgroupPermission(
            (state) => ({
              ...state,
              _workgroup_ref: {
                key: event.target.value,
                type: EntityConfigResourceType.WORKGROUPS,
              },
            }),
          )}
          value={newWorkgroupRefValue?.key ?? ''}
          options={[
            { label: 'Choose one...', value: '' },
            ...workgroups
              .filter(({ state }) => state.type === EntityConfigResourceType.WORKGROUPS)
              .map(({ state: resource, id }) => ({
                label: resource.display_name,
                value: id,
              })),
          ]}
        />
        <NCSelect
          label="Action"
          name="action-action"
          onChange={(event) => setNewWorkgroupPermission(
            (state) => ({
              ...state,
              action: event.target.value as EntityConfigWorkgroupPermissionAction,
            }),
          )}
          value={newWorkgroupPermission.action}
          options={[
            { label: 'Choose one...', value: '' },
            ...Object.values(EntityConfigWorkgroupPermissionAction).map((action) => ({
              label: action,
              value: action,
            })),
          ]}
        />
      </InputList>
    </>
  );
}

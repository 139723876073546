import {
  FormField,
  NCInputCheckbox,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import { EntityConfigResourceIncidentRole } from '../../types/EntityConfig';

export function IncidentRoleForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      can_call: canCallValue,
      can_change: canChangeValue,
      can_manage: canManageValue,
      can_view: canViewValue,
    },
  } = resourceData as EntityConfigResourceIncidentRole ?? { data: {} };
  const canView: FormField<boolean> = {
    name: 'canView',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: canViewValue ?? false,
  };
  const canChange: FormField<boolean> = {
    name: 'canChange',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: canChangeValue ?? false,
  };
  const canCall: FormField<boolean> = {
    name: 'canCall',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: canCallValue ?? false,
  };
  const canManage: FormField<boolean> = {
    name: 'canManage',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: canManageValue ?? false,
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    canView,
    canChange,
    canCall,
    canManage,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        can_view: formState.canView.value,
        can_change: formState.canChange.value,
        can_call: formState.canCall.value,
        can_manage: formState.canManage.value,
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <NCInputCheckbox
        label="Can View"
        className="nc-l-mt_200_mobile"
        name={formState.canView.name}
        onChange={(event) => onFormChange(formState.canView.name, event.target.checked)}
        checked={formState.canView.value}
      />
      <NCInputCheckbox
        label="Can Change"
        className="nc-l-mt_200_mobile"
        name={formState.canChange.name}
        onChange={(event) => onFormChange(formState.canChange.name, event.target.checked)}
        checked={formState.canChange.value}
      />
      <NCInputCheckbox
        label="Can Call"
        className="nc-l-mt_200_mobile"
        name={formState.canCall.name}
        onChange={(event) => onFormChange(formState.canCall.name, event.target.checked)}
        checked={formState.canCall.value}
      />
      <NCInputCheckbox
        label="Can Manage"
        className="nc-l-mt_200_mobile"
        name={formState.canManage.name}
        onChange={(event) => onFormChange(formState.canManage.name, event.target.checked)}
        checked={formState.canManage.value}
      />
    </>
  );
}

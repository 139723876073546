import { v4, validate } from 'uuid';
import { useParams } from 'react-router';
import { useRef, useState } from 'react';
import { parse } from 'yaml';
import { NCLoadingIndicator, NCToast } from '@daupler/nexus-components';
import { useAuthentication } from '../hooks/useAuthentication';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { useFetchData } from '../hooks/useFetchData';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { useToast } from '../hooks/useToasts';

export function ProvideConfigurationDocument({
  children,
}: React.PropsWithChildren) {
  const { addToast } = useToast();
  const { entity, setEntityConfig } = useEntityConfigEditor();
  const { documentId } = useParams();
  const { authToken } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const dauplerApi = useRef(new DauplerApi({
    baseUrl: config.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));
  useFetchData(async () => {
    if (!documentId || !entity || !authToken) { return; }
    if (!validate(documentId)) { return; }
    try {
      setIsLoading(true);
      const response = await dauplerApi.current.getEntityConfigDocument(
        authToken,
        DauplerApi.stripEntityIdPrefix(entity.id),
        documentId,
      );
      setEntityConfig(parse(response.data.attributes.yaml));
    } catch (err) {
      addToast({
        body: (err as Error).message,
        subject: 'Failed to load Cofiguration Document',
        id: v4(),
        type: NCToast.style.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  });
  if (isLoading) { return <NCLoadingIndicator label="Loading configuration document" blockUi />; }

  return (
    <div>
      {children}
    </div>
  );
}

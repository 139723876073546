import { EditorEntityParam, EditorEntityResource } from '../../hooks/useEntityConfigEditor';
import { CciConditionData, CciConditionOperation } from '../../types/EntityConfig';
import { CciConditionAndOrNotForm } from './CciConditionAndOrNotForm';
import { CciConditionCategoryInForm } from './CciConditionCategoryInForm';
import { CciConditionPolicyInForm } from './CciConditionPolicyIn';
import { CciConditionTimeInPartitionStringForm } from './CciConditionTimeInPartitionString';

type CciConditionOpProps = {
  op: CciConditionOperation;
  cciConditionData: CciConditionData | null;
  entityId: string;
  onChange: (data: CciConditionData) => void;
  params: EditorEntityParam[];
  resources: EditorEntityResource[];
};

export function CciConditionOp({
  op,
  cciConditionData,
  entityId,
  onChange,
  params,
  resources,
}: CciConditionOpProps) {
  switch (op) {
    case CciConditionOperation.AND:
    case CciConditionOperation.NOT:
    case CciConditionOperation.OR:
      return (
        <CciConditionAndOrNotForm
          data={cciConditionData}
          entityId={entityId}
          onChange={onChange}
          params={params}
          resources={resources}
        />
      );
    case CciConditionOperation.CATEGORY_IN:
      return (
        <CciConditionCategoryInForm
          data={cciConditionData}
          entityId={entityId}
          onChange={onChange}
          params={params}
          resources={resources}
        />
      );
    case CciConditionOperation.POLICY_IN:
      return (
        <CciConditionPolicyInForm
          data={cciConditionData}
          entityId={entityId}
          onChange={onChange}
          params={params}
          resources={resources}
        />
      );
    case CciConditionOperation.TIME_IN_PARTITION_STRING:
      return (
        <CciConditionTimeInPartitionStringForm
          data={cciConditionData}
          entityId={entityId}
          onChange={onChange}
          params={params}
          resources={resources}
        />
      );
    case CciConditionOperation.HAS_REMOTE_RECONNECT_METER:
    case CciConditionOperation.HAS_TURN_ON_AMOUNT:
    case CciConditionOperation.REPORTED_BY_911:
      return null;
    default:
      return (<p>Unknown Op</p>);
  }
}

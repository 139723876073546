import { useState } from 'react';
import { NCInputText, NCSelect } from '@daupler/nexus-components';
import { IncidentEmailProcessorProps } from './resource-form-types';
import { EntityConfigParamType, EntityConfigResourceType, IncidentEmailProcessorSouthCarolina811Options } from '../../types/EntityConfig';
import { InputList } from '../InputList';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function IncidentEmailProcessorSouthCarolina811({
  onChange,
  params,
  value,
  workgroups,
}: IncidentEmailProcessorProps) {
  const {
    _workgroup_ref: workgroupRef,
    _tzname_ref: tzNameRef,
    emergency_statuses: emergencyStatuses,
    emergency_etc_hours: emergencyEtcHours,
    incident_version: incidentVersion,
    category_name_template: categoryNameTemplate,
    target_entity_id: targetEntityId,
  } = (value as IncidentEmailProcessorSouthCarolina811Options) ?? {};

  const [newEmergencyStatus, setNewEmergencyStatus] = useState('');

  return (
    <>
      <NCSelect
        label="Workgroup"
        name="south_carolina_811-workgroup_id"
        options={[
          { label: 'Choose one...', value: '' },
          ...workgroups.map(({ state: resource, id }) => ({
            label: resource.display_name,
            value: id,
          })),
        ]}
        value={workgroupRef?.key ?? ''}
        onChange={(event) => onChange({
          ...(value as IncidentEmailProcessorSouthCarolina811Options),
          _workgroup_ref: { key: event.target.value, type: EntityConfigResourceType.WORKGROUPS },
        })}
      />
      <ConfigVisualEditorParamControl
        label="TZ Name"
        name="south_carolina_811-tzname"
        className="nc-l-mt_200_mobile"
        params={params}
        paramType={EntityConfigParamType.STRING}
        value={tzNameRef?.key ?? ''}
        onChange={(event) => onChange({
          ...(value as IncidentEmailProcessorSouthCarolina811Options),
          _tzname_ref: { key: event.target.value, type: EntityConfigParamType.STRING },
        })}
      />
      <InputList<string>
        data={(emergencyStatuses ?? [])}
        label="Emergency Statuses"
        onAdd={() => {
          onChange({
            ...(value as IncidentEmailProcessorSouthCarolina811Options),
            emergency_statuses: [
              ...(value as IncidentEmailProcessorSouthCarolina811Options).emergency_statuses ?? [],
              newEmergencyStatus,
            ],
          });
          setNewEmergencyStatus('');
        }}
        onClear={() => {
          setNewEmergencyStatus('');
        }}
        onRemove={(data) => {
          onChange({
            ...(value as IncidentEmailProcessorSouthCarolina811Options),
            emergency_statuses: data,
          });
        }}
        renderItem={(emergencyStatus) => (
          <p>{emergencyStatus}</p>
        )}
        placeholder="No Emergency Statuses here yet!"
      >
        <NCInputText
          name="south_carolina_811-new_emergency_status"
          label="Emergency Status"
          value={newEmergencyStatus}
          onChange={(event) => setNewEmergencyStatus(event.target.value)}
        />
      </InputList>
      <NCInputText
        label="Emergency ETC Hours"
        name="south_carolina_811-emergency_etc_hours"
        type="number"
        step={1}
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorSouthCarolina811Options),
            emergency_etc_hours: parseInt(event.target.value, 10),
          });
        }}
        value={emergencyEtcHours}
      />
      <NCInputText
        label="Incident Version"
        name="south_carolina_811-incident_version"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorSouthCarolina811Options),
            incident_version: event.target.value,
          });
        }}
        value={incidentVersion}
      />
      <NCInputText
        label="Category Name Template"
        name="south_carolina_811-category_name"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorSouthCarolina811Options),
            category_name_template: event.target.value,
          });
        }}
        value={categoryNameTemplate}
      />
      <NCInputText
        label="Target Entity ID"
        name="south_carolina_811-target_entity_id"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorSouthCarolina811Options),
            target_entity_id: event.target.value,
          });
        }}
        value={targetEntityId}
      />
    </>
  );
}

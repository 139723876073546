import { CciConditionCategoryIn, EntityConfigResourceType } from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';
import { CciConditionOpProps } from './resource-form-types';

export function CciConditionCategoryInForm({
  data,
  entityId,
  onChange,
  resources,
}: CciConditionOpProps) {
  const categoryInData = data as CciConditionCategoryIn;
  const {
    _category_set_ref_list: categorySetRefListValue,
  } = categoryInData ?? {};

  return (
    <ConfigVisualEditorResourceRefControl
      label="Category Set"
      name="category_set"
      multiple
      resources={resources}
      resourceType={EntityConfigResourceType.SERVICE_CATEGORIES}
      entityId={entityId}
      onChange={(event) => {
        onChange({
          ...categoryInData ?? {},
          _category_set_ref_list: Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => ({
              key: option.value,
              type: EntityConfigResourceType.SERVICE_CATEGORIES,
            })),
        });
      }}
      value={categorySetRefListValue?.map(({ key }) => key) ?? []}
    />
  );
}

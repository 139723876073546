import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { NCLoadingIndicator } from '@daupler/nexus-components';
import { EntityConfigEditorContext, useProvideEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { logger } from '../utils/logger';
import { useAuthentication } from '../hooks/useAuthentication';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { EntityConfig } from '../types/EntityConfig';
import { EntityConfigUtilityCategoriesResponse } from '../types/EntityConfigApi';
import { EntityDetail } from '../types/Entity';

export function ProvideEntityConfigEditor({
  children,
}: React.PropsWithChildren) {
  const { authToken } = useAuthentication();
  const { id } = useParams();
  const dauplerApi = useRef(new DauplerApi({
    baseUrl: config.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));

  const [entityConfig, setEntityConfig] = useState<EntityConfig | null>(null);
  const [
    utilityCategories,
    setUtilityCategories,
  ] = useState<EntityConfigUtilityCategoriesResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      if (!authToken) { return; }
      if (!id) { return; }
      try {
        setIsLoading(true);
        const [
          utilitiesResponse,
        ] = await Promise.all([
          dauplerApi.current.getUtilityCategories(authToken),
        ]);
        setUtilityCategories(utilitiesResponse);
        setEntityConfig({
          modules: [],
          params: [],
          resources: [],
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (isLoading) { return; }
    if (entityConfig) { return; }
    getData();
  }, [authToken, entityConfig, id, isLoading]);

  const [entity, setEntity] = useState<EntityDetail | null>(null);
  useEffect(() => {
    const getEntities = async () => {
      if (!authToken) { return; }
      if (!id) { return; }
      setIsLoading(true);
      try {
        const [
          entityResponse,
        ] = await Promise.all([
          dauplerApi.current.getEntity(authToken, id),
          dauplerApi.current.getTimezones(authToken),
          dauplerApi.current.getEntities(authToken),
        ]);
        setEntity(entityResponse);
      } catch (err) {
        logger.error((err as Error).message, err);
      } finally {
        setIsLoading(false);
      }
    };
    if (isLoading) { return; }
    if (entity) { return; }
    getEntities();
  }, [authToken, entity, id, isLoading]);

  const store = useProvideEntityConfigEditor({
    dauplerApi: dauplerApi.current,
    entity,
  });
  const hasSetConfig = useRef(false);
  useEffect(() => {
    if (!entityConfig) { return; }
    if (hasSetConfig.current) { return; }
    store.setUtilityCategories(utilityCategories);
    hasSetConfig.current = true;
  }, [entityConfig, id, store, utilityCategories]);

  if (isLoading) {
    return (<NCLoadingIndicator blockUi label="Loading Confg..." />);
  }

  return (
    <EntityConfigEditorContext.Provider value={store}>
      {children}
    </EntityConfigEditorContext.Provider>
  );
}

import { useState } from 'react';
import { NCLoadingIndicator } from '@daupler/nexus-components';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ConfigVisualEditorResourceModal } from '../components/ConfigVisualEditorResourceModal';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigResource } from '../types/EntityConfig';
import { getResourceReferencesByType } from '../utils/resource-tools';
import { useAuthentication } from '../hooks/useAuthentication';

export function EntitiesEditResourceRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    modules,
    params,
    resources,
    entity,
    utilityCategories,
    wipData,
  } = useEntityConfigEditor();

  const { authToken } = useAuthentication();
  const { documentId, resourceId } = useParams();
  const [resourceType, key] = resourceId?.split('|') ?? [];
  const handleSubmit = async (resource: EntityConfigResource) => {
    if (!documentId || !authToken) { return; }
    resources.update(key, resource);
  };

  const resource = (resources.data ?? [])
    .find(({ id, state: { type } }) => id === key && resourceType === type);
  const copyResource = () => {
    if (!resourceId || !entity) { return; }
    searchParams.append('resource-type', resource?.state.type ?? '');
    navigate(
      `/config/entities/${entity.id}/documents/${documentId}/add-resource?${searchParams.toString()}`,
      {
        state: {
          resourceData: {
            id: undefined,
            state: {
              ...resource?.state ?? {},
              key: undefined,
            },
          },
        },
      },
    );
  };

  const handleExit = () => {
    if (!entity) { return; }
    if (location.state?.isRefLink || location.state?.wasAppLink) {
      navigate(-1);
    } else {
      navigate(`/config/entities/${entity.id}?${searchParams.toString()}`);
    }
  };

  const handleProgressSave = (values: unknown) => {
    if (!resourceId) { return; }
    wipData.save(resourceId, values);
  };
  const [isAbandoningChanges, setIsAbandoningChanges] = useState(false);
  const handleAbandonChanges = () => {
    if (!resourceId) { return; }
    setIsAbandoningChanges(true);
    wipData.clear(resourceId);
    setTimeout(() => setIsAbandoningChanges(false), 125);
  };

  if (!entity || !resource) { return null; }
  if (isAbandoningChanges) { return <NCLoadingIndicator blockUi label="Abandoning changes..." />; }
  return (
    <ConfigVisualEditorResourceModal
      key={resourceId}
      copyResource={copyResource}
      entityId={entity?.id ?? ''}
      onAbandonChanges={handleAbandonChanges}
      onBack={handleExit}
      onClose={handleExit}
      onSaveProgress={handleProgressSave}
      onSubmit={handleSubmit}
      params={params.data ?? []}
      resource={resource}
      resources={resources.data ?? []}
      utilityCategories={utilityCategories}
      wipResource={wipData.get()?.[resourceId ?? ''] as any || null}
      resourceReferences={getResourceReferencesByType(
        resource.state,
        resources.data ?? [],
        modules.data ?? [],
      )}
    />
  );
}

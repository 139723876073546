export type EntityConfigScenario = {
  name: string;
  v2_labels: [string, string][];
  dispatch_rule_type: 'dispatch_default' | 'emergency_only_dispatch' | 'no_dispatch' | 'traffic_obstruction_dispatch' | 'dead_animal_dispatch';
};

export type EntityConfigUtilityCategoriesResponse = Record<string, EntityConfigScenario[]>;

export type PostEntityConfigDocumentPayload = {
  data: {
    type: 'configuration-documents',
    attributes: {
      yaml: string;
    };
    relationships?: {
      applications?: {
        data: { type: string; id: string; }[];
      };
    };
  };
};

export type PatchEntityConfigDocumentPayload = {
  data: {
    type: 'configuration-documents',
    id: string;
    attributes: {
      yaml: string;
    };
    relationships?: {
      applications?: {
        data: { type: string; id: string; }[];
      };
    };
  };
};

export type EntityConfigDocumentResource = {
  type: 'configuration-documents',
  id: string;
  attributes: {
    yaml: string;
    timestamps: {
      created: string;
      updated: string;
    };
  };
  relationships: {
    applications: {
      data: { type: string; id: string; }[];
    };
  };
};

export type EntityConfigDocumentResponse = {
  data: EntityConfigDocumentResource;
};

export type EntityConfigDocumentsResponse = {
  data: EntityConfigDocumentResource[];
};

export type EntityConfigApplicationResource = {
  relationships: {
    configuration_document: { data: { type: string; id: string; } };
  },
  attributes: {
    timestamps: {
      created: string;
    },
    message: string;
  },
  type: 'configuration-applications';
  id: string;
};

export type EntityConfigApplicationResponse = {
  data: EntityConfigApplicationResource;
};

export type EntityConfigApplicationsResponse = {
  data: EntityConfigApplicationResource[];
};

export enum EntityConfigModuleActionType {
  ENABLE = 'enable',
  UPDATE = 'update',
  DISABLE = 'disable',
}
export enum EntityConfigResourceActionType {
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
}

export type EntityConfigResourceAction = {
  action_type: EntityConfigResourceActionType;
  resource_id: string;
  planned_hash: string;
  key: string;
  planned_resource: {
    workgroup_id: null;
    display_name: string;
    data: {};
    key: string;
    workgroup_internal_name: null;
    type: string;
  } | null;
  resource_type: string;
};

export type EntityConfigModuleAction = {
  key: string;
  planned_module: null;
  module_type: string;
  action_type: EntityConfigModuleActionType;
};

export type EntityConfigPlanError = {
  code: string;
  message: string;
  modules: { module_type: string; key: string }[];
  parameters: { type: string; key: string }[];
  resources: { type: string; key: string }[];
};

export type EntityConfigPlanAiValidation = {
  recommended_fix: string;
  severity: string;
  why_problematic: string;
  error_or_issue: string;
  location: string;
  recommended_changes: string;
  description: string;
};

export type EntityConfigPlanResponse = {
  meta: {
    plan: {
      resource_actions: EntityConfigResourceAction[];
      module_actions: EntityConfigModuleAction[];
      errors: EntityConfigPlanError[];
    };
    hash: string;
    ai_validation_response: {
      results: {
        errors_found: EntityConfigPlanAiValidation[];
      };
      request_id: string;
    }
  };
};

export type EntityConfigApplyResponse = {
  meta: {
    success: boolean;
  };
};

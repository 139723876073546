import {
  FormField,
  NCInputCheckbox,
  NCInputText,
  useForm,
} from '@daupler/nexus-components';
import { ModuleFormProps } from './module-form-types';
import {
  EntityConfigModuleEngage,
  EntityConfigParamRef,
  EntityConfigParamType,
} from '../../types/EntityConfig';
import { useSyncModuleForm } from '../../hooks/useSyncModuleForm';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function EngageForm({
  module,
  moduleType,
  onChange,
  onValidate,
  params,
}: ModuleFormProps) {
  const {
    data: {
      _extent_ref: extentRefValue,
      _custom_display_name_ref: customDisplayNameRefValue,
      _logo_ref: logoRefValue,
      enabled: enabledValue,
    },
  } = module as EntityConfigModuleEngage ?? { data: {} };
  const extentRef: FormField<EntityConfigParamRef | undefined> = {
    invalidMessage: '',
    name: 'extentRef',
    validate: () => true,
    validMessage: '',
    value: extentRefValue ?? undefined,
  };
  const customDisplayNameRef: FormField<EntityConfigParamRef | undefined> = {
    invalidMessage: '',
    name: 'customDisplayNameRef',
    validate: () => true,
    validMessage: '',
    value: customDisplayNameRefValue,
  };
  const logoRef: FormField<EntityConfigParamRef | undefined> = {
    invalidMessage: '',
    name: 'logoRef',
    validate: () => true,
    validMessage: '',
    value: logoRefValue,
  };
  const enabled: FormField<boolean> = {
    invalidMessage: '',
    name: 'enabled',
    validate: () => true,
    validMessage: '',
    value: enabledValue ?? false,
  };

  const {
    formState,
    isFormValid,
    onChange: onFormChange,
  } = useForm({
    extentRef,
    customDisplayNameRef,
    logoRef,
    enabled,
  });
  useSyncModuleForm({
    isFormValid,
    module: {
      data: {
        _logo_ref: formState.logoRef.value,
        _custom_display_name_ref: formState.customDisplayNameRef.value,
        _extent_ref: formState.extentRef.value,
        enabled: formState.enabled.value,
      },
    },
    onChange,
    onValidate,
  });

  return (
    <>
      <NCInputText
        name="module-type"
        label="Module Type"
        value={moduleType}
        disabled
      />
      <NCInputCheckbox
        label="Enabled?"
        className="nc-l-mt_200_mobile"
        name={formState.enabled.name}
        checked={formState.enabled.value}
        onChange={() => onFormChange(formState.enabled.name, !formState.enabled.value)}
      />
      <ConfigVisualEditorParamControl
        className="nc-l-mt_200_mobile"
        label="Extent"
        name={formState.extentRef.name}
        paramType={EntityConfigParamType.EXTENT}
        params={params}
        onChange={(event) => {
          onFormChange(
            formState.extentRef.name,
            {
              key: event.target.value,
              type: EntityConfigParamType.EXTENT,
            },
          );
        }}
        value={formState.extentRef.value?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        className="nc-l-mt_200_mobile"
        label="Custom Display Name"
        name={formState.customDisplayNameRef.name}
        paramType={EntityConfigParamType.STRING}
        params={params}
        onChange={(event) => {
          onFormChange(
            formState.customDisplayNameRef.name,
            {
              key: event.target.value,
              type: EntityConfigParamType.STRING,
            },
          );
        }}
        value={formState.customDisplayNameRef.value?.key ?? ''}
      />
      <ConfigVisualEditorParamControl
        className="nc-l-mt_200_mobile"
        label="Logo"
        name={formState.logoRef.name}
        paramType={EntityConfigParamType.IMAGE}
        params={params}
        onChange={(event) => {
          onFormChange(
            formState.logoRef.name,
            {
              key: event.target.value,
              type: EntityConfigParamType.IMAGE,
            },
          );
        }}
        value={formState.logoRef.value?.key ?? ''}
      />
    </>
  );
}

export type Entity = {
  name: string;
  id: string;
  metadata?: {
    type?: EntityType;
    purpose?: EntityPurpose;
    configurationMethod?: EntityConfigurationMethod;
    rootEntityId?: string;
  };
};

export enum EntityConfigurationMethod {
  CONFIG_API = 'CONFIG_API',
  LEGACY_IMPLEMENTATION = 'LEGACY_IMPLEMENTATION',
  LEGACY_MANUAL = 'LEGACY_MANUAL',
  RESTORATION_BETA = 'RESTORATION_BETA',
  UNKNOWN = 'UNKNOWN',
}
export enum EntityType {
  CONNECTION = 'CONNECTION',
  FRANCHISE = 'FRANCHISE',
  ROOT = 'ROOT',
  UNKNOWN = 'UNKNOWN',
  WORKGROUP = 'WORKGROUP',
}

export type EntityDetail = {
  name: string;
  shortName: string;
  id: string;
  timezone: string;
  parentEntities: {
    id: string;
    name: string;
  }[];
};

export type CreateEntityPayload = {
  name: string;
  shortName: string;
  timezone: string;
  purpose: EntityPurpose;
};

export enum EntityPurpose {
  OFFICIAL = 'OFFICIAL',
  PROPOSAL = 'PROPOSAL',
  SANDBOX = 'SANDBOX',
}

export type Extent = {
  lat_min: number;
  lat_max: number;
  lng_min: number;
  lng_max: number;
};

export type EntityConfigRef = {
  type: unknown;
  key: string;
};
export type EntityConfigParamRef = {
  type: EntityConfigParamType;
  key: string;
};

export type EntityConfigModuleDaytimeDispatch = EntityConfigModule<
EntityConfigModuleType.DAYTIME_DISPATCH,
{
  _extent_ref?: EntityConfigParamRef;
  _dispatch_versions_ref_list: EntityConfigRef[];
  _undispatched_versions_ref_list: EntityConfigRef[];
  enabled: boolean;
}>;

export type EntityConfigModuleEngage = EntityConfigModule<
EntityConfigModuleType.ENGAGE,
{
  _custom_display_name_ref?: EntityConfigParamRef;
  _extent_ref?: EntityConfigParamRef;
  _logo_ref?: EntityConfigParamRef;
  enabled: boolean;
}>;

export enum NotifyModuleStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  DEMO = 'DEMO',
  CTA = 'CTA',
}
export type EntityConfigModuleNotify = EntityConfigModule<
EntityConfigModuleType.NOTIFY,
{
  _service_area_ref?: EntityConfigRef;
  status: NotifyModuleStatus;
}>;

export type EntityConfigAnyModule =
| EntityConfigModuleDaytimeDispatch
| EntityConfigModuleEngage
| EntityConfigModuleNotify;

export enum EntityConfigModuleType {
  DAYTIME_DISPATCH = 'DAYTIME_DISPATCH',
  ENGAGE = 'ENGAGE',
  NOTIFY = 'NOTIFY',
}
export type EntityConfigModule<
T = EntityConfigModuleType,
D = Record<string, unknown>> = {
  module_type: T;
  data: D;
  metadata?: Record<string, unknown>;
};

export enum EntityConfigResourceType {
  CCI_CONDITIONS = 'cci_conditions',
  CCI_DISPATCH_RULES = 'cci_dispatch_rules',
  CCI_SCRIPTS = 'cci_scripts',
  DISPATCH_ACTIONS = 'dispatch_actions',
  DISPATCH_CONDITIONS = 'dispatch_conditions',
  DISPATCH_PLAYBOOKS = 'dispatch_playbooks',
  DISPATCH_POLICIES = 'dispatch_policies',
  ESCALATION_TREES = 'escalation_trees',
  FACILITY_POLICIES = 'facility_policies',
  INCIDENT_EMAILS = 'incident_emails',
  INCIDENT_EMAIL_PROCESSORS = 'incident_email_processors',
  INCIDENT_EMAIL_ROUTERS = 'incident_email_routers',
  INCIDENT_ROLES = 'incident_roles',
  INCIDENT_TRANSFERS = 'incident_transfers',
  INCIDENT_VERSIONS = 'incident_versions',
  SCHEDULES = 'schedules',
  SERVICE_AREAS = 'service_areas',
  SERVICE_CATEGORIES = 'service_categories',
  SERVICE_LISTS = 'service_lists',
  SHIFTS = 'shifts',
  WORKGROUP_ROLES = 'workgroup_roles',
  WORKGROUPS = 'workgroups',
}

export type EntityConfigResource<
  T = EntityConfigResourceType,
  D = Record<string, unknown>,
> = {
  type: T;
  key: string;
  display_name: string;
  _workgroup_ref?: EntityConfigRef;
  workgroup_id?: string;
  workgroup_internal_name?: string;
  data: D;
  metadata?: Record<string, unknown>;
};

export enum CciConditionOperation {
  AND = 'and',
  CATEGORY_IN = 'category_in',
  NOT = 'not',
  OR = 'or',
  POLICY_IN = 'policy_in',
  TIME_IN_PARTITION_STRING = 'time_in_partition_string',
  HAS_REMOTE_RECONNECT_METER = 'has_remote_reconnect_meter',
  HAS_TURN_ON_AMOUNT = 'has_turn_on_amount',
  REPORTED_BY_911 = 'reported_by_911',
}

export type CciConditionAnd = {
  _conditions_ref_list: EntityConfigRef[];
  op: CciConditionOperation.AND;
  conditions: CciConditionData;
};

export type CciConditionCategoryIn = {
  _category_set_ref_list: EntityConfigRef[];
  op: CciConditionOperation.CATEGORY_IN;
  category_set: string[];
};

export type CciConditionNot = {
  _conditions_ref_list: EntityConfigRef[];
  op: CciConditionOperation.NOT;
  condition: CciConditionData;
};

export type CciConditionOr = {
  _conditions_ref_list: EntityConfigRef[];
  op: CciConditionOperation.OR;
  condition: CciConditionData;
};

export type CciConditionPolicyIn = {
  op: CciConditionOperation.POLICY_IN;
  _policy_set_ref_list: EntityConfigRef[];
};

export type CciConditionTimeInPartitionString = {
  op: CciConditionOperation.TIME_IN_PARTITION_STRING;
  _partition_string_ref: EntityConfigParamRef;
  _tzname_ref: EntityConfigParamRef;
};

export type CciConditionHasRemoteReconnectMeter = {
  op: CciConditionOperation.HAS_REMOTE_RECONNECT_METER;
};

export type CciConditionHasTurnOnAmount = {
  op: CciConditionOperation.HAS_TURN_ON_AMOUNT;
};

export type CciConditionReportedBy911 = {
  op: CciConditionOperation.REPORTED_BY_911;
};

export type CciConditionData = CciConditionAnd
| CciConditionCategoryIn
| CciConditionNot
| CciConditionOr
| CciConditionPolicyIn
| CciConditionTimeInPartitionString
| CciConditionHasRemoteReconnectMeter
| CciConditionHasTurnOnAmount
| CciConditionReportedBy911;

export type EntityConfigResourceCciCondition = EntityConfigResource<
EntityConfigResourceType.CCI_CONDITIONS,
CciConditionData
>;

export enum CciDispatchRuleType {
  ALWAYS_DISPATCH = 'always_dispatch',
  NEVER_DISPATCH = 'never_dispatch',
  REDIRECT = 'redirect',
  SIMPLE_PROMPT = 'simple_prompt',
  STANDARD_RULE = 'standard_rule',
}

export type CciAlwaysDispatchParams = {
  _message_ref: EntityConfigParamRef;
  make_message_visible: boolean;
};
export type CciAlwaysDispatch = {
  type: CciDispatchRuleType.ALWAYS_DISPATCH;
  params: CciAlwaysDispatchParams;
};

export type CciNeverDispatchParams = {
  _message_ref: EntityConfigParamRef;
  make_message_visible: boolean;
};
export type CciNeverDispatch = {
  type: CciDispatchRuleType.NEVER_DISPATCH;
  params: CciNeverDispatchParams;
};

export type CciRedirectParams = {
  _redirect_to_ref_list: EntityConfigParamRef[];
  make_message_visible: boolean;
};
export type CciRedirect = {
  type: CciDispatchRuleType.REDIRECT;
  params: CciRedirectParams;
};

export type CciSimplePromptParams = {
  _prompt_ref: EntityConfigParamRef;
  _yes_text_ref: EntityConfigParamRef;
  _no_text_ref: EntityConfigParamRef;
  _dispatched_message_ref: EntityConfigParamRef;
  _no_dispatch_message_ref: EntityConfigParamRef;
  make_response_visible?: boolean;
};
export type CciSimplePrompt = {
  type: CciDispatchRuleType.SIMPLE_PROMPT;
  params: CciSimplePromptParams;
};

export enum CciDispatchStandardRuleType {
  CRITICAL_SIGN_DISPATCH = 'critical_sign_dispatch',
  DEAD_ANIMAL_DISPATCH = 'dead_animal_dispatch',
  DISPATCH_DEFAULT = 'dispatch_default',
  EMERGENCY_ONLY_DISPATCH = 'emergency_only_dispatch',
  FACILITIES_DISPATCH = 'facilities_dispatch',
  NO_DISPATCH = 'no_dispatch',
  SHUTOFF_LIST_DISPATCH = 'shutoff_list_dispatch',
  SHUTOFF_LIST_HANDLED_NEXT_BUSINESS_DAY = 'shutoff_list_handled_next_business_day',
  TRAFFIC_OBSTRUCTION_DISPATCH = 'traffic_obstruction_dispatch',
}
export type CciStandardDispatchRuleParams = {
  rule_type: CciDispatchStandardRuleType;
};
export type CciStandardDispatchRule = {
  type: CciDispatchRuleType.STANDARD_RULE;
  params: CciStandardDispatchRuleParams;
};

export type EntityConfigResourceCciDispatchRuleParams =
CciAlwaysDispatchParams
| CciNeverDispatchParams
| CciRedirectParams
| CciSimplePromptParams
| CciStandardDispatchRuleParams;

export type EntityConfigResourceCciDispatchRule = EntityConfigResource<
EntityConfigResourceType.CCI_DISPATCH_RULES,
CciAlwaysDispatch
| CciNeverDispatch
| CciRedirect
| CciSimplePrompt
| CciStandardDispatchRule
>;

export enum LocalityMode {
  CONFIRM = 'CONFIRM',
  COLLECT_WITHOUT_CONFIRMATION = 'COLLECT_WITHOUT_CONFIRMATION',
}

export enum CciFacilitiesItMode {
  USE_FACILITY_POLICY = 'use_facility_policy',
  PREFER_IT_POLICY = 'prefer_it_policy',
  REQUIRE_IT_POLICY = 'require_it_policy',
  IGNORE_FACILITY_MATCH = 'ignore_facility_match',
}

export enum CciCollectElectricMeterMode {
  DISABLED = 'DISABLED',
  WHEN_RELEVANT = 'WHEN_RELEVANT',
  FORCED = 'FORCED',
}

export type CciScriptFacilitiesConfig = {
  _workgroup_internal_names_ref_list?: EntityConfigRef[];
  _custom_non_facilities_categories_ref_list?: EntityConfigRef[];
  include_default_non_facility_scenarios?: boolean;
  it_mode?: CciFacilitiesItMode;
};

export type CciScriptSpecialNumber = {
  _display_name_ref?: EntityConfigParamRef;
  _phone_numbers_ref_list: EntityConfigParamRef[];
  _category_ref?: EntityConfigRef;
  _custom_dispatch_rule_ref?: EntityConfigRef;
};

export type CciScriptServiceListRule = {
  _workgroup_internal_name_ref?: EntityConfigRef;
  _service_list_ref?: EntityConfigRef;
  _condition_ref?: EntityConfigRef;
  _dispatch_policy_ref?: EntityConfigRef;
  _dispatch_rule_ref?: EntityConfigRef;
  _incident_version_ref?: EntityConfigRef;
  magic_string?: string;
};

export type EntityConfigResourceCciScript = EntityConfigResource<
EntityConfigResourceType.CCI_SCRIPTS,
{
  _service_areas_ref_list: EntityConfigRef[];
  options: {
    _entity_name_ref: EntityConfigParamRef;
    _service_description_ref: EntityConfigParamRef;
    _primary_phone_number_ref: EntityConfigParamRef;
    _timezone_ref: EntityConfigParamRef;
    _locality_ref: EntityConfigParamRef;
    _region_code_ref: EntityConfigParamRef;
    _country_code_ref: EntityConfigParamRef;
    _map_extent_ref: EntityConfigParamRef;
    _pronunciation_guide_ref: EntityConfigParamRef;
    _business_hours_ref_list: EntityConfigParamRef[];
    _custom_greeting_ref: EntityConfigParamRef;
    _custom_qa_message_ref: EntityConfigParamRef;
    _common_area_codes_ref_list: EntityConfigParamRef[];
    engage_enabled?: boolean;
    require_name?: boolean;
    require_phone?: boolean;
    require_location?: boolean;
    locality_mode?: LocalityMode;
    categories_config?: {
      _categories_ref_list: EntityConfigRef[];
      _fallback_no_dispatch_rule_ref: EntityConfigRef;
      _fallback_dispatch_rule_ref: EntityConfigRef;
      category_activation_threshold?: number;
      policy_activation_threshold?: number;
    };
    facilities_config: CciScriptFacilitiesConfig;
    special_numbers: CciScriptSpecialNumber[];
    override_conf: {
      service_list_rules: CciScriptServiceListRule[];
    };
    duplicate_cutoff_minutes: number;
    collect_electric_meter_mode?: CciCollectElectricMeterMode;
    should_confirm_multispeak_service_location_on_blind_meter_match?: boolean;
  };
}>;

export enum DispatchActionType {
  CLOSE = 'close',
  DISTRIBUTE_BY_SERVICE_AREA = 'distribute_by_service_area',
  ESCALATE = 'escalate',
  MULTI_ACK_TRANSITIONAL = 'multi_ack_transitional',
  RUN_POLICY = 'run_policy',
}

export type EntityConfigResourceDispatchActionDispatchActionCloseParams = {
  complete: boolean;
  archive: boolean;
};
export type EntityConfigResourceDispatchActionDispatchActionClose = {
  type: DispatchActionType.CLOSE;
  params: EntityConfigResourceDispatchActionDispatchActionCloseParams;
};

export type EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParamsRoute = {
  _dispatch_policy_ref?: EntityConfigRef;
  _service_area_ref?: EntityConfigRef;
  _workgroup_ref?: EntityConfigRef;
  display_name?: string;
  match_pattern?: string;
  is_fallback?: boolean;
};
export type EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParams = {
  routes: EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParamsRoute[];
  service_area_options: Record<string, any>;
};
export type EntityConfigResourceDispatchActionDispatchActionDistributeByServiceArea = {
  type: DispatchActionType.DISTRIBUTE_BY_SERVICE_AREA;
  params: EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParams;
};

export type EntityConfigResourceDispatchActionDispatchActionEscalateParams = {
  _tree_id_ref: EntityConfigRef;
};
export type EntityConfigResourceDispatchActionDispatchActionEscalate = {
  type: DispatchActionType.ESCALATE;
  params: EntityConfigResourceDispatchActionDispatchActionEscalateParams;
};

export enum MultiAckSupervisorMode {
  ESCALATE = 'ESCALATE',
  REQUIRE = 'REQUIRE',
}

export type EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams = {
  _escalation_tree_id_ref: EntityConfigRef;
  team_display_name: string;
  max_nb_responders_required?: number;
  supervisor_mode: MultiAckSupervisorMode;
};
export type EntityConfigResourceDispatchActionDispatchActionMultiAckTransitional = {
  type: DispatchActionType.MULTI_ACK_TRANSITIONAL;
  params: EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams;
};

export type EntityConfigResourceDispatchActionDispatchActionRunPolicyParams = {
  _policy_id_ref: EntityConfigRef;
};
export type EntityConfigResourceDispatchActionDispatchActionRunPolicy = {
  type: DispatchActionType.RUN_POLICY;
  params: EntityConfigResourceDispatchActionDispatchActionRunPolicyParams;
};

export type EntityConfigResourceDispatchActionParams =
EntityConfigResourceDispatchActionDispatchActionCloseParams
| EntityConfigResourceDispatchActionDispatchActionDistributeByServiceAreaParams
| EntityConfigResourceDispatchActionDispatchActionEscalateParams
| EntityConfigResourceDispatchActionDispatchActionMultiAckTransitionalParams
| EntityConfigResourceDispatchActionDispatchActionRunPolicyParams;

export type EntityConfigResourceDispatchAction = EntityConfigResource<
EntityConfigResourceType.DISPATCH_ACTIONS,
EntityConfigResourceDispatchActionDispatchActionClose
| EntityConfigResourceDispatchActionDispatchActionDistributeByServiceArea
| EntityConfigResourceDispatchActionDispatchActionEscalate
| EntityConfigResourceDispatchActionDispatchActionMultiAckTransitional
| EntityConfigResourceDispatchActionDispatchActionRunPolicy>;

export type DispatchConditionShiftCheck = {
  _schedule_ref?: EntityConfigRef;
  _shift_ref?: EntityConfigRef;
  options: Record<string, any>;
};

export type EntityConfigResourceDispatchCondition = EntityConfigResource<
EntityConfigResourceType.DISPATCH_CONDITIONS,
{
  shift_checks: DispatchConditionShiftCheck[];
}>;

export type EntityConfigResourceDispatchPlaybook = EntityConfigResource<
EntityConfigResourceType.DISPATCH_PLAYBOOKS,
{
  _actions_ref_list: EntityConfigRef[];
}>;

export type DispatchPolicyRule = {
  _condition_ref?: EntityConfigRef;
  _playbook_ref?: EntityConfigRef;
  stop_cascade?: boolean;
};

export type EntityConfigResourceDispatchPolicy = EntityConfigResource<
EntityConfigResourceType.DISPATCH_POLICIES,
{
  _default_playbook_ref: EntityConfigRef;
  rules?: DispatchPolicyRule[]
}>;
export type EntityConfigResourceEscalationTree = EntityConfigResource<
EntityConfigResourceType.ESCALATION_TREES,
{}
>;

export type EntityConfigResourceFacilityPolicy = EntityConfigResource<
EntityConfigResourceType.FACILITY_POLICIES,
{
  _incident_version_ref: EntityConfigRef;
  _dispatch_policy_ref: EntityConfigRef;
}>;

export type EntityConfigResourceIncidentEmail = EntityConfigResource<
EntityConfigResourceType.INCIDENT_EMAILS,
{
  _token_ref?: EntityConfigParamRef;
  _router_ref?: EntityConfigRef;
  _sender_pattern_ref?: EntityConfigParamRef;
}>;

export enum IncidentEmailProcessorType {
  OHIO_811_NEWTIN = 'OHIO_811_NEWTIN',
  SOUTH_CAROLINA_811 = 'SOUTH_CAROLINA_811',
  WASHINGTON_811_IRTH = 'WASHINGTON_811_IRTH',
  INDIANA_811 = 'INDIANA_811',
  TEXAS_811 = 'TEXAS_811',
}

export type IncidentEmailProcessorOhio811NewtinOptions = {
  emergency_etc_hours?: number;
  category_name_template?: string;
};
export type IncidentEmailProcessorOhio811Newtin = {
  processor_type: IncidentEmailProcessorType.OHIO_811_NEWTIN;
  options: IncidentEmailProcessorOhio811NewtinOptions;
};

export type IncidentEmailProcessorIndiana811Options = {
  _workgroup_ref?: EntityConfigRef;
  _tzname_ref?: EntityConfigParamRef;
  emergency_etc_hours?: number;
  emergency_priorities?: string[][];
  incident_version?: string;
  label_template?: string;
  target_entity_id?: string;
};
export type IncidentEmailProcessorIndiana811 = {
  processor_type: IncidentEmailProcessorType.INDIANA_811;
  options: IncidentEmailProcessorIndiana811Options;
};

export type IncidentEmailProcessorTexas811Options = {
  _workgroup_ref?: EntityConfigRef;
  _tzname_ref?: EntityConfigParamRef;
  emergency_etc_hours?: number;
  emergency_types?: string[][];
  incident_version?: string;
  label_template?: string;
  target_entity_id?: string;
};
export type IncidentEmailProcessorTexas811 = {
  processor_type: IncidentEmailProcessorType.INDIANA_811;
  options: IncidentEmailProcessorIndiana811Options;
};

export type IncidentEmailProcessorSouthCarolina811Options = {
  _workgroup_ref?: EntityConfigRef;
  _tzname_ref?: EntityConfigParamRef;
  emergency_statuses?: string[];
  emergency_etc_hours?: number;
  incident_version?: string;
  category_name_template?: string;
  target_entity_id?: string;
};
export type IncidentEmailProcessorSouthCarolina811 = {
  processor_type: IncidentEmailProcessorType.SOUTH_CAROLINA_811;
  options: IncidentEmailProcessorSouthCarolina811Options;
};

export type IncidentEmailProcessorWashington811IrthParams = {
  _tzname_ref?: EntityConfigParamRef;
  emergency_etc_hours?: number;
  emergency_classifications?: string[][];
  incident_version?: string;
  label_template?: string;
};
export type IncidentEmailProcessorWashington811Irth = {
  processor_type: IncidentEmailProcessorType.WASHINGTON_811_IRTH;
  params: IncidentEmailProcessorWashington811IrthParams;
};

export type EntityConfigResourceIncidentEmailProcessorOptions =
IncidentEmailProcessorWashington811IrthParams
| IncidentEmailProcessorSouthCarolina811Options
| IncidentEmailProcessorIndiana811Options
| IncidentEmailProcessorOhio811NewtinOptions
| IncidentEmailProcessorTexas811Options;

export type EntityConfigResourceIncidentEmailProcessor = EntityConfigResource<
EntityConfigResourceType.INCIDENT_EMAIL_PROCESSORS,
IncidentEmailProcessorOhio811Newtin
| IncidentEmailProcessorSouthCarolina811
| IncidentEmailProcessorWashington811Irth
| IncidentEmailProcessorIndiana811
| IncidentEmailProcessorTexas811
>;

export type EntityConfigResourceIncidentEmailRouterRoute = {
  _dispatch_policy_ref?: EntityConfigRef;
  _processor_ref?: EntityConfigRef;
  stop_cascade: boolean;
};
export type EntityConfigResourceIncidentEmailRouter = EntityConfigResource<
EntityConfigResourceType.INCIDENT_EMAIL_ROUTERS,
{
  routes: EntityConfigResourceIncidentEmailRouterRoute[];
}>;

export type EntityConfigResourceIncidentRole = EntityConfigResource<
EntityConfigResourceType.INCIDENT_ROLES,
{
  can_view: boolean;
  can_change: boolean;
  can_call: boolean;
  can_manage: boolean;
}>;

export type EntityConfigResourceIncidentTransfer = EntityConfigResource<
EntityConfigResourceType.INCIDENT_TRANSFERS,
{
  _dispatch_policy_ref: EntityConfigRef;
  _category_ref: EntityConfigRef;
}>;

export enum EntityConfigIncidentTransferMode {
  SHARED = 'shared',
}

export type CallSelectedUsersTeamFormationOption = {
  _roles_ref_list?: EntityConfigRef[];
  _target_role_ref?: EntityConfigRef;
};
export type CallSelectedRolesTeamFormationOption = {
  _roles_ref_list?: EntityConfigRef[];
};
export type CallScheduledUsersTeamFormationOption = {
  _target_role_ref?: EntityConfigRef;
};
export type EntityConfigResourceIncidentVersion = EntityConfigResource<
EntityConfigResourceType.INCIDENT_VERSIONS,
{
  transfer_mode: EntityConfigIncidentTransferMode;
  team_formation_options: {
    call_selected_users?: CallSelectedUsersTeamFormationOption;
    call_selected_roles?: CallSelectedRolesTeamFormationOption;
    call_scheduled_users?: CallScheduledUsersTeamFormationOption;
  };
}>;

export type ScheduleRule = {
  _assigned_shift_ref?: EntityConfigRef;
  display_name: string;
  dtstart: string;
  rrule: string;
  duration: {
    months?: number;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  }
};

export type EntityConfigResourceSchedule = EntityConfigResource<
EntityConfigResourceType.SCHEDULES,
{
  _default_shift_ref?: EntityConfigRef;
  _holiday_shift_ref?: EntityConfigRef;
  rules?: ScheduleRule[];
}>;

export type EntityConfigResourceServiceArea = EntityConfigResource<
EntityConfigResourceType.SERVICE_AREAS,
{
  _features_ref: EntityConfigParamRef;
}>;

export enum AiTag {
  BURNED_VEHICLE_CLEANUP = 'BURNED_VEHICLE_CLEANUP',
  DUMPSTER_FIRE = 'DUMPSTER_FIRE',
  HIGHWAY_DETECTION = 'HIGHWAY_DETECTION',
}

export type EntityConfigResourceServiceCategory = EntityConfigResource<
EntityConfigResourceType.SERVICE_CATEGORIES,
{
  _dispatch_policy_ref: EntityConfigRef;
  _incident_version_ref: EntityConfigRef;
  _service_area_ref: EntityConfigRef;
  _service_lists_ref_list: EntityConfigRef[];
  _cci_dispatch_rule_ref: EntityConfigRef;
  is_policy_fallback_category?: boolean;
  scenarios: {
    v2: [string, string];
  }[];
  simple_ai_tags: AiTag[];
}>;

export enum EntityConfigServiceListServiceType {
  WATER = 'WATER',
  UTILITIES = 'UTILITIES',
}

export enum EntityConfigServiceListType {
  SHUT_OFFS = 'SHUT_OFFS',
  TURN_ONS = 'TURN_ONS',
}

export type EntityConfigResourceServiceList = EntityConfigResource<
EntityConfigResourceType.SERVICE_LISTS,
{
  service_type: EntityConfigServiceListServiceType;
  list_type: EntityConfigServiceListType;
}>;

export type EntityConfigResourceShift = EntityConfigResource<
EntityConfigResourceType.SHIFTS,
{
  is_after_hours: boolean;
}>;

export enum EntityConfigWorkgroupPermissionAction {
  ENTITIES_MANAGE = 'entities.manage_entity',
  ENTITIES_VIEW = 'entities.view_entity',
  INCIDENTS_ADD = 'incidents.add_incident',
  INCIDENTS_CHANGE = 'incidents.change_incident',
  INCIDENTS_DELETE = 'incidents.delete_incident',
  INCIDENTS_VIEW = 'incidents.view_incident',
  INCIDENTS_CALL = 'incidents.call_incident',
  INCIDENTS_MANAGE = 'incidents.manage_incident',
}

export type WorkgroupRolePermission = {
  _workgroup_ref?: EntityConfigRef;
  action: EntityConfigWorkgroupPermissionAction;
};

export type EntityConfigResourceWorkgroupRole = EntityConfigResource<
EntityConfigResourceType.WORKGROUP_ROLES,
{
  can_use_password: boolean;
  permissions: WorkgroupRolePermission[];
}>;

export type EntityConfigResourceWorkgroup = EntityConfigResource<
EntityConfigResourceType.WORKGROUPS,
{}>;

export type EntityConfigAnyResource = EntityConfigResourceCciCondition
| EntityConfigResourceCciDispatchRule
| EntityConfigResourceCciScript
| EntityConfigResourceDispatchAction
| EntityConfigResourceDispatchCondition
| EntityConfigResourceDispatchPlaybook
| EntityConfigResourceDispatchPolicy
| EntityConfigResourceEscalationTree
| EntityConfigResourceFacilityPolicy
| EntityConfigResourceIncidentEmail
| EntityConfigResourceIncidentEmailProcessor
| EntityConfigResourceIncidentEmailRouter
| EntityConfigResourceIncidentRole
| EntityConfigResourceIncidentTransfer
| EntityConfigResourceIncidentVersion
| EntityConfigResourceSchedule
| EntityConfigResourceServiceArea
| EntityConfigResourceServiceCategory
| EntityConfigResourceServiceList
| EntityConfigResourceShift
| EntityConfigResourceWorkgroup
| EntityConfigResourceWorkgroupRole;

export enum EntityConfigParamType {
  PHONE_NUMBER = 'phone_number',
  EXTENT = 'extent',
  STRING = 'string',
  JSON = 'json',
  IMAGE = 'image',
}
export type EntityConfigParamExtentValue = {
  data: unknown;
};
export type EntityConfigParamJsonValue = {
  data: unknown;
};
export type EntityConfigParamImageValue = {
  filename: string;
  base64_url: string;
  public_url: string;
};
export type EntityConfigParamPhoneNumberValue = {
  display_name: string;
  phone_number: string;
};
export type EntityConfigParamStringValue = {
  text: string;
};
export type EntityConfigParamAnyValue = EntityConfigParamExtentValue
& EntityConfigParamJsonValue
& EntityConfigParamImageValue
& EntityConfigParamPhoneNumberValue
& EntityConfigParamStringValue;
export type EntityConfigParam = {
  type: EntityConfigParamType;
  key: string;
  description: string;
  value: EntityConfigParamAnyValue;
  metadata?: Record<string, unknown>;
};

export type EntityConfig = {
  modules: EntityConfigModule[];
  resources: EntityConfigResource[];
  params: EntityConfigParam[];
};

import { FormField, NCSelect, useForm } from '@daupler/nexus-components';
import {
  IncidentEmailProcessorProps,
  ResourceFormProps,
} from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import {
  EntityConfigResourceIncidentEmailProcessor,
  EntityConfigResourceIncidentEmailProcessorOptions,
  IncidentEmailProcessorIndiana811Options,
  IncidentEmailProcessorOhio811NewtinOptions,
  IncidentEmailProcessorSouthCarolina811Options,
  IncidentEmailProcessorTexas811Options,
  IncidentEmailProcessorType,
  IncidentEmailProcessorWashington811IrthParams,
} from '../../types/EntityConfig';
import { IncidentEmailProcessorOhio811Newtin } from './IncidentEmailProcessorOhio811Newtin';
import { IncidentEmailProcessorSouthCarolina811 } from './IncidentEmailProcessorSouthCarolina811';
import { IncidentEmailProcessorWashington811Irth } from './IncidentEmailProcessorWashington811Irth';
import { IncidentEmailProcessorIndiana811 } from './IncidentEmailProcessorIndiana811';
import { IncidentEmailProcessorTexas811 } from './IncidentEmailProcessorTexas811';

const IncidentEmailProcessorParamsMap: Record<
IncidentEmailProcessorType,
(props: IncidentEmailProcessorProps) => React.JSX.Element
> = {
  [IncidentEmailProcessorType.OHIO_811_NEWTIN]: IncidentEmailProcessorOhio811Newtin,
  [IncidentEmailProcessorType.SOUTH_CAROLINA_811]: IncidentEmailProcessorSouthCarolina811,
  [IncidentEmailProcessorType.WASHINGTON_811_IRTH]: IncidentEmailProcessorWashington811Irth,
  [IncidentEmailProcessorType.INDIANA_811]: IncidentEmailProcessorIndiana811,
  [IncidentEmailProcessorType.TEXAS_811]: IncidentEmailProcessorTexas811,
};

export function IncidentEmailProcessorsForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  params,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: {
      processor_type: processorTypeValue,
    },
  } = resourceData as EntityConfigResourceIncidentEmailProcessor ?? { data: {} };
  let paramsValue: EntityConfigResourceIncidentEmailProcessorOptions = {};
  if ('options' in (resourceData?.data ?? {})) {
    paramsValue = resourceData?.data?.options as EntityConfigResourceIncidentEmailProcessorOptions;
  }
  if ('params' in (resourceData?.data ?? {})) {
    paramsValue = resourceData?.data?.params as EntityConfigResourceIncidentEmailProcessorOptions;
  }
  const processorType: FormField<IncidentEmailProcessorType | ''> = {
    name: 'processorType',
    value: processorTypeValue ?? '',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const processorParams: FormField<EntityConfigResourceIncidentEmailProcessorOptions | null> = {
    name: 'processorParams',
    value: paramsValue,
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    processorType,
    processorParams,
  });

  const getDefaultValuesForProcessorParams = (
    type: IncidentEmailProcessorType,
  ): EntityConfigResourceIncidentEmailProcessorOptions => {
    if (type === IncidentEmailProcessorType.OHIO_811_NEWTIN) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorOhio811NewtinOptions;
      return {
        emergency_etc_hours: options.emergency_etc_hours,
        category_name_template: options.category_name_template,
      } as IncidentEmailProcessorOhio811NewtinOptions;
    }
    if (type === IncidentEmailProcessorType.SOUTH_CAROLINA_811) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorSouthCarolina811Options;
      const {
        _workgroup_ref: workgroupRef,
        _tzname_ref: tzNameRef,
      } = options;
      return {
        _workgroup_ref: workgroupRef,
        _tzname_ref: tzNameRef,
        emergency_statuses: options.emergency_statuses,
        emergency_etc_hours: options.emergency_etc_hours,
        incident_version: options.incident_version,
        category_name_template: options.category_name_template,
        target_entity_id: options.target_entity_id,
      } as IncidentEmailProcessorSouthCarolina811Options;
    }
    if (type === IncidentEmailProcessorType.WASHINGTON_811_IRTH) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorWashington811IrthParams;
      const {
        _tzname_ref: tzNameRef,
      } = options;
      return {
        _tzname_ref: tzNameRef,
        emergency_etc_hours: options.emergency_etc_hours,
        emergency_classifications: options.emergency_classifications,
        incident_version: options.incident_version,
        label_template: options.label_template,
      } as IncidentEmailProcessorWashington811IrthParams;
    }
    if (type === IncidentEmailProcessorType.INDIANA_811) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorIndiana811Options ?? {};
      const {
        _tzname_ref: tzNameRef,
      } = options;
      return {
        _tzname_ref: tzNameRef,
        emergency_etc_hours: options.emergency_etc_hours ?? '4',
        emergency_priorities: options.emergency_priorities,
        incident_version: options.incident_version ?? 'utility_locate_v1',
        label_template: options.label_template ?? 'Utility Locate',
        target_entity_id: options.target_entity_id,
      } as IncidentEmailProcessorIndiana811Options;
    }
    if (type === IncidentEmailProcessorType.TEXAS_811) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorTexas811Options ?? {};
      const {
        _tzname_ref: tzNameRef,
      } = options;
      return {
        _tzname_ref: tzNameRef,
        emergency_etc_hours: options.emergency_etc_hours ?? '4',
        emergency_types: options.emergency_types,
        incident_version: options.incident_version ?? 'utility_locate_v1',
        label_template: options.label_template ?? 'Utility Locate',
        target_entity_id: options.target_entity_id,
      } as IncidentEmailProcessorTexas811Options;
    }
    return {};
  };

  const getValueForProcessorParams = () => {
    const type = formState.processorType.value;
    if (type === IncidentEmailProcessorType.OHIO_811_NEWTIN) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorOhio811NewtinOptions;
      return { options };
    }
    if (type === IncidentEmailProcessorType.SOUTH_CAROLINA_811) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorSouthCarolina811Options;
      return { options };
    }
    if (type === IncidentEmailProcessorType.WASHINGTON_811_IRTH) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorWashington811IrthParams;
      return { params: options };
    }
    if (type === IncidentEmailProcessorType.INDIANA_811) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorIndiana811Options ?? {};
      return { options };
    }
    if (type === IncidentEmailProcessorType.TEXAS_811) {
      const options = formState.processorParams
        .value as IncidentEmailProcessorTexas811Options ?? {};
      return { options };
    }
    return {};
  };

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        processor_type: formState.processorType.value,
        ...getValueForProcessorParams(),
      },
    },
  });

  const ParamsComponent = IncidentEmailProcessorParamsMap[
    formState.processorType.value as IncidentEmailProcessorType] ?? null;

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <NCSelect
        label="Type"
        className="nc-l-mt_200_mobile"
        name={formState.processorType.name}
        options={[
          { label: 'Choose one...', value: '' },
          ...Object.values(IncidentEmailProcessorType)
            .map((value) => ({
              label: value,
              value,
            })),
        ]}
        onChange={(event) => {
          onFormChange(formState.processorType.name, event.target.value);
          onFormChange(
            formState.processorParams.name,
            getDefaultValuesForProcessorParams(event.target.value as IncidentEmailProcessorType),
          );
        }}
        value={formState.processorType.value}
      />

      {formState.processorType.value && ParamsComponent ? (
        <div className="nc-l-mt_200_mobile">
          <ParamsComponent
            onChange={(value) => {
              onFormChange(
                formState.processorParams.name,
                value,
              );
            }}
            params={params}
            resources={resources}
            workgroups={workgroups}
            value={formState.processorParams.value}
          />
        </div>
      ) : null}
    </>
  );
}

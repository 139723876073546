import {
  FormField,
  useForm,
} from '@daupler/nexus-components';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields, getResourceRefFromId } from '../../utils/resource-tools';
import { EntityConfigResourceDispatchPlaybook, EntityConfigResourceType } from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function DispatchPlaybookForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const {
    data: dataValue,
  } = resourceData as EntityConfigResourceDispatchPlaybook ?? { data: {} };
  const {
    _actions_ref_list: actionsRefListValue,
  } = dataValue ?? {};

  const actionIds: FormField<string[]> = {
    name: 'actionIds',
    invalidMessage: '',
    validate: () => true,
    validMessage: '',
    value: (actionsRefListValue ?? []).map(({ key }) => key) ?? [],
  };
  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    actionIds,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value ?? undefined,
      data: {
        _actions_ref_list: formState.actionIds.value
          .map((actionId) => getResourceRefFromId(
            actionId,
            EntityConfigResourceType.DISPATCH_ACTIONS,
            resources,
          )),
      },
    },
  });

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <ConfigVisualEditorResourceRefControl
        label="Actions"
        multiple
        name={formState.actionIds.name}
        className="nc-l-mt_200_mobile"
        onChange={(event) => onFormChange(
          formState.actionIds.name,
          Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => option.value),
        )}
        value={formState.actionIds.value}
        resources={resources}
        resourceType={EntityConfigResourceType.DISPATCH_ACTIONS}
        entityId={entityId}
      />
    </>
  );
}

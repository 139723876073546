import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { parse } from 'yaml';
import {
  NCToast,
} from '@daupler/nexus-components';
import { logger } from '../utils/logger';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditor } from '../components/ConfigVisualEditor';
import { EditorMode } from '../types/ConfigEditor';
import { useToast } from '../hooks/useToasts';
import { useAuthentication } from '../hooks/useAuthentication';

export function EntitiesIdDocumentsIdRoute() {
  const { addToast } = useToast();

  const [searchParams] = useSearchParams();
  const [isTrayOpen, setIsTrayOpen] = useState(searchParams.get('objectsMenu') === 'true');
  useEffect(() => {
    if (searchParams.get('objectsMenu') === 'true' && !isTrayOpen) {
      setIsTrayOpen(true);
    } else if (searchParams.get('objectsMenu') === 'false' && isTrayOpen) {
      setIsTrayOpen(false);
    }
  }, [isTrayOpen, searchParams]);

  const {
    modules,
    resources,
    params,
    editor,
    resourceFilter,
    searchFilter,
    setSearchFilter,
    setEntityConfig,
    setResourceFilter,
    yaml: yamlConfig,
    wipData,
    saveEntityConfig,
  } = useEntityConfigEditor();

  const { documentId } = useParams();
  const { authToken } = useAuthentication();

  const handleYamlSave = async (yaml: string) => {
    if (!documentId || !authToken) { return; }
    try {
      setEntityConfig(parse(yaml));
      setTimeout(() => editor.setEditorState({
        hasManualEdits: false,
        mode: EditorMode.VISUAL,
        shouldNagForUnsaved: false,
      }));
      await saveEntityConfig(
        authToken,
        documentId === 'new' ? null : documentId,
        { yaml },
      );
      wipData.clearAll();
      addToast({
        body: 'Saved config',
        id: 'yaml-foreground-save',
        subject: 'Configuration Saved',
        type: NCToast.style.SUCCESS,
      });
    } catch (err) {
      logger.error((err as Error).message);
      addToast({
        body: (err as Error).message,
        id: 'yaml-parse-error',
        subject: 'Failed to Parse YAML',
        type: NCToast.style.ERROR,
      });
    }
  };

  return (
    <ConfigVisualEditor
      editorMode={editor.mode}
      removeModule={modules.remove}
      removeResource={resources.remove}
      params={params.data ?? []}
      onYamlCancel={() => {
        editor.setMode(EditorMode.VISUAL);
      }}
      onYamlSave={handleYamlSave}
      yamlValue={yamlConfig.data}
      resourceFilter={resourceFilter}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      setResourceFilter={(resource) => {
        if (resource) {
          setResourceFilter([resource]);
        } else {
          setResourceFilter([]);
        }
      }}
      resources={resources.data ?? []}
      wipData={wipData.get() as any}
      modules={modules.data ?? []}
    />
  );
}

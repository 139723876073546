import {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import yaml from 'yaml';
import {
  EntityConfigDocumentResponse,
  EntityConfigPlanResponse,
  EntityConfigUtilityCategoriesResponse,
} from '../types/EntityConfigApi';
import {
  EntityConfig,
  EntityConfigModule,
  EntityConfigParam,
  EntityConfigParamRef,
  EntityConfigRef,
  EntityConfigResource,
  EntityConfigResourceType,
} from '../types/EntityConfig';
import { EntityDetail } from '../types/Entity';
import { EditorMode } from '../types/ConfigEditor';
import { sortKeys } from '../utils/config-tools';
import { DauplerApi } from '../lib/daupler-api';

export type EditorEntityModule = {
  state: EntityConfigModule;
  id: string;
};

export type EditorEntityParam = {
  state: EntityConfigParam;
  id: string;
};

export type EditorEntityResource = {
  state: EntityConfigResource;
  id: string;
};

export interface EditorEntityConfig extends Record<string, unknown> {
  modules: EditorEntityModule[];
  params: EditorEntityParam[];
  resources: EditorEntityResource[];
}

export type EditorStoredEntityConfig = {
  config: EntityConfig;
  updated: string;
};

type EditorState = {
  hasManualEdits: boolean;
  shouldNagForUnsaved: boolean;
  mode: EditorMode;
};

export type EntityConfigEditorStore = {
  modules: {
    data: EditorEntityModule[] | null;
    add: (module: EntityConfigModule) => void;
    update: (id: string, module: EntityConfigModule) => void;
    remove: (id: string) => void;
  };
  params: {
    data: EditorEntityParam[] | null;
    add: (param: EntityConfigParam) => void;
    update: (id: string, module: EntityConfigParam) => void;
    remove: (id: string) => void;
  };
  resources: {
    data: EditorEntityResource[] | null;
    add: (resource: EntityConfigResource) => void;
    update: (id: string, module: EntityConfigResource) => void;
    remove: (id: string, type: EntityConfigResourceType) => void;
  };
  wipData: {
    get: () => Record<string, unknown> | undefined;
    save: (typeKeyPair: string, values: unknown) => void;
    clear: (typeKeyPair: string) => void;
    clearAll: () => void;
  };
  yaml: {
    data: string;
    from: (config: Partial<EditorEntityConfig>) => string;
  };
  saveEntityConfig: (
    authToken: string,
    documentId: string | null,
    options?: {
      yaml?: string;
    },
  ) => Promise<EntityConfigDocumentResponse>;
  setEntityConfig: (config: EntityConfig) => void;
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  entity: EntityDetail | null;
  resourceFilter: (EntityConfigRef | EntityConfigParamRef)[];
  searchFilter: string;
  setResourceFilter: (filter: (EntityConfigRef | EntityConfigParamRef)[]) => void;
  setSearchFilter: (filter: string) => void;
  utilityCategories: EntityConfigUtilityCategoriesResponse | null,
  setUtilityCategories: (categories: EntityConfigUtilityCategoriesResponse | null) => void;
  plan: {
    response: EntityConfigPlanResponse | null;
    setPlanResponse: (response: EntityConfigPlanResponse) => void;
  };
  editor: {
    hasManualEdits: boolean;
    mode: EditorMode;
    setEditorState: (value: EditorState) => void;
    setHasManualEdits: (value: boolean) => void;
    setMode: (value: EditorMode) => void;
    setShouldNagForUnsaved: (value: boolean) => void;
    shouldNagForUnsaved: boolean;
  };
};
export const EntityConfigEditorContext = createContext<EntityConfigEditorStore>(
  {} as EntityConfigEditorStore,
);

export const useProvideEntityConfigEditor = (
  {
    dauplerApi,
    entity,
  }: {
    dauplerApi: DauplerApi,
    entity: EntityDetail | null;
  },
): EntityConfigEditorStore => {
  const [editorState, setEditorState] = useState<EditorState>({
    hasManualEdits: false,
    shouldNagForUnsaved: false,
    mode: EditorMode.VISUAL,
  });

  const entityConfigToEditorEntityConfig = (config: EntityConfig | null): EditorEntityConfig => {
    if (!config) {
      return {
        modules: [],
        params: [],
        resources: [],
      };
    }
    return {
      modules: config.modules.map((module) => ({ id: module.module_type, state: module })),
      params: config.params.map((param) => ({ id: param.key, state: param })),
      resources: config.resources.map((resource) => ({ id: resource.key, state: resource })),
    };
  };

  const [
    configState,
    setConfigState,
  ] = useState<EditorEntityConfig | null>(null);
  const configToYaml = useCallback((
    config: Partial<EditorEntityConfig> | null,
  ) => (config ? yaml.stringify({
    modules: config.modules?.map(({ state: module }) => module) ?? [],
    params: config.params?.map(({ state: param }) => param) ?? [],
    resources: config.resources?.map(({ state: resource }) => resource) ?? [],
  }, { schema: 'yaml-1.1' }) : null), []);

  const [isSaving, setIsSaving] = useState(false);
  const [
    utilityCategories,
    setUtilityCategories,
  ] = useState<EntityConfigUtilityCategoriesResponse | null>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const setEntityConfig = (config: EntityConfig) => {
    setConfigState(entityConfigToEditorEntityConfig(config));
  };

  const addObjectToState = (
    objectType: 'modules' | 'params' | 'resources',
    id: string,
    object: EntityConfigModule | EntityConfigParam | EntityConfigResource,
  ) => {
    const state = {
      modules: [],
      params: [],
      resources: [],
      ...configState ?? {},
      [objectType]: [
        ...(configState?.[objectType] ?? []),
        sortKeys({ id, state: object }),
      ],
    };
    setConfigState(state);
    return state;
  };
  const updateObjectInState = (
    objectType: 'modules' | 'params' | 'resources',
    id: string,
    object: EntityConfigModule | EntityConfigParam | EntityConfigResource,
  ) => {
    const state = {
      modules: [],
      params: [],
      resources: [],
      ...configState ?? {},
      [objectType]: (configState?.[objectType] ?? [])
        .map((objectInState) => {
          if (objectInState.id !== id) { return objectInState; }
          if ('type' in objectInState.state && 'type' in object) {
            if (objectInState.state.type !== object.type) {
              return objectInState;
            }
          }
          return sortKeys({ id, state: object });
        }),
    };
    setConfigState(state);
    return state;
  };
  const removeObjectFromState = (
    objectType: 'modules' | 'params' | 'resources',
    id: string,
    type?: string,
  ) => {
    const state = {
      modules: [],
      params: [],
      resources: [],
      ...configState ?? {},
      [objectType]: (configState?.[objectType] ?? [])
        .filter((objectInState) => {
          if (objectInState.id !== id) { return true; }
          if ('type' in objectInState.state && objectInState.state.type !== type) {
            return true;
          }
          return false;
        }),
    };
    setConfigState(state);
    return state;
  };

  const addModule = (module: EntityConfigModule) => addObjectToState('modules', module.module_type, module);
  const updateModule = (id: string, module: EntityConfigModule) => updateObjectInState(
    'modules',
    id,
    module,
  );
  const removeModule = (id: string) => removeObjectFromState('modules', id);

  const addParam = (param: EntityConfigParam) => addObjectToState('params', param.key, param);
  const updateParam = (id: string, param: EntityConfigParam) => updateObjectInState(
    'params',
    id,
    param,
  );
  const removeParam = (id: string) => removeObjectFromState('params', id);

  const addResource = (resource: EntityConfigResource) => addObjectToState('resources', resource.key, resource);
  const updateResource = (id: string, resource: EntityConfigResource) => updateObjectInState(
    'resources',
    resource.key,
    resource,
  );
  const removeResource = (id: string, type: EntityConfigResourceType) => removeObjectFromState(
    'resources',
    id,
    type,
  );

  const [
    resourceFilter,
    setResourceFilter,
  ] = useState<(EntityConfigRef | EntityConfigParamRef)[]>([]);

  const [
    planResponse,
    setPlanResponse,
  ] = useState<EntityConfigPlanResponse | null>(null);

  const saveEntityConfig = async (
    authToken: string,
    documentId: string | null,
    options?: {
      yaml?: string;
    },
  ): Promise<EntityConfigDocumentResponse> => {
    if (!entity) { throw new Error('Entity not loaded'); }

    if (documentId === null) {
      return dauplerApi.postEntityConfigDocument(
        authToken,
        DauplerApi.stripEntityIdPrefix(entity.id),
        {
          data: {
            attributes: {
              yaml: options?.yaml ?? (configToYaml(configState) ?? ''),
            },
            relationships: {},
            type: 'configuration-documents',
          },
        },
      );
    }
    return dauplerApi.patchEntityConfigDocument(
      authToken,
      DauplerApi.stripEntityIdPrefix(entity.id),
      documentId,
      {
        data: {
          attributes: {
            yaml: options?.yaml ?? (configToYaml(configState) ?? ''),
          },
          relationships: {},
          type: 'configuration-documents',
          id: documentId,
        },
      },
    );
  };

  const STORAGE_KEY = `dplr-icg-${entity?.id}-wip`;
  const [, setWipDataHasChanged] = useState('');
  const getWipData = () => {
    try {
      return JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '');
    } catch {
      return {};
    }
  };
  const saveWipData = (typeKeyPair: string, values: unknown) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...getWipData(), [typeKeyPair]: values }));
  };
  const clearWipData = (typeKeyPair: string) => {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({ ...getWipData(), [typeKeyPair]: undefined }),
    );
    setWipDataHasChanged(Math.random().toString());
  };

  return {
    modules: {
      data: configState?.modules ?? null,
      add: addModule,
      update: updateModule,
      remove: removeModule,
    },
    params: {
      data: configState?.params ?? null,
      add: addParam,
      update: updateParam,
      remove: removeParam,
    },
    resources: {
      data: configState?.resources ?? null,
      add: addResource,
      update: updateResource,
      remove: removeResource,
    },
    wipData: {
      get: getWipData,
      save: saveWipData,
      clear: clearWipData,
      clearAll: () => Object.keys(getWipData() ?? {}).forEach(clearWipData),
    },
    yaml: {
      data: configToYaml(configState) ?? '',
      from: (config: Partial<EditorEntityConfig> | null) => configToYaml(config) ?? '',
    },
    saveEntityConfig,
    setEntityConfig,
    isSaving,
    setIsSaving,
    entity,
    resourceFilter,
    setResourceFilter,
    searchFilter,
    setSearchFilter,
    setUtilityCategories,
    utilityCategories,
    plan: {
      response: planResponse,
      setPlanResponse,
    },
    editor: {
      hasManualEdits: editorState.hasManualEdits,
      mode: editorState.mode,
      setEditorState: (value: EditorState) => setEditorState(value),
      setHasManualEdits: (value) => setEditorState((state) => ({
        ...state,
        hasManualEdits: value,
      })),
      setMode: (mode: EditorMode) => {
        const wasChangingYaml = editorState.mode === EditorMode.YAML && mode === EditorMode.VISUAL;
        if (wasChangingYaml && editorState.hasManualEdits) {
          setEditorState((state) => ({
            ...state,
            shouldNagForUnsaved: true,
          }));
          return;
        }
        setEditorState((state) => ({
          ...state,
          mode,
        }));
      },
      setShouldNagForUnsaved: (value) => setEditorState((state) => ({
        ...state,
        shouldNagForUnsaved: value,
      })),
      shouldNagForUnsaved: editorState.shouldNagForUnsaved,
    },
  };
};

export const useEntityConfigEditor = () => useContext(EntityConfigEditorContext);

import { NCWell } from '@daupler/nexus-components';
import { EntityConfigPlanResponse } from '../types/EntityConfigApi';
import { ConfigVisualEditorPlanError } from './ConfigVisualEditorPlanError';
import { ConfigAiValidation } from './ConfigAiValidation';
import { ConfigAction } from './ConfigAction';

type ConfigVisualEditorPlanSummaryProps = {
  plan: EntityConfigPlanResponse | null;
};

export function ConfigVisualEditorPlanSummary({
  plan,
}: ConfigVisualEditorPlanSummaryProps) {
  if (!plan) {
    return (<p>No plan available.</p>);
  }

  return (
    <>
      {plan.meta.plan.errors.length ? (
        <NCWell color={NCWell.colors.ERROR} className="nc-l-mt_200_mobile">
          <h2 className="nc-t-h4_medium_mobile nc-t-error">
            <i className="fa-solid fa-warning" />
            {' '}
            Errors
          </h2>
          {plan.meta.plan.errors.map((configError) => (
            <ConfigVisualEditorPlanError
              key={JSON.stringify(configError)}
              planError={configError}
              className="nc-l-mt_200_mobile"
            />
          ))}
        </NCWell>
      ) : null}
      {plan.meta.ai_validation_response?.results?.errors_found?.length ? (
        <NCWell color={NCWell.colors.WARNING} className="nc-l-mt_200_mobile">
          <h2 className="nc-t-h4_medium_mobile nc-t-error">
            <i className="fa-solid fa-warning" />
            {' '}
            AI Validation
          </h2>
          {plan.meta.ai_validation_response?.results.errors_found?.map((aiValidation) => (
            <ConfigAiValidation
              validation={aiValidation}
              key={JSON.stringify(aiValidation)}
            />
          ))}
        </NCWell>
      ) : null}
      <section className="nc-l-mt_200_mobile">
        <h2 className="nc-t-h4_medium_mobile">Module Actions</h2>
        {!plan.meta.plan.module_actions.length ? (
          <p className="nc-t-sub_text_regular_mobile nc-t-grey_500">No module changes</p>
        ) : null}
        {plan.meta.plan.module_actions.map((moduleAction) => (
          <ConfigAction
            key={moduleAction.module_type}
            actionType={moduleAction.action_type}
            itemKey={moduleAction.module_type}
            details={JSON.stringify(moduleAction.planned_module, undefined, 2) ?? ''}
          />
        ))}
      </section>

      <section className="nc-l-mt_200_mobile">
        <h2 className="nc-t-h4_medium_mobile">Resource Actions</h2>
        {!plan.meta.plan.resource_actions.length ? (
          <p className="nc-t-sub_text_regular_mobile nc-t-grey_500">No resource changes</p>
        ) : null}
        {plan.meta.plan.resource_actions.map((resourceAction) => (
          <ConfigAction
            key={resourceAction.key}
            actionType={resourceAction.action_type}
            itemKey={`${resourceAction.key} <${resourceAction.resource_type}>`}
            details={JSON.stringify(resourceAction.planned_resource, undefined, 2) ?? ''}
          />
        ))}
      </section>
    </>
  );
}

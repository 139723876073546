import { useEffect, useRef, useState } from 'react';
import './ConfigVisualEditor.css';
import { useSearchParams } from 'react-router-dom';
import {
  debounce,
  NCButton,
  NCInputCheckbox,
  NCInputText,
  NCSelect,
} from '@daupler/nexus-components';
import { EditorMode } from '../types/ConfigEditor';
import {
  EntityConfigModuleType,
  EntityConfigParamRef,
  EntityConfigRef,
  EntityConfigResource,
  EntityConfigResourceType,
} from '../types/EntityConfig';
import { EditorEntityModule, EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorCode } from './ConfigVisualEditorCode';
import { ConfigVisualEditorSection } from './ConfigVisualEditorSection';
import { ConfigVisualEditorModule } from './ConfigVisualEditorModule';
import { ConfigVisualEditorResource } from './ConfigVisualEditorResource';
import { getIconForResourceType, getResourceReferences } from '../utils/resource-tools';
import { getIconForModuleType } from '../utils/module-tools';
import { sortKeys } from '../utils/config-tools';
import { getParamReferences } from '../utils/param-tools';

type ConfigVisualEditorProps = {
  editorMode: EditorMode;
  modules: EditorEntityModule[];
  params: EditorEntityParam[];
  onYamlCancel: () => void;
  onYamlSave: (yaml: string) => void;
  removeModule: (key: string) => void;
  removeResource: (key: string, type: EntityConfigResourceType) => void;
  resources: EditorEntityResource[];
  resourceFilter: (EntityConfigRef | EntityConfigParamRef)[];
  searchFilter: string;
  setSearchFilter: (value: string) => void;
  setResourceFilter: (filter: EntityConfigRef | EntityConfigParamRef | null) => void;
  wipData?: Record<string, {
    resourceData: Partial<EntityConfigResource> | null;
    type: EntityConfigResourceType;
  }>;
  yamlValue: string;
};

export function ConfigVisualEditor({
  editorMode,
  wipData,
  removeModule,
  removeResource,
  resources,
  modules,
  params,
  resourceFilter,
  searchFilter,
  setSearchFilter,
  setResourceFilter,
  onYamlCancel,
  onYamlSave,
  yamlValue,
}: ConfigVisualEditorProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const collapsedParam = (searchParams.get('collapsed')?.split(',') ?? [])
    .filter((v) => !!v);
  const [selectedWorkgroup, setSelectedWorkgroup] = useState(searchParams.get('workgroups') ?? '');

  const toggleCollapsed = (type?: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const val = collapsedParam;
    if (type) {
      if (!val.includes(type)) {
        newSearchParams.set('collapsed', [...val, type].join(','));
      }
      if (val.includes(type)) {
        newSearchParams.set('collapsed', val.filter((v) => v !== type).join(','));
      }
    } else if (val.length > 0) {
      newSearchParams.set('collapsed', '');
    } else {
      newSearchParams.set('collapsed', [
        ...Object.values(EntityConfigResourceType),
        'Modules',
      ].join(','));
    }
    setSearchParams(newSearchParams);
  };

  const [isHidingSaved, setIsHidingSaved] = useState(false);
  const typeFilter = (searchParams.get('type-filter') ?? [])?.length
    ? searchParams.get('type-filter')?.split(',')
    : [];
  const resourcesByType = Object.values(EntityConfigResourceType)
    .filter((type) => {
      if (!typeFilter?.length) { return true; }
      return typeFilter.some((key) => {
        const [objectType, resourceType] = key.split('|');
        return objectType === 'RESOURCES' && resourceType.toLowerCase() === type.toLowerCase();
      });
    })
    .sort((l, r) => {
      if (l > r) { return 1; }
      if (r > l) { return -1; }
      return 0;
    })
    .reduce((result, type) => ({
      ...result,
      [type]: resources
        .filter(({ state }) => {
          if (!isHidingSaved) { return true; }
          return !!wipData?.[`${state.type}|${state.key}`];
        })
        .filter(({ state }) => {
          if (!selectedWorkgroup) { return true; }
          const { _workgroup_ref: workgroupRef } = state;
          if (selectedWorkgroup === 'NONE') {
            return !workgroupRef?.key;
          }
          return workgroupRef?.key === selectedWorkgroup;
        })
        .filter(({ state: resource }) => resource.type === type)
        .filter((resource) => (searchFilter
          ? JSON.stringify(sortKeys(resource)).toLowerCase().includes(searchFilter.toLowerCase())
          : true))
        .filter((resource) => {
          if (!resourceFilter.length) { return true; }
          const isThisResource = resourceFilter.some((filter) => resource.state.key === filter.key
              && resource.state.type === filter.type);
          if (isThisResource) { return true; }
          return resourceFilter.some((filter) => {
            const filteredResource = resources.find(({ state }) => state.key === filter.key
              && filter.type === state.type);
            const filteredParam = params.find(({ state }) => state.key === filter.key
              && filter.type === state.type);
            if (filteredResource) {
              return getResourceReferences(filteredResource.state, [resource], []).length;
            }
            if (filteredParam) {
              return getParamReferences(filteredParam.state, [resource], []).length;
            }
            return false;
          });
        }),
    }), {} as Record<EntityConfigResourceType, EditorEntityResource[]>);

  const modulesToRender = modules
    .filter(({ state }) => {
      if (!isHidingSaved) { return true; }
      return !!wipData?.[`${state.module_type}`];
    })
    .filter(({ state: { module_type: type } }) => {
      if (!typeFilter?.length) { return true; }
      return typeFilter.some((key) => {
        const [objectType, resourceType] = key.split('|');
        return objectType === 'MODULES'
          && resourceType.toLowerCase() === type.toLowerCase();
      });
    })
    .filter((module) => (searchFilter
      ? JSON.stringify(module).toLowerCase().includes(searchFilter.toLowerCase())
      : true))
    .map(({ state, id }) => ({
      state,
      id: `${state.module_type}|${id}`,
    }));

  const onSearchChangeDebounced = useRef(
    debounce(setSearchFilter, 250),
  );

  const searchFilterRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!searchFilterRef.current) { return; }
    if (searchFilter === searchFilterRef.current.value) {
      return;
    }
    searchFilterRef.current.value = searchFilter;
  }, [searchFilter]);

  const resourcesToRender = Object.entries(resourcesByType);
  const resourceNumbers: Record<string, number> = resources
    .reduce((res, { id, state }, i) => ({ ...res, [`${state.type}|${id}`]: i }), {});

  const onSetFilter = (object: EntityConfigRef | EntityConfigParamRef) => {
    setResourceFilter(object);
  };

  const getAddLink = (path: string, param?: { key: string; value: string }) => {
    const newParams = new URLSearchParams(searchParams);
    if (param) {
      newParams.set(param.key, param.value);
    }
    return `${path}?${newParams.toString()}`;
  };

  if (editorMode === EditorMode.YAML) {
    return (
      <ConfigVisualEditorCode
        onYamlCancel={onYamlCancel}
        onYamlSave={onYamlSave}
        yamlValue={yamlValue}
      />
    );
  }

  return (
    <div className="config_visual_editor__workspace">
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-mb_200_mobile">
        <div className="nc-flex nc-flex--align_center nc-flex--gap_2">
          <NCInputText
            name="search"
            onChange={(event) => onSearchChangeDebounced.current(event.target.value)}
            labelHidden
            label="Search"
            tag={<i className="fa-light fa-search" />}
            refToInput={searchFilterRef}
            defaultValue={searchFilter}
          />
          <NCInputCheckbox
            label="Unsaved only"
            name="unsaved_only"
            checked={isHidingSaved}
            onChange={() => setIsHidingSaved((state) => !state)}
          />
        </div>
        <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
          <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
            <span className="nc-t-body_medium nc-t-grey_900">Workgroup</span>
            <NCSelect
              label="Workgroup"
              name="workgroup_filter"
              labelHidden
              options={[
                { label: 'All', value: '' },
                { label: 'No Workgroup', value: 'NONE' },
                ...resources
                  .filter(({ state }) => state.type === EntityConfigResourceType.WORKGROUPS)
                  .map((resource) => ({
                    label: resource.state.display_name,
                    value: resource.state.key,
                  })),
              ]}
              onChange={(event) => {
                setSelectedWorkgroup(event.target.value);
                const newParams = new URLSearchParams(searchParams);
                newParams.set('workgroups', event.target.value);
                setSearchParams(newParams);
              }}
              value={selectedWorkgroup}
            />
          </div>
          <NCButton
            color={NCButton.colors.GREY}
            appearance={NCButton.appearances.OUTLINE}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => toggleCollapsed()}
          >
            {collapsedParam.length ? (
              'Expand All'
            ) : (
              'Collapse All'
            )}
          </NCButton>
        </div>
      </div>
      <div className="nc-flex nc-flex--wrap nc-flex--align_center nc-flex--justify_between nc-l-mb_200_mobile">
        {resourceFilter.map((resource) => (
          <NCButton
            key={JSON.stringify(resources)}
            color={NCButton.colors.DARK}
            appearance={NCButton.appearances.INVERSE}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => setResourceFilter(null)}
          >
            {`${resource.key} <${resource.type}>`}
            {' '}
            <i className="fa-light fa-times" />
          </NCButton>
        ))}
      </div>

      {!modulesToRender.length
        && searchFilter.length
        && !resourcesToRender.every(([, resourcesForType]) => !!resourcesForType.length) ? (
          <span className="nc-t-body_light nc-t-grey_700">No modules or resources match search filters</span>
        ) : null}

      {modulesToRender.length ? (
        <ConfigVisualEditorSection<EditorEntityModule>
          title="Modules"
          icon=""
          isCollapsed={collapsedParam.includes('Modules')}
          toggleCollapsed={() => toggleCollapsed('Modules')}
          items={modulesToRender}
          addLink={{
            to: `add-module?${searchParams.toString()}`,
            state: { wasAppLink: true },
          }}
          renderItem={({ state: module, id }) => (
            <ConfigVisualEditorModule
              icon={getIconForModuleType(module.module_type as EntityConfigModuleType)}
              moduleType={module.module_type}
              data={module.data}
              editLink={`modules/${id}?${searchParams.toString()}`}
              onRemove={() => removeModule(id.split('|')[1])}
              hasUnsavedChanges={!!wipData?.[module.module_type]}
            />
          )}
        />
      ) : null}

      {resourcesToRender
        .map(([
          type,
          resourcesForType,
        ]) => ((searchFilter.length && resourcesForType.length)
          || !searchFilter.length
          ? (
            <div key={type} className="nc-l-mt_200_mobile">
              <ConfigVisualEditorSection<EditorEntityResource>
                isCollapsed={collapsedParam.includes(type)}
                icon={getIconForResourceType(type as EntityConfigResourceType)}
                items={resourcesForType
                  .map(({ state, id }) => ({
                    state,
                    id: `${state.type}|${id}`,
                  }))}
                addLink={{
                  to: getAddLink('add-resource', { key: 'resource-type', value: type }),
                  state: { wasAppLink: true },
                }}
                renderItem={({ state: resource, id }) => (
                  <ConfigVisualEditorResource
                    type={resource.type}
                    label={`resource ${resourceNumbers[id]}`}
                    hasChanges={!!wipData?.[`${resource.type}|${resource.key}`]}
                    icon={getIconForResourceType(resource.type)}
                    resourceKey={resource.key}
                    displayName={resource.display_name}
                    editLink={`resources/${id}?${searchParams.toString()}`}
                    onRemove={() => removeResource(id.split('|')[1], resource.type)}
                    referenceCount={getResourceReferences(resource, resources, modules).length}
                    onFilter={() => {
                      onSetFilter({ key: resource.key, type: resource.type });
                    }}
                  />
                )}
                title={type as EntityConfigResourceType}
                toggleCollapsed={() => toggleCollapsed(type)}
              />
            </div>
          ) : null))}
    </div>
  );
}

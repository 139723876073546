import { EntityConfigPlanAiValidation } from '../types/EntityConfigApi';

type ConfigAiValidationProps = {
  validation: EntityConfigPlanAiValidation;
};

export function ConfigAiValidation({
  validation,
}: ConfigAiValidationProps) {
  return (
    <div>
      {Object.entries(validation).map(([key, value]) => (
        <div key={JSON.stringify({ key, value })}>
          <span className="nc-t-body_medium">{`${key}: `}</span>
          <span className="nc-t-body_regular">{value}</span>
        </div>
      ))}
    </div>
  );
}

import {
  NCButton,
  NCModal,
} from '@daupler/nexus-components';
import { EntityConfigDocumentResource } from '../types/EntityConfigApi';
import { ConfigVisualEditorLoadDataView, EnhancedConfigDocumentResource } from './ConfigVisualEditorLoadDataView';

type ConfigVisualEditorLoadDataModalProps = {
  data?: EnhancedConfigDocumentResource[];
  isLoading?: boolean;
  isOpen: boolean;
  localData?: EnhancedConfigDocumentResource[];
  onClose: () => void;
  onCloseFinished: () => void;
  onDeleteLocal: (config: EntityConfigDocumentResource) => void;
  onDownload: (config: EntityConfigDocumentResource) => void;
  onLoad: (config: EntityConfigDocumentResource) => void;
};

export function ConfigVisualEditorLoadDataModal({
  data,
  isLoading,
  isOpen,
  localData,
  onClose,
  onCloseFinished,
  onDeleteLocal,
  onDownload,
  onLoad,
}: ConfigVisualEditorLoadDataModalProps) {
  return (
    <NCModal
      closeLabel="Close"
      isOpen={isOpen}
      onClose={onClose}
      title="Manage Entity Data"
      onCloseFinished={onCloseFinished}
      footerChildren={(
        <div className="nc-flex nc-flex--align_center nc-flex--justify_between nc-l-pa_utilities_225_mobile">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onClose}
          >
            Cancel
          </NCButton>
        </div>
      )}
    >
      <ConfigVisualEditorLoadDataView
        onDeleteLocal={onDeleteLocal}
        onDownload={onDownload}
        onLoad={onLoad}
        localData={localData}
        data={data}
        isLoading={isLoading}
      />
    </NCModal>
  );
}

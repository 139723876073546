import {
  FormField,
  NCButton,
  useForm,
} from '@daupler/nexus-components';
import { useState } from 'react';
import {
  EntityConfigModule,
  EntityConfigModuleType,
  EntityConfigResourceType,
} from '../types/EntityConfig';
import { logger } from '../utils/logger';
import { EditorEntityModule, EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { ConfigVisualEditorModuleForms } from './ConfigVisualEditorModuleForms';
import './ConfigVisualEditorModuleModal.css';
import { ConfigVisualEditorObjectPicker } from './ConfigVisualEditorObjectPicker';
import { getIconForModuleType } from '../utils/module-tools';

enum View {
  CHOOSE_MODULE = 'CHOOSE_MODULE',
  CONFIGURE_MODULE = 'CONFIGURE_MODULE',
}

type ConfigVisualEditorModuleModalProps = {
  entityId: string;
  onBack: () => void;
  onClose: () => void;
  onSaveProgress?: (values: unknown) => void;
  onSubmit: (resource: EntityConfigModule) => Promise<void>;
  module?: EditorEntityModule;
  params: EditorEntityParam[];
  resources: EditorEntityResource[];
  wipData?: EntityConfigModule;
};

export function ConfigVisualEditorModuleModal({
  entityId,
  onBack,
  onClose,
  onSaveProgress,
  onSubmit,
  module,
  params,
  resources,
  wipData,
}: ConfigVisualEditorModuleModalProps) {
  const [view, setView] = useState(module?.state.module_type
    ? View.CONFIGURE_MODULE
    : View.CHOOSE_MODULE);

  const type: FormField<EntityConfigModuleType> = {
    invalidMessage: '',
    name: 'type',
    validate: (value) => !!value,
    validMessage: '',
    value: wipData?.module_type ?? module?.state.module_type ?? '' as EntityConfigModuleType,
    isValid: !!wipData || !!module?.state.module_type,
  };
  const moduleData: FormField<Omit<EntityConfigModule, 'module_type'> | null> = {
    invalidMessage: '',
    name: 'moduleData',
    validate: (_, state) => state.moduleData.isValid ?? false,
    validMessage: '',
    isValid: !!wipData,
    value: wipData ?? module?.state as EntityConfigModule ?? null,
  };

  const {
    formState,
    isFormValid,
    onChange,
    validateField,
    validateForm,
  } = useForm({ moduleData, type });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    validateField(formState.type.name);
    if (view === View.CHOOSE_MODULE && formState.type.isValid) {
      setView(View.CONFIGURE_MODULE);
      return;
    }

    validateForm();
    if (!isFormValid()) { return; }

    try {
      await onSubmit({
        module_type: formState.type.value,
        ...(formState.moduleData.value ?? {}),
      } as EntityConfigModule);
      onClose();
    } catch (err) {
      logger.error((err as Error).message, err);
    }
  };

  const handleCancel = () => {
    if (module?.state.module_type || view === View.CHOOSE_MODULE) {
      onClose();
    } else {
      setView(View.CHOOSE_MODULE);
    }
  };

  const canSubmit = () => {
    if (view === View.CHOOSE_MODULE) {
      return formState.type.isValid;
    }
    return isFormValid();
  };

  return (
    <form onSubmit={handleSubmit} className="config_visual_editor_module_modal">
      <div className="config_visual_editor_module_modal__header">
        <NCButton
          appearance={NCButton.appearances.SOLID}
          color={NCButton.colors.LIGHT}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={onBack}
        >
          <i className="fa-light fa-arrow-left" />
        </NCButton>
        <h2 className="nc-t-h5_medium_mobile">
          Module Editor
        </h2>
      </div>
      <div className="config_visual_editor_module_modal__body">
        {view === View.CHOOSE_MODULE ? (
          <ConfigVisualEditorObjectPicker
            items={Object.values(EntityConfigModuleType).map((moduleType) => ({
              id: moduleType,
              name: moduleType,
              icon: (<i className={`fa-solid ${getIconForModuleType(moduleType)}`} />),
            }))}
            onChange={(id) => onChange(formState.type.name, id as EntityConfigModuleType)}
            title="Choose Module"
            value={formState.type.value}
          />
        ) : null}
        {view === View.CONFIGURE_MODULE ? (
          <div>
            <ConfigVisualEditorModuleForms
              entityId={entityId}
              onChange={(value) => {
                onChange(
                  formState.moduleData.name,
                  value,
                );
                onSaveProgress?.({
                  module_type: formState.type.value,
                  ...(formState.moduleData.value ?? {}),
                });
              }}
              onValidate={(result) => {
                validateField(formState.moduleData.name, result);
              }}
              moduleType={formState.type.value}
              params={params}
              module={formState.moduleData.value}
              resources={resources}
              workgroups={resources.filter(
                (workgroup) => workgroup
                  .state.type === EntityConfigResourceType.ESCALATION_TREES,
              )}
            />
          </div>
        ) : null}
      </div>

      <div className="config_visual_editor_module_modal__footer nc-flex nc-flex--align_center nc-flex--justify_between nc-l-pa_utilities_225_mobile">
        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          color={NCButton.colors.GREY}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={handleCancel}
        >
          {view === View.CHOOSE_MODULE || module?.state.module_type ? 'Cancel' : 'Back'}
        </NCButton>
        <NCButton
          appearance={NCButton.appearances.SOLID}
          color={NCButton.colors.PRIMARY}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          type="submit"
          disabled={!canSubmit()}
        >
          {view === View.CHOOSE_MODULE ? 'Next' : 'Save'}
        </NCButton>
      </div>
    </form>
  );
}

import { NCDauplerLogo, NCErrorView, NCLinkButton } from '@daupler/nexus-components';
import { useLocation } from 'react-router';

export function ErrorUnauthorized() {
  const { pathname, search } = useLocation();

  return (
    <NCErrorView
      title="Unauthorized"
      message={(
        <>
          <p>Sorry, you are not authorized to view the requested resource.</p>
          <NCLinkButton
            className="nc-l-mt_200_mobile"
            color={NCLinkButton.colors.PRIMARY}
            width={[[NCLinkButton.breakpoints.MOBILE, NCLinkButton.widths.HUG]]}
            to={`/login?returl=${encodeURIComponent(pathname)}?${encodeURIComponent(search)}`}
          >
            Log In
          </NCLinkButton>
        </>
      )}
      logo={<NCDauplerLogo />}
    />
  );
}

import clsx from 'clsx';
import { NCButton, NCLabel, NCLinkButton } from '@daupler/nexus-components';
import './ConfigVisualEditorSection.css';

type ConfigVisualEditorSectionProps<T extends { id: string }> = {
  icon?: string;
  isCollapsed: boolean;
  toggleCollapsed: () => void;
  items: T[];
  addLink: { to: string; state?: any };
  renderItem: (item: T) => React.JSX.Element;
  title: string;
};

export function ConfigVisualEditorSection<T extends { id: string; }>({
  icon,
  isCollapsed,
  toggleCollapsed,
  items,
  addLink,
  renderItem,
  title,
}: ConfigVisualEditorSectionProps<T>) {
  return (
    <div className="config_visual_editor_section">
      <div className="config_visual_editor_section__header">
        <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
          <h2 className="nc-t-h5_medium_mobile nc-t-grey_800">
            {icon ? (
              <>
                <i className={clsx('fa-solid', 'fa-fw', icon)} />
                {' '}
              </>
            ) : null}
            {title}
          </h2>
          <NCLinkButton
            color={NCLinkButton.colors.GREY}
            size={[[NCLinkButton.breakpoints.MOBILE, NCLinkButton.sizes.SM]]}
            width={[[NCLinkButton.breakpoints.MOBILE, NCLinkButton.widths.HUG]]}
            to={addLink.to}
          >
            <i className="fa-light fa-plus" />
          </NCLinkButton>
        </div>
        <div>
          <NCLabel label={items.length} color={NCLabel.colors.GREY} />
          <NCButton
            color={NCButton.colors.LIGHT}
            className="nc-l-ml_100_mobile"
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => {
              toggleCollapsed();
            }}
          >
            {isCollapsed ? (
              <i className="fa-solid fa-chevron-right" />
            ) : (
              <i className="fa-solid fa-chevron-down" />
            )}
          </NCButton>
        </div>
      </div>

      <div
        className={clsx('config_visual_editor_section__drawer', {
          'config_visual_editor_section__drawer--closed': isCollapsed,
        })}
      >

        {items.length === 0 ? <p className="nc-t-body_light_mobile nc-t-grey_600 nc-l-mt_utilities_50_mobile">No items.</p> : null}

        {items
          .map((item) => (
            <div key={item.id} className="config_visual_editor_section__node">
              {renderItem(item)}
            </div>
          ))}
      </div>
    </div>
  );
}

import download from 'downloadjs';
import { useState } from 'react';
import { DauplerApi } from '../lib/daupler-api';
import { useFetchData } from './useFetchData';
import {
  EntityConfigApplicationResponse,
  EntityConfigApplicationsResponse,
  EntityConfigDocumentResource,
  EntityConfigDocumentsResponse,
} from '../types/EntityConfigApi';

export const useLoadConfigurationDocuments = ({
  authToken,
  dauplerApi,
  entityId,
}: {
  authToken: string;
  dauplerApi: DauplerApi;
  entityId: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    configurationDocuments,
    setConfigurationDocuments,
  ] = useState<null | EntityConfigDocumentsResponse>(null);
  const [
    configurationApplications,
    setConfigurationApplications,
  ] = useState<null | EntityConfigApplicationsResponse>(null);
  const [
    latestApplication,
    setLatestApplication,
  ] = useState<null | EntityConfigApplicationResponse>(null);
  useFetchData(async () => {
    if (!authToken) { return; }
    if (configurationDocuments) { return; }
    try {
      setIsLoading(true);
      const [
        configDocumentsResponse,
        applicationsResponse,
        latestApplicationResponse,
      ] = await Promise.all([
        dauplerApi.getEntityConfigDocuments(
          authToken,
          DauplerApi.stripEntityIdPrefix(entityId),
        ),
        dauplerApi.getEntityConfigApplications(
          authToken,
          DauplerApi.stripEntityIdPrefix(entityId),
        ),
        dauplerApi.getEntityConfigApplication(
          authToken,
          DauplerApi.stripEntityIdPrefix(entityId),
          'latest',
        ),
      ]);
      setConfigurationDocuments(configDocumentsResponse);
      setConfigurationApplications(applicationsResponse);
      setLatestApplication(latestApplicationResponse);
    } finally {
      setIsLoading(false);
    }
  });

  const handleDownload = (configDocument: EntityConfigDocumentResource) => {
    if (!entityId) { return; }
    download(configDocument.attributes.yaml, `${entityId}-${configDocument.attributes.timestamps.updated}.yml`);
  };

  return {
    isLoading,
    onDownload: handleDownload,
    data: configurationDocuments?.data.map((configDoc) => ({
      ...configDoc,
      meta: {
        applied: configurationApplications?.data.find(
          (
            application,
          ) => application.relationships.configuration_document.data.id === configDoc.id,
        )?.attributes.timestamps.created ?? '',
        latest: latestApplication?.data?.relationships
          .configuration_document.data.id === configDoc.id,
      },
    })),
  };
};

import { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  NCButton,
  NCInputCheckbox,
  NCInputText,
  NCLoadingIndicator,
  NCTable,
  NCTableBody,
  NCTableHeader,
  NCTableHeaderCol,
  NCTableRow,
  NCTableRowCol,
  NCToast,
} from '@daupler/nexus-components';
import { v4 } from 'uuid';
import { useAuthentication } from '../hooks/useAuthentication';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { Entity, EntityConfigurationMethod, EntityPurpose } from '../types/Entity';
import './EntitiesRoute.css';
import { Navbar } from '../components/Navbar';
import { useToast } from '../hooks/useToasts';

export function EntitiesRoute() {
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useAuthentication();
  const dauplerApi = useRef(new DauplerApi({
    baseUrl: config.dauplerApiBaseUrl,
    fetch: fetch.bind(window),
  }));
  const [entities, setEntities] = useState<Entity[] | null>(null);
  useEffect(() => {
    const getEntities = async () => {
      if (!authToken) { return; }
      setIsLoading(true);
      try {
        const response = await dauplerApi.current.getEntities(authToken);
        if (response.length) {
          setEntities(response);
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (entities) { return; }
    getEntities();
  }, [authToken, entities]);

  const [entityIdToDelete, setEntityIdToDelete] = useState('');
  const { addToast } = useToast();
  const deleteEntity = async (entityId: string) => {
    if (!authToken) { return; }
    try {
      setEntityIdToDelete(entityId);
      await dauplerApi.current.deleteEntity(authToken, entityId);
    } catch (err) {
      addToast({
        body: (err as Error).message,
        id: v4(),
        subject: 'Failed to Delete Entity',
        type: NCToast.style.ERROR,
      });
    } finally {
      setEntities((state) => state?.filter((entity) => entity.id !== entityId) ?? []);
      setEntityIdToDelete('');
    }
  };

  const getLinkForEntity = (entity: Entity) => `/config/entities/${entity.id}`;

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFilter, setSearchFilter] = useState(searchParams.get('entity') ?? '');
  useEffect(() => {
    if (searchFilter === searchParams.get('entity')) { return; }
    searchParams.set('entity', searchFilter);
    setSearchParams(searchParams);
  }, [searchFilter, searchParams, setSearchParams]);

  const [isOnlyConfigurable, setIsOnlyConfigurable] = useState(false);

  const entityTableData = (entities ?? [])
    .filter((entity) => {
      if (!isOnlyConfigurable) { return true; }
      return entity?.metadata?.configurationMethod === EntityConfigurationMethod.CONFIG_API;
    })
    .filter((entity) => entity.id.includes(searchFilter)
      || entity.name.toLowerCase().includes(searchFilter.toLowerCase()));

  return (
    <>
      <Navbar title="Entities" />
      <div className="nc-l-pa_300_mobile">
        <div className="nc-flex nc-flex--align_center nc-flex--justify_between">
          <div className="nc-flex nc-flex--align_center">
            <h1 className="nc-t-h1_medium_mobile nc-t-grey_900">Entities</h1>
            <Link to="/config/entities/add-entity" className="entities_route__add_entity">
              <i className="fa-solid fa-plus" />
              {' '}
              Add Entity
            </Link>
          </div>

          <div className="nc-flex nc-flex nc-flex--align_center nc-flex--gap_1">
            <NCInputCheckbox
              label="Only Configurable Entities"
              name="only_configurable"
              checked={isOnlyConfigurable}
              onChange={() => setIsOnlyConfigurable(!isOnlyConfigurable)}
            />
            <NCInputText
              disabled={!entities}
              name="search"
              label="Search"
              labelHidden
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              tag={<i className="fa-light fa-search" />}
            />
          </div>
        </div>
        {isLoading ? <NCLoadingIndicator blockUi label="Loading Entities" /> : null}
        {entityTableData && entityTableData.length ? (
          <NCTable className="nc-l-mt_200_mobile">
            <NCTableHeader>
              <NCTableHeaderCol>Name</NCTableHeaderCol>
              <NCTableHeaderCol>ID</NCTableHeaderCol>
              <NCTableHeaderCol>Config API?</NCTableHeaderCol>
              <NCTableHeaderCol>Purpose</NCTableHeaderCol>
              <NCTableHeaderCol />
            </NCTableHeader>
            <NCTableBody>
              {entityTableData.map((entity) => (
                <NCTableRow key={entity.id} className="entities_route__entity">
                  <NCTableRowCol>
                    <Link className="entities_route__entity__primary" to={getLinkForEntity(entity)}>
                      {entity.name}
                    </Link>
                  </NCTableRowCol>
                  <NCTableRowCol>
                    <Link className="entities_route__entity__detail" to={getLinkForEntity(entity)}>
                      {entity.id}
                    </Link>
                  </NCTableRowCol>
                  <NCTableRowCol className="nc-t-center">
                    <Link className="entities_route__entity__detail" to={getLinkForEntity(entity)}>
                      {entity.metadata?.configurationMethod === EntityConfigurationMethod.CONFIG_API ? <i className="fa-light fa-check nc-t-success" /> : null}
                    </Link>
                  </NCTableRowCol>
                  <NCTableRowCol className="nc-t-center">
                    <Link className="entities_route__entity__detail" to={getLinkForEntity(entity)}>
                      {entity.metadata?.purpose}
                    </Link>
                  </NCTableRowCol>
                  <NCTableRowCol className="nc-t-center">
                    <NCButton
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      color={NCButton.colors.GREY}
                      appearance={NCButton.appearances.OUTLINE}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                      onClick={() => deleteEntity(entity.id)}
                      disabled={(
                        (entity
                          .metadata?.configurationMethod !== EntityConfigurationMethod.CONFIG_API
                        && entity.metadata?.purpose !== EntityPurpose.PROPOSAL)
                        || !!entityIdToDelete
                      )}
                    >
                      {entityIdToDelete === entity.id ? (
                        <i className="fa-light fa-spinner" />
                      ) : (
                        <i className="fa-light fa-trash" />
                      )}
                    </NCButton>
                  </NCTableRowCol>
                </NCTableRow>
              ))}
            </NCTableBody>
          </NCTable>
        ) : null}
        {!isLoading && (!entities || !entityTableData.length) ? (
          <p className="nc-l-mt_200_mobile nc-t-body_regular_mobile nc-t-grey_600">No Entities to display.</p>
        ) : null}
      </div>
    </>
  );
}

import {
  NCButton,
  NCModal,
  NCTable,
  NCTableBody,
  NCTableHeader,
  NCTableHeaderCol,
  NCTableRow,
  NCTableRowCol,
} from '@daupler/nexus-components';
import './InputList.css';
import { useState } from 'react';
import clsx from 'clsx';
import { v4 } from 'uuid';

type InputListProps<T> = {
  label: string;
  className?: string;
  data: T[];
  onAdd: () => void;
  onClear: () => void;
  onRemove: (items: T[]) => void;
  placeholder?: string;
  renderItem: (item: T) => React.JSX.Element;
  secondaryActions?: {
    label: string;
    onClick: () => void;
  }[];
};

export function InputList<T>({
  children,
  className,
  label,
  data,
  onAdd,
  onClear,
  onRemove,
  placeholder,
  renderItem,
  secondaryActions,
}: React.PropsWithChildren<InputListProps<T>>) {
  const [isOpen, setIsOpen] = useState(false);

  const dataWithIds = data.map((item) => ({ item, id: v4() }));

  return (
    <>
      <p className={clsx('nc-t-body_medium_mobile', className)}>{label}</p>
      {data.length === 0 ? (
        <div className="input_list__list_container">
          <p className="nc-t-sub_text_light_mobile nc-t-grey_700">{placeholder ?? 'No items added yet!'}</p>
        </div>
      ) : (
        <NCTable>
          <NCTableHeader>
            <NCTableHeaderCol className="nc-l-pa_0_mobile"><span className="nc-a11y_hidden">Description</span></NCTableHeaderCol>
            <NCTableHeaderCol className="nc-l-pa_0_mobile"><span className="nc-a11y_hidden">Remove</span></NCTableHeaderCol>
          </NCTableHeader>
          <NCTableBody>
            {dataWithIds?.map(({ item, id }) => (
              <NCTableRow key={id}>
                <NCTableRowCol className="input_list__description_field">
                  {renderItem(item)}
                </NCTableRowCol>
                <NCTableRowCol className="nc-t-center">
                  <NCButton
                    appearance={NCButton.appearances.OUTLINE}
                    color={NCButton.colors.ERROR}
                    size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                    width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                    onClick={() => onRemove(data.filter((datum) => datum !== item))}
                  >
                    <i className="fa-solid fa-times" />
                  </NCButton>
                </NCTableRowCol>
              </NCTableRow>
            ))}
          </NCTableBody>
        </NCTable>
      )}
      <div className="nc-flex nc-flex--align_center nc-flex--gap_1">
        <NCButton
          className="nc-l-mt_100_mobile"
          appearance={NCButton.appearances.OUTLINE}
          color={NCButton.colors.PRIMARY}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={() => {
            setIsOpen(true);
          }}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
        >
          Add Item
        </NCButton>
        {secondaryActions?.map((secondaryAction) => (
          <NCButton
            key={secondaryAction.label}
            className="nc-l-mt_100_mobile"
            appearance={NCButton.appearances.LINK}
            color={NCButton.colors.PRIMARY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={secondaryAction.onClick}
            size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
          >
            {secondaryAction.label}
          </NCButton>
        ))}
      </div>

      <NCModal
        closeLabel="Close"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={label}
        onCloseFinished={onClear}
      >
        {children}
        <NCButton
          appearance={NCButton.appearances.OUTLINE}
          color={NCButton.colors.PRIMARY}
          onClick={onAdd}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          className="nc-l-mt_200_mobile"
        >
          Add Item
        </NCButton>
      </NCModal>
    </>
  );
}

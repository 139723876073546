import { useState } from 'react';
import { NCInputText } from '@daupler/nexus-components';
import {
  IncidentEmailProcessorProps,
} from './resource-form-types';
import { EntityConfigParamType, IncidentEmailProcessorTexas811Options } from '../../types/EntityConfig';
import { InputList } from '../InputList';
import { ConfigVisualEditorParamControl } from '../ConfigVisualEditorParamControl';

export function IncidentEmailProcessorTexas811({
  onChange,
  params,
  value,
}: IncidentEmailProcessorProps) {
  const {
    _tzname_ref: tzNameRef,
    emergency_types: emergencyTypes,
    emergency_etc_hours: emergencyEtcHours,
    incident_version: incidentVersion,
    label_template: labelTemplate,
    target_entity_id: targetEntityId,
  } = (value as IncidentEmailProcessorTexas811Options) ?? {};

  const [newEmergencyType, setNewEmergencyType] = useState<string[]>([]);

  return (
    <>
      <ConfigVisualEditorParamControl
        label="TZ Name"
        name="Texas_811-tzname"
        className="nc-l-mt_200_mobile"
        params={params}
        paramType={EntityConfigParamType.STRING}
        value={tzNameRef?.key ?? ''}
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorTexas811Options),
            _tzname_ref: { key: event.target.value, type: EntityConfigParamType.STRING },
          });
        }}
      />
      <InputList<string[]>
        data={(emergencyTypes ?? [])}
        label="Emergency Types"
        className="nc-l-mt_200_mobile"
        onAdd={() => {
          onChange({
            ...(value as IncidentEmailProcessorTexas811Options),
            emergency_types: [
              ...(value as IncidentEmailProcessorTexas811Options).emergency_types ?? [],
              newEmergencyType,
            ],
          });
          setNewEmergencyType([]);
        }}
        onClear={() => {
          setNewEmergencyType([]);
        }}
        onRemove={(data) => {
          onChange({
            ...(value as IncidentEmailProcessorTexas811Options),
            emergency_types: data,
          });
        }}
        renderItem={(emergencyType) => (
          <p>{emergencyType.join(',')}</p>
        )}
        placeholder="No Emergency Priorities here yet!"
      >
        <NCInputText
          name="Texas_811-new_emergency_type"
          label="Emergency Type"
          value={newEmergencyType.join(',')}
          onChange={(event) => setNewEmergencyType(event.target.value.split(','))}
          hint="Comma separated list of types (ex. Emergency,DigUp)"
        />
      </InputList>
      <NCInputText
        label="Emergency ETC Hours"
        className="nc-l-mt_200_mobile"
        name="Texas_811-emergency_etc_hours"
        hint="Estimated time to completion hours: if it is to be complete within a certain amount of hours dispatch it regardless of its priority"
        type="number"
        step={1}
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorTexas811Options),
            emergency_etc_hours: parseInt(event.target.value, 10),
          });
        }}
        value={emergencyEtcHours}
      />
      <NCInputText
        label="Incident Version"
        name="Texas_811-incident_version"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorTexas811Options),
            incident_version: event.target.value,
          });
        }}
        value={incidentVersion}
      />
      <NCInputText
        label="Label Template"
        name="Texas_811-category_name"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorTexas811Options),
            label_template: event.target.value,
          });
        }}
        value={labelTemplate}
      />
      <NCInputText
        label="Target Entity ID"
        name="Texas_811-target_entity_id"
        className="nc-l-mt_200_mobile"
        onChange={(event) => {
          onChange({
            ...(value as IncidentEmailProcessorTexas811Options),
            target_entity_id: event.target.value,
          });
        }}
        value={targetEntityId}
      />
    </>
  );
}

import { stringify } from 'yaml';
import { EnhancedConfigDocumentResource } from '../components/ConfigVisualEditorLoadDataView';
import { EntityConfigDocumentResource } from '../types/EntityConfigApi';

export function sortKeys(object: unknown): unknown {
  if (!object) { return object; }
  if (typeof object !== 'object') { return object; }
  if (Array.isArray(object)) {
    return object.map(sortKeys);
  }
  return Object.keys(object)
    .sort()
    .reduce((result, key) => ({
      ...result,
      [key]: sortKeys((object as Record<string, unknown>)[key]),
    }), {});
}

export const getStorageKeyForEntity = (entityId: string) => `dplr-icg-${entityId}`;
export const loadLocalDataForEntity = (storageKey: string): EnhancedConfigDocumentResource[] => {
  try {
    const rawLocalData: {
      config: { modules: any[]; params: any[]; resources: [] },
      updated: string;
    }[] = JSON
      .parse(localStorage.getItem(storageKey) ?? '') ?? [];
    return rawLocalData.map((rawLocalConfig, i) => ({
      id: i.toString(),
      type: 'configuration-documents',
      attributes: {
        yaml: stringify(rawLocalConfig.config, { schema: 'yaml-1.1' }),
        timestamps: {
          created: rawLocalConfig.updated,
          updated: rawLocalConfig.updated,
        },
      },
      relationships: { applications: { data: [] } },
      meta: { applied: '', latest: false },
    }));
  } catch (e) {
    return [];
  }
};

export const deleteLocalConfigVersion = (
  storageKey: string,
  localConfig: EntityConfigDocumentResource,
) => {
  const localData = loadLocalDataForEntity(storageKey);
  localStorage.setItem(
    storageKey,
    JSON.stringify(localData.filter(
      (c) => c.attributes.timestamps.updated === localConfig.attributes.timestamps.updated,
    )),
  );
};

import {
  CciConditionAnd,
  CciConditionNot,
  CciConditionOr,
  EntityConfigResourceType,
} from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';
import { CciConditionOpProps } from './resource-form-types';

export function CciConditionAndOrNotForm({
  data,
  entityId,
  onChange,
  resources,
}: CciConditionOpProps) {
  const andOrNotData = data as CciConditionAnd | CciConditionOr | CciConditionNot;
  const {
    _conditions_ref_list: conditionsSetRefListValue,
  } = andOrNotData ?? {};

  return (
    <ConfigVisualEditorResourceRefControl
      label="Condition Set"
      name="condition_set"
      multiple
      resources={resources}
      resourceType={EntityConfigResourceType.CCI_CONDITIONS}
      entityId={entityId}
      onChange={(event) => {
        onChange({
          ...andOrNotData ?? {},
          _conditions_ref_list: Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => ({
              key: option.value,
              type: EntityConfigResourceType.CCI_CONDITIONS,
            })),
        });
      }}
      value={conditionsSetRefListValue?.map(({ key }) => key) ?? []}
    />
  );
}

import { useRef, useState } from 'react';
import {
  FormField,
  NCButton,
  NCInputCheckbox,
  NCModal,
  useForm,
} from '@daupler/nexus-components';
import { useNavigate } from 'react-router';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { useAuthentication } from '../hooks/useAuthentication';
import { EntityDetail } from '../types/Entity';

export function EntitiesIdApplyRoute() {
  const {
    entity,
    yaml,
    resources,
    modules,
    params,
    plan,
  } = useEntityConfigEditor();

  const optionsAiValidation: FormField<boolean> = {
    invalidMessage: '',
    name: 'optionsAiValidation',
    validate: () => true,
    validMessage: '',
    value: false,
  };
  const optionsDryRun: FormField<boolean> = {
    invalidMessage: '',
    name: 'optionsDryRun',
    validate: () => true,
    validMessage: '',
    value: false,
  };
  const {
    formState,
    onChange,
  } = useForm({ optionsAiValidation, optionsDryRun });

  const getConfigYaml = () => yaml.from({
    modules: modules.data ?? [],
    params: params.data ?? [],
    resources: resources.data ?? [],
  });

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const [isApplying, setIsApplying] = useState(false);
  const [applyError, setApplyError] = useState('');
  const api = useRef(new DauplerApi({
    fetch: fetch.bind(window),
    baseUrl: config.dauplerApiBaseUrl,
  }));
  const { authToken } = useAuthentication();
  const applyPlan = async () => {
    if (!entity || !authToken || !plan.response) { return; }
    try {
      setIsApplying(true);
      await api.current.applyEntityConfig(
        authToken,
        DauplerApi.stripEntityIdPrefix((entity as EntityDetail).id),
        getConfigYaml(),
        plan.response.meta.hash,
        { dryRun: formState.optionsDryRun.value },
      );
      setIsOpen(false);
    } catch (err) {
      setApplyError((err as Error).message);
    } finally {
      setIsApplying(false);
    }
  };

  return (
    <NCModal
      closeLabel="Close"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Apply Plan"
      onCloseFinished={() => navigate('..')}
      footerChildren={(
        <div className="nc-flex nc-flex--align_items nc-flex--justify_between nc-l-pa_utilities_225_mobile">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </NCButton>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            color={NCButton.colors.PRIMARY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={applyPlan}
            disabled={isApplying}
          >
            Apply
          </NCButton>
        </div>
      )}
    >
      {applyError ? (
        <div className="nc-l-pa_100_mobile nc-t-center nc-t-error">
          {applyError}
        </div>
      ) : null}
      <NCInputCheckbox
        label="Dry Run"
        name="options__dry_run"
        checked={formState.optionsDryRun.value}
        onChange={(event) => onChange(
          formState.optionsDryRun.name,
          event.target.checked,
        )}
      />
    </NCModal>
  );
}

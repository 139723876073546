import {
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  NCModal,
  NCToast,
} from '@daupler/nexus-components';
import { v4, validate } from 'uuid';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { useToast } from '../hooks/useToasts';
import { ConfigVisualEditorHeader } from './ConfigVisualEditorHeader';
import { ConfigVisualEditorObjectsMenu } from './ConfigVisualEditorObjectsMenu';
import './ConfigVisualEditorLayout.css';
import { EditorMode } from '../types/ConfigEditor';
import { ConfigVisualEditorUnsavedWarning } from './ConfigVisualEditorUnsavedWarning';
import { ConfigVisualEditorDrawer } from './ConfigVisualEditorDrawer';
import { ConfigVisualEditorPlanSummary } from './ConfigVisualEditorPlanSummary';
import { useAuthentication } from '../hooks/useAuthentication';

export function ConfigVisualEditorLayout({
  children,
}: PropsWithChildren) {
  const { documentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTrayOpen, setIsTrayOpen] = useState(searchParams.get('objectsMenu') === 'true');
  useEffect(() => {
    if (searchParams.get('objectsMenu') === 'true' && !isTrayOpen) {
      setIsTrayOpen(true);
    } else if (searchParams.get('objectsMenu') === 'false' && isTrayOpen) {
      setIsTrayOpen(false);
    }
  }, [isTrayOpen, searchParams]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(searchParams.get('drawer') === 'true');
  useEffect(() => {
    if (searchParams.get('drawer') === 'true' && !isDrawerOpen) {
      setIsDrawerOpen(true);
    } else if (searchParams.get('drawer') === 'false' && isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [isDrawerOpen, searchParams, setSearchParams]);

  const {
    entity,
    modules,
    params,
    resources,
    isSaving,
    setIsSaving,
    saveEntityConfig,
    setResourceFilter,
    plan,
    editor,
    wipData,
  } = useEntityConfigEditor();

  const { addToast } = useToast();
  const { authToken } = useAuthentication();
  const navigate = useNavigate();
  const saveConfigAs = async () => {
    if (!authToken) { return; }
    if (!entity) { return; }
    try {
      const response = await saveEntityConfig(
        authToken,
        null,
      );
      navigate(`documents/${response.data.id}`);
      wipData.clearAll();
    } catch (err) {
      addToast({
        body: (err as Error).message,
        id: v4(),
        subject: 'Failed to save Configuration Document',
        type: NCToast.style.ERROR,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const saveConfig = async () => {
    if (!authToken) { return; }
    if (!entity) { return; }
    if (!documentId) { return; }
    try {
      await saveEntityConfig(
        authToken,
        documentId,
      );
      wipData.clearAll();
    } catch (err) {
      addToast({
        body: (err as Error).message,
        id: v4(),
        subject: 'Failed to save Configuration Document',
        type: NCToast.style.ERROR,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDrawerSelection = (
    key: string,
    options: { isMultiSelect: boolean },
  ) => {
    if (!entity) { return; }
    const updatedParams = new URLSearchParams(searchParams);
    const currentValue = updatedParams.get('type-filter')?.split(',') ?? [];
    const isTypeAlreadySelected = currentValue.includes(key) ?? false;
    if (options.isMultiSelect) {
      updatedParams.set(
        'type-filter',
        isTypeAlreadySelected
          ? currentValue.filter((v) => v !== key).join(',')
          : [...currentValue, key].join(','),
      );
    } else {
      updatedParams.set(
        'type-filter',
        isTypeAlreadySelected ? '' : key,
      );
    }
    navigate(`/config/entities/${entity.id}/documents/${documentId}/?${updatedParams.toString()}`);
  };

  const [isPlanViewOpen, setIsPlanViewOpen] = useState(false);

  return (
    <>
      <div
        className={clsx('entities_id_route', {
          'entities_id_route--tray': isTrayOpen,
          'entities_id_route--drawer': isDrawerOpen,
        })}
      >
        <div className="entities_id_route__header">
          <ConfigVisualEditorHeader
            abandonChanges={wipData.clearAll}
            documentId={validate(documentId) ? documentId : undefined}
            entity={entity}
            editorMode={editor.mode}
            onDrawerClick={() => {
              const newParams = new URLSearchParams(searchParams.toString());
              newParams.set('drawer', (!isDrawerOpen).toString());
              setSearchParams(newParams);
            }}
            onEditorModeChange={editor.setMode}
            hasUnsavedChanges={!!Object.values(wipData.get() ?? {}).some((data) => data)}
            isDrawerOpen={isDrawerOpen}
            isObjectsMenuOpen={isTrayOpen}
            onObjectsMenuClick={() => {
              const newParams = new URLSearchParams(searchParams.toString());
              newParams.set('objectsMenu', (!isTrayOpen).toString());
              setSearchParams(newParams);
            }}
            onSave={saveConfig}
            onSaveAs={saveConfigAs}
            isSaving={isSaving}
            planErrors={plan.response?.meta.plan.errors.length}
            onPlan={() => {
              navigate(`documents/${documentId}/plan?${searchParams.toString()}`);
            }}
            onApply={() => {
              if (!plan.response) {
                addToast({
                  id: v4(),
                  subject: 'No Plan Available',
                  type: NCToast.style.ERROR,
                  body: 'Plan your changes before applying',
                });
                return;
              }
              navigate(`documents/${documentId}/apply?${searchParams.toString()}`);
            }}
            viewPlan={() => { setIsPlanViewOpen(true); }}
          />
        </div>

        {isDrawerOpen && documentId ? (
          <div className="entities_id_route__drawer">
            <ConfigVisualEditorDrawer
              addMacroLink={`documents/${documentId}/add-macro?${searchParams.toString()}`}
              addModuleLink={`documents/${documentId}/add-module?${searchParams.toString()}`}
              addResourceLink={`documents/${documentId}/add-resource?${searchParams.toString()}`}
              onClearSelections={() => {
                const newParams = new URLSearchParams(searchParams);
                newParams.set('type-filter', '');
                setSearchParams(newParams);
              }}
              onDrawerSelect={handleDrawerSelection}
              selectedList={searchParams.get('type-filter')?.split(',') ?? []}
            />
          </div>
        ) : null}

        <div className="entities_id_route__workspace">
          {children}
        </div>

        {isTrayOpen && documentId ? (
          <div className="entities_id_route__tray">
            <ConfigVisualEditorObjectsMenu
              onSaveProgress={(id, values) => {
                wipData.save(id, values);
              }}
              onParamAdd={(param) => {
                params.add(param);
                wipData.save(`${param.type}|${param.key}`, param);
              }}
              onParamFilter={(paramKey, paramType) => {
                setResourceFilter([{ key: paramKey, type: paramType }]);
              }}
              onParamRemove={(id, type) => {
                params.remove(id);
                wipData.clear(`${type}|${id}`);
              }}
              onParamUpdate={(id, param) => {
                params.update(id, param);
                wipData.save(`${param.type}|${param.key}`, param);
              }}
              resources={resources.data ?? []}
              params={params.data ?? []}
              wipData={wipData.get()}
              modules={modules.data ?? []}
            />
          </div>
        ) : null}
      </div>
      {editor.shouldNagForUnsaved ? (
        <ConfigVisualEditorUnsavedWarning
          onCancel={() => {
            editor.setShouldNagForUnsaved(false);
          }}
          onConfirm={() => {
            editor.setEditorState({
              hasManualEdits: false,
              shouldNagForUnsaved: false,
              mode: EditorMode.VISUAL,
            });
          }}
        />
      ) : null}

      <NCModal
        closeLabel="Close"
        isOpen={isPlanViewOpen}
        onClose={() => { setIsPlanViewOpen(false); }}
        title="Plan"
        width={NCModal.widths.FILL}
        height={NCModal.heights.FILL}
      >
        <ConfigVisualEditorPlanSummary plan={plan.response} />
      </NCModal>
    </>
  );
}

import { NCButton, NCInputText } from '@daupler/nexus-components';
import './ConfigVisualEditor.css';
import { useRef } from 'react';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';

type ConfigVisualEditorCodeProps = {
  yamlValue: string;
  onYamlCancel: () => void;
  onYamlSave: (yaml: string) => void;
};

export function ConfigVisualEditorCode({
  yamlValue,
  onYamlCancel,
  onYamlSave,
}: ConfigVisualEditorCodeProps) {
  const { editor } = useEntityConfigEditor();
  const ref = useRef<HTMLInputElement>(null);
  const handleSave = () => {
    if (!ref.current) { return; }
    onYamlSave(ref.current.value);
  };

  return (
    <div className="config_visual_editor__text_editor">
      <NCInputText
        refToInput={ref}
        multiline
        rows={3}
        name="configuration-raw"
        hint=""
        defaultValue={yamlValue}
        className="config_visual_editor__code"
        label="Configuration Raw"
        labelHidden
        onChange={() => {
          editor.setHasManualEdits(true);
        }}
      />
      <div className="nc-flex nc-flex--justify_end nc-flex--gap_1 nc-l-pa_200_mobile">
        <NCButton
          color={NCButton.colors.GREY}
          appearance={NCButton.appearances.OUTLINE}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={onYamlCancel}
        >
          Cancel
        </NCButton>
        <NCButton
          color={NCButton.colors.PRIMARY}
          appearance={NCButton.appearances.OUTLINE}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          onClick={handleSave}
        >
          Save
        </NCButton>
      </div>
    </div>
  );
}

import { useRef, useState } from 'react';
import {
  FormField,
  NCButton,
  NCInputCheckbox,
  NCModal,
  useForm,
} from '@daupler/nexus-components';
import { useNavigate } from 'react-router';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { DauplerApi } from '../lib/daupler-api';
import { config } from '../config';
import { useAuthentication } from '../hooks/useAuthentication';
import { EntityDetail } from '../types/Entity';

export function EntitiesIdPlanRoute() {
  const {
    entity,
    yaml,
    resources,
    modules,
    params,
    plan,
  } = useEntityConfigEditor();

  const optionsAiValidation: FormField<boolean> = {
    invalidMessage: '',
    name: 'optionsAiValidation',
    validate: () => true,
    validMessage: '',
    value: false,
  };
  const optionsDryRun: FormField<boolean> = {
    invalidMessage: '',
    name: 'optionsDryRun',
    validate: () => true,
    validMessage: '',
    value: false,
  };
  const {
    formState,
    onChange,
  } = useForm({ optionsAiValidation, optionsDryRun });

  const getConfigYaml = () => yaml.from({
    modules: modules.data ?? [],
    params: params.data ?? [],
    resources: resources.data ?? [],
  });

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const [isPlanning, setIsPlanning] = useState(false);
  const [planError, setPlanError] = useState('');
  const api = useRef(new DauplerApi({
    fetch: fetch.bind(window),
    baseUrl: config.dauplerApiBaseUrl,
  }));
  const { authToken } = useAuthentication();
  const onPlan = async () => {
    try {
      setIsPlanning(true);
      setPlanError('');
      plan.setPlanResponse(await api.current.planEntityConfig(
        authToken as string,
        DauplerApi.stripEntityIdPrefix((entity as EntityDetail).id),
        getConfigYaml(),
        { includeAiValidation: formState.optionsAiValidation.value },
      ));
      setIsOpen(false);
    } catch (err) {
      setPlanError((err as Error).message);
    } finally {
      setIsPlanning(false);
    }
  };

  return (
    <NCModal
      closeLabel="Close"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Plan Changes"
      onCloseFinished={() => navigate('..')}
      footerChildren={(
        <div className="nc-flex nc-flex--align_items nc-flex--justify_between nc-l-pa_utilities_225_mobile">
          <NCButton
            appearance={NCButton.appearances.OUTLINE}
            color={NCButton.colors.GREY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </NCButton>
          <NCButton
            appearance={NCButton.appearances.SOLID}
            color={NCButton.colors.PRIMARY}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onPlan}
            disabled={isPlanning}
          >
            Plan
          </NCButton>
        </div>
      )}
    >
      {planError ? (
        <div className="nc-l-pa_100_mobile nc-t-center nc-t-error">
          {planError}
        </div>
      ) : null}
      <NCInputCheckbox
        label="Include AI Validation"
        name="options__ai_validation"
        checked={formState.optionsAiValidation.value}
        onChange={(event) => onChange(
          formState.optionsAiValidation.name,
          event.target.checked,
        )}
      />
    </NCModal>
  );
}

import { useState } from 'react';
import {
  FormField,
  NCInputText,
  useForm,
} from '@daupler/nexus-components';
import { Tooltip } from '@mui/material';
import { ResourceFormProps } from './resource-form-types';
import { useSyncResourceForm } from '../../hooks/useSyncResourceForm';
import { ResourceBaseForm } from './ResourceBaseForm';
import { getBaseResourceFormFields } from '../../utils/resource-tools';
import {
  EntityConfigRef,
  EntityConfigResourceSchedule,
  EntityConfigResourceType,
  ScheduleRule,
} from '../../types/EntityConfig';
import InputRRule from '../InputRRule';
import { InputList } from '../InputList';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';

export function ScheduleForm({
  entityId,
  isResourceReferenced,
  onChange,
  onValidate,
  resourceData,
  resources,
  resourceType,
  workgroups,
}: ResourceFormProps) {
  const { data: dataValue } = (resourceData as EntityConfigResourceSchedule) ?? {};
  const {
    _default_shift_ref: defaultShiftRefValue,
    _holiday_shift_ref: holidayShiftValue,
    rules: rulesValue,
  } = dataValue ?? {};
  const getEmptyRule = () => ({
    display_name: '',
    dtstart: '',
    duration: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    rrule: '',
    _assigned_shift_ref: undefined,
  });
  const [newRule, setNewRule] = useState<ScheduleRule>(getEmptyRule());

  const defaultShiftRef: FormField<EntityConfigRef | null> = {
    invalidMessage: '',
    name: 'defaultShiftRef',
    validate: () => true,
    validMessage: '',
    value: defaultShiftRefValue ?? null,
  };
  const holidayShiftRef: FormField<EntityConfigRef | null> = {
    invalidMessage: '',
    name: 'holidayShiftRef',
    validate: () => true,
    validMessage: '',
    value: holidayShiftValue ?? null,
  };
  const rules: FormField<ScheduleRule[]> = {
    invalidMessage: '',
    name: 'rules',
    validate: () => true,
    validMessage: '',
    value: rulesValue ?? [],
  };

  const {
    formState,
    onChange: onFormChange,
    isFormValid,
    validateForm,
  } = useForm({
    ...getBaseResourceFormFields({
      isResourceReferenced,
      resourceData,
      resources,
      resourceType,
    }),
    defaultShiftRef,
    holidayShiftRef,
    rules,
  });

  useSyncResourceForm({
    isFormValid,
    onChange,
    onValidate,
    validateForm,
    resourceData: {
      display_name: formState.displayName.value,
      key: formState.key.value,
      _workgroup_ref: formState.workgroupRef.value,
      data: {
        _default_shift_ref: formState.defaultShiftRef.value,
        _holiday_shift_ref: formState.holidayShiftRef.value,
        rules: formState.rules.value,
      },
    },
  });

  const {
    _assigned_shift_ref: newRuleAssignedShiftRef,
  } = newRule;
  const [durationValue, setDurationValue] = useState<{
    'months': number;
    'weeks': number;
    'days': number;
    'hours': number;
    'minutes': number;
    'seconds': number;
  }>({
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const updateDurationValue = (
    durationUnit: string,
    value: number,
  ) => {
    setDurationValue((state) => ({
      ...state,
      [durationUnit]: value,
    }));
  };
  const addRule = () => {
    const {
      _assigned_shift_ref: assignedShiftRef,
    } = newRule;
    onFormChange(formState.rules.name, [
      ...formState.rules.value,
      {
        ...newRule,
        display_name: newRule.display_name,
        dtstart: newRule.dtstart,
        rrule: newRule.rrule,
        duration: durationValue,
        _assigned_shift_ref: assignedShiftRef,
      },
    ]);
    setNewRule(getEmptyRule());
  };
  const clearRule = () => {
    setNewRule(getEmptyRule());
  };

  const removeRule = (data: ScheduleRule[]) => {
    onFormChange(
      formState.rules.name,
      data,
    );
  };

  return (
    <>
      <ResourceBaseForm
        entityId={entityId}
        formState={formState}
        onChange={onFormChange}
        resourceType={resourceType}
        workgroups={workgroups}
      />
      <ConfigVisualEditorResourceRefControl
        label="Default Shift"
        className="nc-l-mt_200_mobile"
        name={formState.defaultShiftRef.name}
        resources={resources}
        resourceType={EntityConfigResourceType.SHIFTS}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.defaultShiftRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.SHIFTS,
          },
        )}
        value={formState.defaultShiftRef.value?.key ?? ''}
        isValid={formState.defaultShiftRef.isValid}
        hint={formState.defaultShiftRef.message}
      />
      <ConfigVisualEditorResourceRefControl
        label="Holiday Shift"
        className="nc-l-mt_200_mobile"
        name={formState.holidayShiftRef.name}
        resourceType={EntityConfigResourceType.SHIFTS}
        resources={resources}
        entityId={entityId}
        onChange={(event) => onFormChange(
          formState.holidayShiftRef.name,
          {
            key: event.target.value,
            type: EntityConfigResourceType.SHIFTS,
          },
        )}
        value={formState.holidayShiftRef.value?.key ?? ''}
        isValid={formState.holidayShiftRef.isValid}
        hint={formState.holidayShiftRef.message}
      />

      <InputList<ScheduleRule>
        label="Rules"
        className="nc-l-mt_200_mobile"
        data={formState.rules.value}
        renderItem={(rule) => (
          <Tooltip
            title={(
              <ul>
                <li>{`Display Name: ${rule.display_name}`}</li>
                <li>{`Start Date: ${rule.dtstart}`}</li>
                <li>{`RRule: ${rule.rrule}`}</li>
                <li>{`Duration: ${JSON.stringify(rule.duration)}`}</li>
                <li>
                  {`Assigned Shift: ${resources
                    .find((resource) => {
                      if (resource.state.type !== EntityConfigResourceType.SHIFTS) {
                        return false;
                      }
                      const {
                        _assigned_shift_ref: assignedShiftRef,
                      } = rule;
                      return resource.state.key === assignedShiftRef?.key;
                    })?.state.display_name}`}
                </li>
              </ul>
            )}
          >
            <span>
              {`Display Name: ${rule.display_name}`}
            </span>
          </Tooltip>
        )}
        onAdd={addRule}
        onClear={clearRule}
        onRemove={(data) => removeRule(data)}
        placeholder="No Rules here yet!"
      >
        <>
          <ConfigVisualEditorResourceRefControl
            label="Assigned Shift"
            name="rule-assigned_shift"
            resources={resources}
            resourceType={EntityConfigResourceType.SHIFTS}
            entityId={entityId}
            onChange={(event) => {
              setNewRule((state) => ({
                ...state,
                _assigned_shift_ref: {
                  key: event.target.value,
                  type: EntityConfigResourceType.SHIFTS,
                },
              }));
            }}
            value={newRuleAssignedShiftRef?.key ?? ''}
          />
          <NCInputText
            label="Display Name"
            className="nc-l-mt_200_mobile"
            name="rule-display_name"
            onChange={(event) => {
              setNewRule((state) => ({ ...state, display_name: event.target.value }));
            }}
            value={newRule.display_name}
          />
          <InputRRule
            className="nc-l-mt_200_mobile"
            onChange={(value) => {
              setNewRule((state) => ({
                ...state,
                dtstart: value.dtstart,
                rrule: value.rrule,
              }));
            }}
            rrule={newRule.rrule}
            dtstart={newRule.dtstart}
          />
          <div className="nc-flex nc-flex--gap_1 nc-l-mt_100_mobile">
            <NCInputText
              name="rule-duration_months"
              className="nc-flex_item--1"
              label="Months"
              onChange={(event) => {
                updateDurationValue('months', parseInt(event.target.value, 10));
              }}
              step={1}
              type="number"
              value={durationValue.months}
            />
            <NCInputText
              name="rule-duration_weeks"
              className="nc-flex_item--1"
              label="Weeks"
              onChange={(event) => {
                updateDurationValue('weeks', parseInt(event.target.value, 10));
              }}
              step={1}
              type="number"
              value={durationValue.weeks}
            />
          </div>
          <div className="nc-flex nc-flex--gap_1 nc-l-mt_100_mobile">
            <NCInputText
              name="rule-duration_days"
              className="nc-flex_item--1"
              label="Days"
              onChange={(event) => {
                updateDurationValue('days', parseInt(event.target.value, 10));
              }}
              step={1}
              type="number"
              value={durationValue.days}
            />
            <NCInputText
              name="rule-duration_hours"
              className="nc-flex_item--1"
              label="Hours"
              onChange={(event) => {
                updateDurationValue('hours', parseInt(event.target.value, 10));
              }}
              step={1}
              type="number"
              value={durationValue.hours}
            />
          </div>
          <div className="nc-flex nc-flex--gap_1 nc-l-mt_100_mobile">
            <NCInputText
              name="rule-duration_minutes"
              className="nc-flex_item--1"
              label="Minutes"
              onChange={(event) => {
                updateDurationValue('minutes', parseInt(event.target.value, 10));
              }}
              step={1}
              type="number"
              value={durationValue.minutes}
            />
            <NCInputText
              name="rule-duration_seconds"
              className="nc-flex_item--1"
              label="Seconds"
              onChange={(event) => {
                updateDurationValue('seconds', parseInt(event.target.value, 10));
              }}
              step={1}
              type="number"
              value={durationValue.seconds}
            />
          </div>
        </>
      </InputList>
    </>
  );
}

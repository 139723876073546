import { NCButton, NCSelect, useOutsideClickHandler } from '@daupler/nexus-components';
import { ComponentProps, useRef, useState } from 'react';
import './InputMultiSelectControl.css';
import clsx from 'clsx';

export function InputMultiSelectControl(props: ComponentProps<typeof NCSelect>) {
  const {
    className,
    label,
    labelAction,
    name,
    onChange,
    options,
    value,
  } = props;

  const ref = useRef<HTMLSelectElement>(null);

  const selectedValues = [
    ...(value as string[] ?? [])?.map(
      (selectedValue) => options.find((option) => option.value === selectedValue),
    ).filter((v) => !!v) as { label: string; value: string; }[],
  ];

  const handleSelect = (option: { label: string; value: string; }) => {
    if (!ref.current) { return; }
    Array.from(ref.current.options).forEach((refOption) => {
      if (option.value !== refOption.value) { return; }
      // eslint-disable-next-line no-param-reassign
      refOption.selected = !refOption.selected;
      const event = new Event('change', { bubbles: true });
      ref.current?.dispatchEvent(event);
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const { ref: dropdownRef } = useOutsideClickHandler<HTMLDivElement>(() => setIsOpen(false));

  const remainingOptions = options
    .filter((option) => !(value as string[])?.includes(option.value));

  return (
    <div className={clsx('input_multi_select_control', className)}>
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between">
        <p className="input_multi_select_control__label">{label}</p>
        <div>{labelAction}</div>
      </div>
      <div
        className={clsx('input_multi_select_control__input', {
          'input_multi_select_control__input--active': isOpen,
        })}
      >
        <button type="button" onClick={() => setIsOpen(!isOpen)} className="input_multi_select_control__input__toggle">
          <span className="nc-a11y_hidden">{label}</span>
          <div className="input_multi_select_control__input__filler_values">
            {selectedValues.map((selectedValue) => (
              <NCButton
                key={selectedValue.value}
                color={NCButton.colors.GREY}
                size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              >
                {selectedValue.label}
                {' '}
                <i className="fa-light fa-times fa-fw" />
              </NCButton>
            ))}
          </div>
          {!selectedValues.length ? (<span className="nc-t-grey_500">Click to add items...</span>) : null}
        </button>
        <div className="input_multi_select_control__input__value_list">
          {selectedValues.map((selectedValue) => (
            <NCButton
              key={selectedValue.value}
              color={NCButton.colors.GREY}
              size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
              width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
              onClick={() => handleSelect(selectedValue)}
              className="input_multi_select_control__input__value_list__item"
            >
              {selectedValue.label}
              {' '}
              <i className="fa-light fa-times fa-fw" />
            </NCButton>
          ))}
        </div>
      </div>
      <div
        className={clsx('input_multi_select_control__dropdown', {
          'input_multi_select_control__dropdown--open': isOpen,
        })}
        ref={(refValue) => {
          if (!refValue) { return; }
          (dropdownRef as React.MutableRefObject<HTMLDivElement>).current = refValue;
        }}
      >
        {remainingOptions.length ? remainingOptions
          .map((option) => (
            <button
              type="button"
              className={clsx('input_multi_select_control__option', {
                'input_multi_select_control__option--selected': ((value ?? []) as string[]).includes(option.value),
              })}
              onClick={() => handleSelect(option)}
              key={option.value}
            >
              {option.label}
            </button>
          )) : (
            <span className="nc-l-pa_200_mobile nc-t-sub_text_light nc-t-grey_700">No options remaining</span>
        )}
      </div>
      <NCSelect
        // 2024-12-16 pdb: spreading a wrapper component is OK
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className="input_multi_select_control__select"
        label={label}
        refToInput={ref}
        name={name}
        options={options}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

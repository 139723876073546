import { CciConditionPolicyIn, EntityConfigResourceType } from '../../types/EntityConfig';
import { ConfigVisualEditorResourceRefControl } from '../ConfigVisualEditorResourceRefControl';
import { CciConditionOpProps } from './resource-form-types';

export function CciConditionPolicyInForm({
  data,
  entityId,
  onChange,
  resources,
}: CciConditionOpProps) {
  const policyInData = data as CciConditionPolicyIn;
  const {
    _policy_set_ref_list: policySetRefListValue,
  } = policyInData ?? {};

  return (
    <ConfigVisualEditorResourceRefControl
      label="Policy Set"
      name="policy_set"
      multiple
      resources={resources}
      resourceType={EntityConfigResourceType.DISPATCH_POLICIES}
      entityId={entityId}
      onChange={(event) => {
        onChange({
          ...policyInData ?? {},
          _policy_set_ref_list: Array.from(event.target.options)
            .filter((option) => option.selected)
            .map((option) => ({
              key: option.value,
              type: EntityConfigResourceType.DISPATCH_POLICIES,
            })),
        });
      }}
      value={policySetRefListValue?.map(({ key }) => key) ?? []}
    />
  );
}

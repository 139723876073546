import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useEntityConfigEditor } from '../hooks/useEntityConfigEditor';
import { EntityConfigModule } from '../types/EntityConfig';
import { ConfigVisualEditorModuleModal } from '../components/ConfigVisualEditorModuleModal';
import { useAuthentication } from '../hooks/useAuthentication';

export function EntitiesAddModuleRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    params,
    resources,
    modules,
    entity,
  } = useEntityConfigEditor();

  const { authToken } = useAuthentication();
  const { documentId } = useParams();
  const handleSubmit = async (module: EntityConfigModule) => {
    if (!authToken || !documentId) { return; }
    modules.add(module);
  };

  const handleExit = () => {
    if (!entity) { return; }
    if (location.state?.isRefLink || location.state?.wasAppLink) {
      navigate(-1);
    } else {
      navigate(`/config/entities/${entity.id}?${searchParams.toString()}`);
    }
  };

  if (!entity) { return null; }
  return (
    <ConfigVisualEditorModuleModal
      entityId={entity?.id ?? ''}
      onBack={handleExit}
      onClose={handleExit}
      onSubmit={handleSubmit}
      params={params.data ?? []}
      resources={resources.data ?? []}
    />
  );
}

import React, { useEffect } from 'react';
import {
  FormField,
  NCButton,
  NCInputText,
  NCSelect,
  NCWell,
  useForm,
} from '@daupler/nexus-components';
import './ConfigVisualEditorParamForm.css';
import { ConfigVisualEditorField } from './ConfigVisualEditorField';
import { EntityConfigParam, EntityConfigParamAnyValue, EntityConfigParamType } from '../types/EntityConfig';
import { ExtentFieldValue } from './ConfigVisualEditorFieldExtent';
import { extentToGeoJson } from '../utils/location';

type ConfigVisualEditorParamFormProps = {
  type?: string;
  onSaveProgress?: (values: unknown) => void;
  onSubmit: (param: EntityConfigParam) => void;
  paramKey?: string;
  params: EntityConfigParam[];
  description?: string;
  value?: unknown;
  wipData?: EntityConfigParam;
};

export function ConfigVisualEditorParamForm({
  value,
  onSaveProgress,
  onSubmit,
  paramKey,
  params,
  description,
  type,
  wipData,
}: ConfigVisualEditorParamFormProps) {
  const paramFormDescription: FormField<string> = {
    name: 'paramFormDescription',
    validate: (v) => !!v,
    value: wipData?.description ?? description ?? '',
    invalidMessage: 'Description is required',
    validMessage: '',
  };
  const paramFormKey: FormField<string> = {
    name: 'paramFormKey',
    validate: (paramFormKeyValue, state) => {
      if (state.paramFormKey.initialValue === paramFormKeyValue) { return true; }
      const matches = params.filter((param) => param.type === type
        && param.key === paramFormKeyValue);
      if (matches.length > 0) { return false; }
      return true;
    },
    value: wipData?.key ?? paramKey ?? '',
    invalidMessage: 'Keys must be unique',
    validMessage: '',
    initialValue: paramKey,
  };
  const paramFormType: FormField<string> = {
    name: 'paramFormType',
    validate: (v) => !!v,
    value: wipData?.type ?? type ?? '',
    invalidMessage: 'Type is required',
    validMessage: '',
  };
  const paramFormValue: FormField<unknown> = {
    name: 'paramFormValue',
    validate: (v) => !!v,
    value: wipData?.value ?? value ?? '',
    invalidMessage: 'Value is required',
    validMessage: '',
  };
  const {
    formState,
    getFormValues,
    isFormValid,
    onChange,
    validateForm,
  } = useForm({
    paramFormDescription,
    paramFormKey,
    paramFormType,
    paramFormValue,
  });

  const parseParamValue = (
    paramType: EntityConfigParamType,
    fieldValue: EntityConfigParamAnyValue | ExtentFieldValue,
  ): EntityConfigParamAnyValue => {
    if (paramType === EntityConfigParamType.EXTENT) {
      return {
        data: {
          lat_min: parseFloat((fieldValue as ExtentFieldValue).lat_min),
          lat_max: parseFloat((fieldValue as ExtentFieldValue).lat_max),
          lng_min: parseFloat((fieldValue as ExtentFieldValue).lng_min),
          lng_max: parseFloat((fieldValue as ExtentFieldValue).lng_max),
        },
      } as EntityConfigParamAnyValue;
    }
    return fieldValue as EntityConfigParamAnyValue;
  };

  useEffect(() => {
    const values = getFormValues();
    onSaveProgress?.({
      description: values.paramFormDescription,
      key: values.paramFormKey,
      type: values.paramFormType as EntityConfigParamType,
      value: parseParamValue(
        values.paramFormType as EntityConfigParamType,
        values.paramFormValue as (EntityConfigParamAnyValue | ExtentFieldValue),
      ),
    });
  }, [formState, getFormValues, onSaveProgress]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    validateForm();
    if (!isFormValid()) { return; }
    const values = getFormValues();
    onSubmit({
      description: values.paramFormDescription,
      key: values.paramFormKey,
      type: values.paramFormType as EntityConfigParamType,
      value: parseParamValue(
        values.paramFormType as EntityConfigParamType,
        values.paramFormValue as (EntityConfigParamAnyValue | ExtentFieldValue),
      ),
    });
  };

  return (
    <form className="config_visual_editor_param" onSubmit={handleSubmit}>
      <NCInputText
        className="nc-l-mt_200_mobile"
        label="Description"
        name={formState.paramFormDescription.name}
        value={formState.paramFormDescription.value}
        disabled={!!formState.paramFormKey.initialValue}
        onChange={(event) => {
          onChange(formState.paramFormDescription.name, event.target.value);
          if (!formState.paramFormKey.initialValue) {
            onChange(
              formState.paramFormKey.name,
              event.target.value
                .trim()
                .toUpperCase()
                .split(' ')
                .join('_')
                .replaceAll(/[\W]+/g, ''),
            );
          }
        }}
        isValid={formState.paramFormDescription.isValid}
        hint={formState.paramFormDescription.message}
      />

      <NCInputText
        className="nc-l-mt_200_mobile"
        label="Key"
        name={formState.paramFormKey.name}
        value={formState.paramFormKey.value}
        onChange={(event) => {
          onChange(formState.paramFormKey.name, event.target.value);
        }}
        isValid={formState.paramFormKey.isValid}
        hint={formState.paramFormKey.message}
        disabled={!!paramKey}
      />

      <div className="nc-l-mt_200_mobile">
        <NCSelect
          label="Value Type"
          options={[
            { label: 'Choose one...', value: '' },
            ...Object.values(EntityConfigParamType).map((v) => ({
              label: v,
              value: v,
            })),
          ]}
          name={formState.paramFormType.name}
          value={formState.paramFormType.value}
          onChange={(event) => {
            onChange(formState.paramFormType.name, event.target.value);
          }}
          isValid={formState.paramFormType.isValid}
          hint={formState.paramFormType.message}
        />
        {formState.paramFormType.value ? (
          <>
            <NCWell className="nc-l-mt_100_mobile">
              <ConfigVisualEditorField
                fieldName="Value"
                fieldValue={formState.paramFormValue.value}
                id="param-editor-value"
                type={formState.paramFormType.value}
                onChange={(v) => {
                  onChange(formState.paramFormValue.name, v);
                }}
              />
            </NCWell>
            {!formState.paramFormValue.isValid ? <p className="nc-t-error">{formState.paramFormValue.message}</p> : null}
          </>
        ) : null}
        {formState.paramFormType.value === EntityConfigParamType.EXTENT ? (
          <NCButton
            className="nc-l-mt_200_mobile"
            appearance={NCButton.appearances.LINK}
            onClick={() => {
              onChange(
                formState.paramFormValue.name,
                JSON.stringify({
                  data: JSON.parse(
                    extentToGeoJson(formState.paramFormValue.value as ExtentFieldValue),
                  ),
                }),
              );
              onChange(
                formState.paramFormType.name,
                EntityConfigParamType.JSON,
              );
            }}
          >
            Convert to GeoJSON
          </NCButton>
        ) : null}
      </div>

      <NCButton
        type="submit"
        color={NCButton.colors.PRIMARY}
        className="nc-l-mt_300_mobile"
      >
        Save
      </NCButton>
    </form>
  );
}

import {
  FormField,
  NCAlert,
  NCButton,
  NCInputText,
  NCToast,
  useForm,
} from '@daupler/nexus-components';
import { Link, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { useState } from 'react';
import { EntityConfigResource, EntityConfigResourceType } from '../types/EntityConfig';
import { EntityConfigUtilityCategoriesResponse } from '../types/EntityConfigApi';
import { logger } from '../utils/logger';
import './ConfigVisualEditorResourceModal.css';
import { ConfigVisualEditorResourceForms } from './ConfigVisualEditorResourceForms';
import { EditorEntityModule, EditorEntityParam, EditorEntityResource } from '../hooks/useEntityConfigEditor';
import { useToast } from '../hooks/useToasts';
import { ConfigVisualEditorUnsavedWarning } from './ConfigVisualEditorUnsavedWarning';
import { getDescriptionForResourceType, getIconForResourceType } from '../utils/resource-tools';
import { ConfigVisualEditorObjectPicker } from './ConfigVisualEditorObjectPicker';

enum View {
  CHOOSE_RESOURCE = 'CHOOSE_RESOURCE',
  CONFIGURE_RESOURCE = 'CONFIGURE_RESOURCE',
  CODE = 'CODE',
}

type ConfigVisualEditorResourceModalProps = {
  copyResource?: () => void;
  entityId: string;
  onAbandonChanges?: () => void;
  onBack: () => void;
  onClose: () => void;
  onSaveProgress?: (values: unknown) => void;
  onSubmit: (resource: EntityConfigResource) => Promise<void>;
  params: EditorEntityParam[];
  resource?: EditorEntityResource;
  resourceReferences?: { modules: EditorEntityModule[], resources: EditorEntityResource[]; };
  resources: EditorEntityResource[];
  resourceType?: EntityConfigResourceType;
  utilityCategories: EntityConfigUtilityCategoriesResponse | null;
  wipResource: {
    resourceData: Partial<EntityConfigResource> | null,
    type: EntityConfigResourceType;
  } | null;
};

export function ConfigVisualEditorResourceModal({
  copyResource,
  entityId,
  onAbandonChanges,
  onBack,
  onClose,
  onSaveProgress,
  onSubmit,
  params,
  resource,
  resourceReferences,
  resources,
  resourceType: incomingType,
  utilityCategories,
  wipResource,
}: ConfigVisualEditorResourceModalProps) {
  const [searchParams] = useSearchParams();
  const isResourceReferenced = (resourceReferences?.modules.length ?? 0) > 0
    || (resourceReferences?.resources.length ?? 0) > 0;
  const hasDefaultResource = incomingType || resource?.state.type;
  const [view, setView] = useState(hasDefaultResource
    ? View.CONFIGURE_RESOURCE
    : View.CHOOSE_RESOURCE);

  const [
    subFormValidationCallback,
    setSubFormValidationCallback,
  ] = useState<({ validate: () => boolean })>({ validate: () => false });

  const type: FormField<EntityConfigResourceType> = {
    invalidMessage: '',
    name: 'type',
    validate: (value) => !!value,
    validMessage: '',
    value: wipResource
      ? wipResource.type
      : incomingType || (resource?.state.type ?? '') as EntityConfigResourceType,
    initialValue: (incomingType || resource?.state.type) ?? '' as EntityConfigResourceType,
    isValid: !!resource?.state.type,
  };
  const resourceData: FormField<Partial<EntityConfigResource> | null> = {
    invalidMessage: '',
    name: 'resourceData',
    isValid: true,
    validate: (_, state) => state.resourceData.isValid ?? false,
    validMessage: '',
    value: wipResource
      ? wipResource.resourceData
      : resource?.state ?? null,
    initialValue: resource?.state ?? null,
  };

  const {
    formState,
    getFormValues,
    isFormValid,
    onChange,
    validateField,
    validateForm,
  } = useForm({ resourceData, type });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    validateField(formState.type.name);
    if (view === View.CHOOSE_RESOURCE && formState.type.isValid) {
      setView(View.CONFIGURE_RESOURCE);
      return;
    }

    validateForm();
    subFormValidationCallback.validate();
    if (!isFormValid()) { return; }

    try {
      await onSubmit({
        type: formState.type.value,
        ...(formState.resourceData.value ?? {}),
      } as EntityConfigResource);
      onClose();
    } catch (err) {
      logger.error((err as Error).message, err);
    }
  };

  const handleCancel = () => {
    if (hasDefaultResource || view === View.CHOOSE_RESOURCE) {
      onClose();
      onAbandonChanges?.();
    } else {
      setView(View.CHOOSE_RESOURCE);
    }
  };

  const [codeValue, setCodeValue] = useState('');
  const [shouldWarnUnsaved, setShouldWarnUnsaved] = useState(false);
  const revertCodeChanges = () => {
    setCodeValue(JSON.stringify(formState.resourceData.value, undefined, 2));
    setView(View.CONFIGURE_RESOURCE);
  };
  const abandonCodeChanges = () => {
    if (JSON.stringify(formState.resourceData.value) !== codeValue) {
      setShouldWarnUnsaved(true);
      return;
    }
    revertCodeChanges();
  };
  const switchToCodeEditor = () => {
    setCodeValue(JSON.stringify(formState.resourceData.value, undefined, 2));
    setView(View.CODE);
  };
  const { addToast } = useToast();
  const switchToVisualEditor = () => {
    setCodeValue(JSON.stringify(formState.resourceData.value));
    try {
      onChange(formState.resourceData.name, JSON.parse(codeValue));
      validateField(formState.resourceData.name);
    } catch (e) {
      addToast({
        body: (e as Error).message,
        subject: 'Failed to parse resource',
        type: NCToast.style.ERROR,
        id: 'json-parse-error',
      });
    }
    setView(View.CONFIGURE_RESOURCE);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="config_visual_editor_resource_modal">
        <div className="config_visual_editor_resource_modal__header">
          <NCButton
            appearance={NCButton.appearances.SOLID}
            color={NCButton.colors.LIGHT}
            width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
            onClick={onBack}
          >
            <i className="fa-light fa-arrow-left" />
          </NCButton>
          <h2 className="nc-t-h5_medium_mobile">
            Resource Editor
          </h2>
        </div>

        <div className="config_visual_editor_resource_modal__body">
          {view === View.CHOOSE_RESOURCE ? (
            <ConfigVisualEditorObjectPicker
              items={Object.values(EntityConfigResourceType).map((resourceType) => ({
                id: resourceType,
                name: resourceType,
                description: getDescriptionForResourceType(resourceType),
                icon: (<i className={`fa-solid ${getIconForResourceType(resourceType)}`} />),
              }))}
              onChange={(id) => onChange(formState.type.name, id as EntityConfigResourceType)}
              title="Choose Resource"
              value={formState.type.value}
            />
          ) : null}
          {view === View.CONFIGURE_RESOURCE ? (
            <div>
              {wipResource ? (
                <NCAlert
                  className="nc-l-mb_200_mobile"
                  action={(
                    <NCButton
                      appearance={NCButton.appearances.LINK}
                      size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.SM]]}
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      onClick={onAbandonChanges}
                    >
                      <i className="fa-solid fa-times fa-fw config_visual_editor_resource_modal__unsaved_dismiss" />
                    </NCButton>
                  )}
                  message="You have unsaved changes since your last edit."
                  type={NCAlert.types.WARNING}
                />
              ) : null}
              <ConfigVisualEditorResourceForms
                entityId={entityId}
                isResourceReferenced={isResourceReferenced}
                onChange={(value) => {
                  onChange(
                    formState.resourceData.name,
                    value,
                  );
                  onSaveProgress?.({ ...getFormValues(), resourceData: value });
                }}
                onValidate={(result, validateSubForm) => {
                  if (typeof result === 'boolean') {
                    validateField(formState.resourceData.name, result);
                  }
                  setSubFormValidationCallback({ validate: validateSubForm });
                }}
                type={formState.type.value}
                params={params}
                resourceData={formState.resourceData.value}
                resources={resources}
                resourceType={formState.type.value}
                utilityCategories={utilityCategories}
                workgroups={resources.filter(
                  (workgroup) => workgroup
                    .state.type === EntityConfigResourceType.WORKGROUPS,
                )}
              />
              <div className="nc-l-mt_400_mobile">
                <h3 className="nc-t-h5_medium_mobile">Referenced By</h3>
                {isResourceReferenced ? (
                  <ul className="nc-l-mt_100_mobile">
                    {resourceReferences?.resources.map(({ state: resourceReference, id }) => (
                      <li key={resourceReference.key} className="nc-l-mt_utilities_50_mobile">
                        <Link to={`../resources/${resourceReference.type}|${id}?${searchParams.toString()}`} state={{ wasAppLink: true }}>
                          {resourceReference.key}
                          {' '}
                          <span className="nc-t-sub_text_light_mobile">
                            {`(${resourceReference.type})`}
                          </span>
                        </Link>
                      </li>
                    ))}
                    {resourceReferences?.modules.map(({ state: moduleReference, id }) => (
                      <li key={moduleReference.module_type} className="nc-l-mt_utilities_50_mobile">
                        <Link to={`../resources/${id}?${searchParams.toString()}`} state={{ wasAppLink: true }}>
                          {moduleReference.module_type}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="nc-t-sub_text_light_mobile nc-l-mt_100_mobile">
                    No references
                  </p>
                )}
              </div>
            </div>
          ) : null}
          {view === View.CODE ? (
            <NCInputText
              label="Code"
              name="resource-modal-code-editor"
              className="config_visual_editor_resource_modal__code"
              labelHidden
              value={codeValue}
              onChange={(event) => setCodeValue(event.target.value)}
              multiline
              rows={3}
            />
          ) : null}
        </div>

        <div
          className={clsx(
            'config_visual_editor_resource_modal__footer',
            'nc-flex',
            'nc-flex--align_center',
            'nc-flex--gap_1',
            'nc-l-pa_utilities_225_mobile',
            {
              'nc-flex--justify_between': view !== View.CODE,
              'nc-flex--justify_end': view === View.CODE,
            },
          )}
        >
          {view === View.CODE ? (
            <>
              <NCButton
                appearance={NCButton.appearances.OUTLINE}
                color={NCButton.colors.GREY}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                onClick={() => {
                  abandonCodeChanges();
                }}
              >
                Discard Changes
              </NCButton>
              <NCButton
                appearance={NCButton.appearances.OUTLINE}
                color={NCButton.colors.PRIMARY}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                onClick={() => {
                  switchToVisualEditor();
                  validateField(
                    formState.resourceData.name,
                    subFormValidationCallback.validate(),
                  );
                }}
              >
                Save Code
              </NCButton>
            </>
          ) : (
            <>
              <NCButton
                appearance={NCButton.appearances.OUTLINE}
                color={NCButton.colors.GREY}
                width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                onClick={handleCancel}
              >
                {view === View.CHOOSE_RESOURCE || hasDefaultResource ? 'Cancel' : 'Back'}
              </NCButton>
              <div className="nc-flex nc-flex--gap_1 nc-flex--align_center">
                {view === View.CHOOSE_RESOURCE ? null : (
                  <>
                    <NCButton
                      appearance={NCButton.appearances.OUTLINE}
                      color={NCButton.colors.GREY}
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      onClick={() => {
                        switchToCodeEditor();
                      }}
                    >
                      <i className="fa-solid fa-fw fa-code" />
                    </NCButton>
                    <NCButton
                      appearance={NCButton.appearances.OUTLINE}
                      color={NCButton.colors.GREY}
                      width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                      onClick={copyResource}
                    >
                      <i className="fa-solid fa-fw fa-copy" />
                    </NCButton>
                  </>
                )}
                <NCButton
                  appearance={NCButton.appearances.SOLID}
                  color={NCButton.colors.PRIMARY}
                  width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
                  type="submit"
                >
                  {view === View.CHOOSE_RESOURCE ? 'Next' : 'Save'}
                </NCButton>
              </div>
            </>
          )}
        </div>
      </form>
      {shouldWarnUnsaved ? (
        <ConfigVisualEditorUnsavedWarning
          onCancel={() => { setShouldWarnUnsaved(false); }}
          onConfirm={() => {
            setShouldWarnUnsaved(false);
            revertCodeChanges();
          }}
        />
      ) : null}
    </>
  );
}
